<div>
    <h1 mat-dialog-title>Add an Organization</h1>

    <form autocomplete="off" [formGroup]="form">

        <div fxLayout="column" fxLayoutGap="20px grid" style="margin-bottom: 100px;">

            <mat-form-field appearance="outline" fxFlex="1 1 100%">
                <mat-label>Company Name</mat-label>
                <input autocomplete="off" matInput matTooltip="The registered name of the company" type="string" placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="form.get('name').invalid">Please enter a valid business name</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 100%">
                <mat-label>Company Email</mat-label>
                <input autocomplete="off" matInput matTooltip="The invitation to join will be sent on this email id" type="string" placeholder="Email" formControlName="email"
                    required>
                <mat-error *ngIf="form.get('email').invalid">Please enter a valid email</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 100%">
                <mat-label>Contact Number</mat-label>
                <input autocomplete="off" matInput matTooltip="User may be contacted over this number" type="string" placeholder="Number" formControlName="phone"
                    required>
                <mat-error *ngIf="form.get('phone').invalid">Please enter a valid phone number</mat-error>
            </mat-form-field>
        </div>
    </form>

    <div>
        <div fxLayout="row" style="margin-top: 20px;">

            <button mat-button type="submit" class="action-button" style="color:white; margin-bottom: 20px;"
                (click)="submitClicked()">
                SUBMIT
            </button>

            <div style="margin-left: auto;">
                <button mat-button (click)="cancelClicked()"
                    style="border: brown 1px solid; color:brown;">CANCEL</button>
            </div>
        </div>
    </div>
</div>