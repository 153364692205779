import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FinanceRequestFormComponent } from './finance-request-form.component';
import { PipesModule } from 'app/dp-world/pipes/pipes.module';

@NgModule({
  imports: [SharedModule, NgxFileDropModule, PipesModule],
  declarations: [
    FinanceRequestFormComponent,
  ],
  entryComponents: [FinanceRequestFormComponent],
  exports: [FinanceRequestFormComponent]
})

export class FinanceRequestFormModule {}