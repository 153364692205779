import { Routes } from '@angular/router';
import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { AuthGuard } from './sessions/login/auth.guard';
var ɵ0 = { title: 'Dashboard', titleI18n: 'dashboard' }, ɵ1 = function () { return import("./sessions/sessions.module.ngfactory").then(function (m) { return m.SessionsModuleNgFactory; }); }, ɵ2 = { title: 'Sessions', titleI18n: 'Sessions' }, ɵ3 = function () { return import("./apply/apply.module.ngfactory").then(function (m) { return m.ApplyModuleNgFactory; }); }, ɵ4 = function () { return import("./transaction-history/transaction-history.module.ngfactory").then(function (m) { return m.TransactionHistoryModuleNgFactory; }); }, ɵ5 = function () { return import("./settings/settings.module.ngfactory").then(function (m) { return m.SettingsModuleNgFactory; }); }, ɵ6 = function () { return import("./status/status.module.ngfactory").then(function (m) { return m.StatusModuleNgFactory; }); }, ɵ7 = function () { return import("./update/update.module.ngfactory").then(function (m) { return m.UpdateModuleNgFactory; }); }, ɵ8 = function () { return import("./faq/faq.module.ngfactory").then(function (m) { return m.FaqModuleNgFactory; }); }, ɵ9 = function () { return import("./view/view.module.ngfactory").then(function (m) { return m.ViewModuleNgFactory; }); }, ɵ10 = function () { return import("./error-handling/error-handling.module.ngfactory").then(function (m) { return m.ErrorHandlingModuleNgFactory; }); }, ɵ11 = function () { return import("./logistics-finance/logistics-finance.module.ngfactory").then(function (m) { return m.LogisticsFinanceModuleNgFactory; }); }, ɵ12 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ13 = { title: 'Login', titleI18n: 'Login' }, ɵ14 = { title: 'Register', titleI18n: 'Register' };
var routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard', canActivate: [AuthGuard],
                component: DashboardComponent,
                data: ɵ0,
            },
            {
                path: 'sessions', canActivate: [AuthGuard],
                loadChildren: ɵ1,
                data: ɵ2,
            },
            {
                path: 'apply', canActivate: [AuthGuard], runGuardsAndResolvers: 'always',
                loadChildren: ɵ3
            },
            {
                path: 'transaction-history', canActivate: [AuthGuard], loadChildren: ɵ4
            },
            { path: 'settings', canActivate: [AuthGuard], loadChildren: ɵ5 },
            { path: 'status', canActivate: [AuthGuard], loadChildren: ɵ6 },
            { path: 'update', canActivate: [AuthGuard], loadChildren: ɵ7 },
            { path: 'faq', canActivate: [AuthGuard], loadChildren: ɵ8 },
            { path: 'view', canActivate: [AuthGuard], loadChildren: ɵ9 },
            { path: 'error-handling', canActivate: [AuthGuard], loadChildren: ɵ10 },
            { path: 'logistics-finance', canActivate: [AuthGuard], loadChildren: ɵ11 },
            { path: 'home', loadChildren: ɵ12 },
        ],
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: ɵ13,
            },
            {
                path: 'register',
                component: RegisterComponent,
                data: ɵ14,
            },
        ],
    },
    { path: '**', canActivate: [AuthGuard], redirectTo: 'dashboard' },
];
var RoutesRoutingModule = /** @class */ (function () {
    function RoutesRoutingModule() {
    }
    return RoutesRoutingModule;
}());
export { RoutesRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
