import { User } from './user.model';
import { AuthData } from './auth-data.model';
import { NgForm } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { LogService } from 'app/dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';

@Injectable()
export class AuthService {
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public readonly currentUser: Observable<User> = this.currentUserSubject.asObservable();
  public user:User;
  public token: string;

  constructor(
    private router: Router, 
    private http: HttpClient,
    private logger:LogService,
    private ui:UiService,
    ) 
  {
    let user = JSON.parse(localStorage.getItem(environment.storage.currentUser));

    if (user==null){
      return;
    }

    let hours = 3600000;
    let original = user.timestamp;
    let now = new Date().getTime();
    let diff = now-original;

    // token still valid
    if (diff < hours*8){
      this.handleNewUser(user);

    // token expired
    } else {
      console.log("login is too old, removing! "+diff);
      localStorage.removeItem(environment.storage.currentUser);
    }

    /*
    // what is this for?
    if (!user && environment.hasOwnProperty('mockUser')) {
      // tslint:disable-next-line: no-string-literal
      user = environment['mockUser'] as User;
      localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
    }
    this.handleNewUser(user);
    */
  }

  private handleNewUser(user: any) {

    this.user = user;
    if (user) {
      //moment.locale(user.locale);
    }
    this.currentUserSubject.next(user);
  }

  public get currentUserValue(): User {
    return this.user;
    //return this.currentUserSubject.value;
  }

  registerUser(authData: AuthData) {
    return this.http
      .post(
        environment.rootUrl + environment.urls.register,
        {},
        {
          headers: new HttpHeaders({
            [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.username + ':' + authData.password)
          }),
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          return user;
        })
      );
  }

  login(authData: AuthData) {
    return this.http
      .post(
        environment.rootUrl + environment.urls.login,
        {},
        {
          headers: new HttpHeaders({
            [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.username + ':' + authData.password)
          }),
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<User>) => {

          //console.log("login; response: ", response);
          //console.log("login; response.headers: ", response.headers);

          const user = response.body as User;

          console.log("login; user: ", user);

          user.token = response.headers.get('x-dpw-user-token');
          user.timestamp = moment().toDate().getTime();

          localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));

          this.handleNewUser(user);
          return user;
        })
      )

    // .pipe(
    //   map(res => {
    //     //console.log(res);
    //   })
    // );

    // this.user = {
    //   email: authData.email,
    //   userId: Math.round(Math.random() * 10000).toString()
    // };
    // this.authSuccessfully();
  }

  verifyCaptcha(captchaToken: String) {
    return this.http.post(environment.rootUrl + environment.urls.verify_captcha, {captcha_token: captchaToken});
  }

  private authSuccessfully() {
    // this.authChange.next(true);
    this.router.navigate(['/welcome']);
  }

  logout() {

    console.log("logout;");

    this.http.post(environment.rootUrl + environment.urls.logout,{}).subscribe((response)=>{
      console.log("logout; response: ", response);

      this.ui.snackbar("You have been logged out!");

      localStorage.removeItem(environment.storage.currentUser);
      this.currentUserSubject.next(null);

      setTimeout(()=>{
        this.router.navigate(['/auth/login']);
      }, 200);
    },
    (error)=>{
      this.logger.logError("The backend can't log you out right now.");
      //this.ui.snackbar("The backend can't log you out right now.");

      setTimeout(()=>{
        this.router.navigate(['/auth/login']);
      }, 200);
    });
  }

  isAuth() {
    console.log("isAuth; this.currentUserValue: ", this.currentUserValue);
    return this.currentUserValue != null;
  }

  isAdmin() {
    return this.currentUserValue && this.currentUserValue.isAdmin;
  }

  onSubmit(form: NgForm) {
    //console.log(form);
  }

  getUserLocale() {
    let user = this.currentUserValue;
    let allowedLocales = ['en-US', 'en-GB'];
    if (user && user.locale && allowedLocales.indexOf(user.locale) !== -1) {
      return user.locale;
    }
    console.warn('check user locale for its validation');
    return 'en-US';
  }
}
