import { Observable } from 'rxjs';
import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UtilsService } from 'app/dp-world/services/utils.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
import * as i0 from "@angular/core";
import * as i1 from "../../dp-world/services/static-data.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../dp-world/services/utils.service";
import * as i4 from "../../dp-world/services/ui.service";
import * as i5 from "../../dp-world/services/log.service";
var ApplyService = /** @class */ (function () {
    function ApplyService(staticData, http, utils, ui, logger) {
        this.staticData = staticData;
        this.http = http;
        this.utils = utils;
        this.ui = ui;
        this.logger = logger;
    }
    ApplyService.prototype.saveFinance = function (finance, id) {
        if (id === void 0) { id = ''; }
        console.log("saveFinance; ");
        //return of();
        var url = environment.rootUrl + environment.urls.save_finance;
        var data = this.utils.getFormData(finance);
        //console.log("saveFinance; data:"+JSON.stringify(data) );
        data.forEach(function (value, key) {
            console.log(key + "=" + JSON.stringify(value));
        });
        //if this is an existing finance, we must use it's id to save it.
        if (finance.id != null) {
            url = environment.rootUrl + environment.urls.save_finance_by_id.replace(':id', finance.id);
        }
        return this.http.post(url, data);
    };
    ApplyService.prototype.downloadDocument = function (id, type) {
        var url = environment.rootUrl + '/trade-finance/' + id + '/document/' + type; //environment.urls.upload_document;
        return this.http.get(url);
    };
    /**
     * used by other parties to reject finance
     * /discount-rate?invoiceDueDate=2020-07-31T12:43:02.119Z&paymentDate=2020-07-30T12:43:02.119Z
     */
    ApplyService.prototype.getDiscountRate = function (invoiceDueDate, paymentDate) {
        console.log("getDiscountRate;");
        var url = environment.rootUrl + 'discount-rate?invoiceDueDate=' + invoiceDueDate + '&paymentDate=' + paymentDate;
        return this.http.get(url);
    };
    ApplyService.prototype.getDiscountRates = function (form) {
        var _this = this;
        console.log("getDiscountRate;");
        // could be string or date, make sure it's a string
        var invoiceDueDate = form.get('invoiceDueDate').value;
        var invoiceDueDateString = "";
        if (typeof invoiceDueDate != "string") {
            invoiceDueDateString = invoiceDueDate.toISOString();
        }
        else {
            invoiceDueDateString = invoiceDueDate;
        }
        console.log("getDiscountRate; invoiceDueDate: " + invoiceDueDate);
        var logisticsDetailsFormGroup = form.get('logisticsDetails').controls;
        logisticsDetailsFormGroup.forEach(function (eventDetails) {
            var paymentDate = eventDetails.get("paymentDate").value;
            if (!paymentDate || !invoiceDueDate) {
                return;
            }
            // could be string or date, make sure it's a string
            var paymentDateString = "";
            if (typeof paymentDate != "string") {
                paymentDateString = paymentDate.toISOString();
            }
            else {
                paymentDateString = paymentDate;
            }
            var discountRateControl = eventDetails.get('discountRate');
            _this.getDiscountRate(invoiceDueDateString, paymentDateString).subscribe(function (response) {
                console.log("getDiscountRate; response: ", response);
                discountRateControl.setValue(response.discountRate);
            }, function (error) {
                _this.ui.snackbar('The backend is currently not returning discount rate data.');
                _this.logger.logError('The backend is currently not returning discount rate data.');
            });
        });
    };
    ApplyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplyService_Factory() { return new ApplyService(i0.ɵɵinject(i1.StaticDataService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UtilsService), i0.ɵɵinject(i4.UiService), i0.ɵɵinject(i5.LogService)); }, token: ApplyService, providedIn: "root" });
    return ApplyService;
}());
export { ApplyService };
