import * as i0 from "@angular/core";
var LogService = /** @class */ (function () {
    function LogService() {
    }
    LogService.prototype.logError = function (message) {
        console.log(message);
    };
    LogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogService_Factory() { return new LogService(); }, token: LogService, providedIn: "root" });
    return LogService;
}());
export { LogService };
