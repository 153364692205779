import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../dp-world/services/static-data.service";
import * as i2 from "@angular/common/http";
var RegisterService = /** @class */ (function () {
    function RegisterService(staticData, http) {
        this.staticData = staticData;
        this.http = http;
    }
    RegisterService.prototype.register = function (user) {
        return of();
        var url = environment.rootUrl + environment.urls.register;
        return this.http.get(url);
    };
    RegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.ɵɵinject(i1.StaticDataService), i0.ɵɵinject(i2.HttpClient)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
