<div>
    <ng-container *ngIf="financeType=='Trade'">
        <h2>About This Application</h2>
        <div>This application is for Pre-Shipment-Trade Finance. The application has 8 steps, you may save at any of the
            steps and resume back again.</div>
    </ng-container>

    <ng-container *ngIf="financeType=='Logistics'">
        <h2>Apply New Logistics Finance Application</h2>
        <div>This application is for Logistics Finance. The application has 6 steps, you may save at any of the
            steps and resume back again.</div>
    </ng-container>

    <div fxShow fxHide.lt-lg="true">
        <mat-card style="background-color: #f2f2f2; margin: 20px 0 0 0;">
            <h3 style="text-align: center;">Application sections are</h3>

            <!-- For desktop resolutions -->
            <div>

                <div style="margin: 0 60px 0 0;">

                    <mat-horizontal-stepper labelPosition="bottom" #stepper
                        style="background-color: transparent; margin-bottom: 0px; width: 100%;">

                        <!-- override the icon for completed steps -->
                        <ng-template matStepperIcon="edit" let-index="index">
                            {{index+1}}
                        </ng-template>

                        <mat-step [completed]="true">
                            <ng-template matStepLabel *ngIf="financeType=='Trade'">Trade Finance <br> Application
                            </ng-template>
                            <ng-template matStepLabel *ngIf="financeType=='Logistics'">Logistics Finance <br>
                                Application</ng-template>
                        </mat-step>
                        <mat-step [completed]="true">
                            <ng-template matStepLabel *ngIf="financeType=='Trade'">Trading Partner <br> Review
                            </ng-template>
                            <ng-template matStepLabel *ngIf="financeType=='Logistics'">Invoice <br> Endorsement
                            </ng-template>
                        </mat-step>
                        <mat-step [completed]="true">
                            <ng-template matStepLabel>Initial <br> Assessment</ng-template>
                        </mat-step>
                        <mat-step [completed]="true">
                            <ng-template matStepLabel>Bank <br> Assessment</ng-template>
                        </mat-step>
                        <ng-container *ngIf="financeType=='Trade'">
                            <mat-step [completed]="true">
                                <ng-template matStepLabel>Financing <br> Terms Review</ng-template>
                            </mat-step>
                            <mat-step [completed]="true">
                                <ng-template matStepLabel>Application <br> Summary</ng-template>
                            </mat-step>
                        </ng-container>
                        <mat-step [completed]="true">
                            <ng-template matStepLabel>Funds <br> Transferred</ng-template>
                        </mat-step>
                        <mat-step [completed]="true">
                            <ng-template matStepLabel>Account <br> Settled </ng-template>
                        </mat-step>
                    </mat-horizontal-stepper>
                </div>

                <div fxLayout="row" style="margin: 0 15px 0 15px;">

                    <ng-container *ngIf="financeType=='Trade'">

                        <div style="width: 12.4%; margin:10px;">
                            Provide the requisite details to start the finance application. You can save it as
                            a draft
                            and resume it later.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            The finance application will be screened for problems.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            The document submitted should be endorsed from the other party for the application to
                            progress.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            Once the documents are endorsed, the Bank will assess the application. You will have to
                            accept
                            the terms of the bank thereafter.
                        </div>

                        <div style="width: 12.4%; margin:10px;">
                            The Bank has accepted the application. Either part has to
                            accept the terms for the amount to be sanctioned.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            The finance amount has been approved. The funds will be transferred to you within a
                            few
                            days.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            Congratulations! The amount has been transferred.
                        </div>
                        <div style="width: 12.4%; margin:10px;">
                            The bank has received back the amount and the account has been settled.
                        </div>
                    </ng-container>

                    <ng-container *ngIf="financeType=='Logistics'">
                        <div style="width: 16.6%; margin:10px;">
                            Provide the requisite details to start the finance application. You can save it as
                            a draft
                            and resume it later.
                        </div>
                        <div style="width: 16.6%; margin:10px;">
                            The finance application will be screened for problems.
                        </div>
                        <div style="width: 16.6%; margin:10px;">
                            The document submitted should be endorsed from the other party for the application to
                            progress.
                        </div>
                        <div style="width: 16.6%; margin:10px;">
                            Once the documents are endorsed, the Bank will assess the application. You will have to
                            accept
                            the terms of the bank thereafter.
                        </div>
                        <div style="width: 16.6%; margin:10px;">
                            Congratulations! The amount has been transferred.
                        </div>
                        <div style="width: 16.6%; margin:10px;">
                            The bank has received back the amount, and the account has been settled.
                        </div>
                    </ng-container>

                </div>
            </div>

        </mat-card>
    </div>
</div>