<div class="matero-topmenu scrollbar-thin">
  <div class="matero-topmenu-inner">
    <ng-container *ngFor="let menuItem of menus">

      <a mat-button [routerLink]="buildState([menuItem.state])" *ngIf="menuItem.type === 'link'">
        <span class="menu-name">{{menuItem.name}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
      </a>
      <a mat-button [href]="menuItem.state" *ngIf="menuItem.type === 'extLink'">
        <span class="menu-name">{{menuItem.name}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon>launch</mat-icon>
      </a>
      <a mat-button [href]="menuItem.state" target="_blank" *ngIf="menuItem.type === 'extTabLink'">
        <span class="menu-name">{{menuItem.name}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon>launch</mat-icon>
      </a>
      <!-- level 1 -->
      <button mat-button *ngIf="menuItem.type === 'sub'" [matMenuTriggerFor]="menulevel1">
        <span class="menu-name">{{menuItem.name}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menulevel1="matMenu">
        <ng-container *ngFor="let childLvl1 of menuItem.children">

          <a mat-menu-item [routerLink]="buildState([menuItem.state, childLvl1.state])"
             *ngIf="childLvl1.type === 'link'">
            {{childLvl1.name}}
          </a>
          <a mat-menu-item [href]="childLvl1.state"
             *ngIf="childLvl1.type === 'extLink'">
            <span class="menu-name">{{childLvl1.name}}</span>
            <mat-icon>launch</mat-icon>
          </a>
          <a mat-menu-item [href]="childLvl1.state" target="_blank"
             *ngIf="childLvl1.type === 'extTabLink'">
            <span class="menu-name">{{childLvl1.name}}</span>
            <mat-icon>launch</mat-icon>
          </a>
          <!-- level 2 -->
          <button mat-menu-item *ngIf="childLvl1.type === 'sub'" [matMenuTriggerFor]="menulevel2">
            {{childLvl1.name}}
          </button>
          <mat-menu #menulevel2="matMenu">
            <ng-container *ngFor="let childLvl2 of childLvl1.children">

              <a mat-menu-item
                 [routerLink]="buildState([menuItem.state, childLvl1.state, childLvl2.state])"
                 *ngIf="childLvl2.type === 'link'">
                {{childLvl2.name}}
              </a>
              <a mat-menu-item [href]="childLvl2.state"
                 *ngIf="childLvl2.type === 'extLink'">
                <span class="menu-name">{{childLvl2.name}}</span>
                <mat-icon>launch</mat-icon>
              </a>
              <a mat-menu-item [href]="childLvl2.state" target="_blank"
                 *ngIf="childLvl2.type === 'extTabLink'">
                <span class="menu-name">{{childLvl2.name}}</span>
                <mat-icon>launch</mat-icon>
              </a>

            </ng-container>
          </mat-menu>
        </ng-container>
      </mat-menu>

    </ng-container>
  </div>
</div>