import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * All backend logging should go through this class. Records errors happening in production.
 * @author charles.skinner@dpworld.com
 */
export class LogService {

  constructor() { }

  logError(message:string){
    console.log(message);
  }
}
