import { Component, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from 'app/dp-world/services/notification.service';
import { Notification } from '../../../routes/dashboard/dashboard.models';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  messages: Notification[] = [];
  numUnread = 0;

  constructor(
    private notificationService: NotificationService,
    private ui: UiService,
    private logger: LogService,
    private ref: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {

    // if you're on apply tab, and you click a notification, it won't refresh because you're already at the apply page. this is a hack to fix that.
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        window.location.reload();
      }
    });

    this.notificationService.getNotifications().subscribe(
      (data: Notification[]) => {
        if (!data) {
          this.ui.snackbar("The backend isn't currently returning notifications.");
          this.logger.logError("The backend isn't currently returning notifications.");
          return;
        }
        // console.log('data: ', data);
        // get the last 10 notifications
        this.messages = data.slice(0, 10);
        this.numUnread = this.countUnreadNotifications(this.messages);
        this.ref.detectChanges();
      },
      error => {
        this.ui.snackbar("The backend isn't currently returning notifications.");
        this.logger.logError("The backend isn't currently returning notifications.");
      }
    );
  }

  countUnreadNotifications(messages: Notification[]) {
    return messages.filter(message => !message.has_seen).length;
  }

  menuOpened() {
    console.log('Notification menu opens');
  }
  
  notificationClicked(financeId: number, ownerType:string) {

    console.log("financeNumClicked; financeType: "+ownerType);
    let financeType = "";
    if(ownerType == "TRADEFINANCE"){
      financeType = "TradeFinance";
    } else if(ownerType == "LOGISTICSFINANCE"){
      financeType = "LogisticsFinance";
    }

    let address = 'apply/def';

    this.router.navigate([address], { queryParams: { finance: financeId, financeType:financeType  } } );
  }

  updateNotificationStatus(notification) {
    notification.has_seen = true;
    this.numUnread--;
    this.notificationService.updateNotification(notification.id).subscribe(
      () => {},
      () => {
        notification.has_seen = false;
        this.numUnread++;
        this.logger.logError("notifications can't be updated.");
      }
    );
  }
}
