/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/branding.component.ngfactory";
import * as i2 from "../header/branding.component";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/common";
import * as i6 from "../sidemenu/sidemenu.component.ngfactory";
import * as i7 from "../sidemenu/sidemenu.component";
import * as i8 from "../../../core/services/menu.service";
import * as i9 from "../../../routes/sessions/login/auth.service";
import * as i10 from "./sidebar.component";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "matero-sidebar-header"], ["style", "background-color: #173A64;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-branding", [], null, null, null, i1.View_BrandingComponent_0, i1.RenderType_BrandingComponent)), i0.ɵdid(2, 49152, null, 0, i2.BrandingComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i0.ɵdid(4, 671744, null, 0, i3.DefaultFlexDirective, [i0.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "matero-sidebar-main scrollbar-none"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-sidemenu", [], null, null, null, i6.View_SidemenuComponent_0, i6.RenderType_SidemenuComponent)), i0.ɵdid(4, 114688, null, 0, i7.SidemenuComponent, [i8.MenuService, i9.AuthService], { ripple: [0, "ripple"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showToggle; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 49152, null, 0, i10.SidebarComponent, [], null, null)], null, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i10.SidebarComponent, View_SidebarComponent_Host_0, { showToggle: "showToggle", showUser: "showUser", showHeader: "showHeader", toggleChecked: "toggleChecked" }, { toggleCollapsed: "toggleCollapsed" }, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
