<div style="padding: 20px;">
  
  <div *ngIf="loadingTable || loadingAvailableCredit" style="width: 100%; margin-top: 100px;"
    fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="!loadingTable && !loadingAvailableCredit">
    <div fxLayout="row wrap">
      <app-available-credit *ngIf="creditLimitInfoMap" [creditLimitInfo]="creditLimitInfoMap" (tradeFinanceFilterFlag)="setFinanceFlag($event)" fxFlex="50">
      </app-available-credit>
      <app-finance-status [financeStatus]="financeStatus" [isTradeFinance]="isTradeFinance" fxFlex="50"></app-finance-status>
    </div>

    <div *ngIf="finances" class="box" style="margin-bottom: 0px;">
      <div fxLayout="row" style="border-bottom: 0.5px solid #cecece;">
        <div style="width:100px; height:90px;">
          <img src="assets/images/Content_Icon3b.png" draggable="false" class="matero-branding-logo-expanded" alt="logo"
            style="margin:10px; border: none;" />
        </div>
        <div>
          <h2 style="margin-bottom: 3px; color: #173A64;">Finance Summary</h2>
          <div style="color:gray; font-size:small;">Transaction Status</div>
        </div>
        <div style="margin-left: auto;">
          <mat-form-field appearance="outline" style="margin-right: 12px; margin-top: 8px;" class="my-form-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input autocomplete="off" matInput (ngModelChange)="searchTextChanged($event)" [(ngModel)]="searchText"
              placeholder="search">
          </mat-form-field>
        </div>
      </div>

      <table mat-table matSort [dataSource]="dataSource" class="full-width-table">
        <ng-container matColumnDef="finance_id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Application Number </th>
          <td mat-cell *matCellDef="let element"> <a style="text-decoration:underline; cursor: pointer;"
              [innerHTML]="element.finance_id | highlightSearch: searchText"
              (click)="financeNumClicked(element.finance_id, element.finance_type)"></a> </td>
        </ng-container>

        <ng-container matColumnDef="importer_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Importer</th>
          <td [innerHTML]="element.importer_name | highlightSearch: searchText" mat-cell *matCellDef="let element">
          </td>
        </ng-container>

        <ng-container matColumnDef="exporter_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Exporter</th>
          <td [innerHTML]="element.exporter_name | highlightSearch: searchText" mat-cell *matCellDef="let element">
          </td>
        </ng-container>

        <ng-container matColumnDef="finance_type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef fxShow fxHide.lt-lg="true"> Finance Type </th>
          <td [innerHTML]="utils.financeTypeDisplayString(element.finance_type) | highlightSearch: searchText" mat-cell *matCellDef="let element" fxShow fxHide.lt-lg="true"> </td>
        </ng-container>

        <ng-container matColumnDef="submitted_at">
          <th mat-header-cell mat-sort-header *matHeaderCellDef fxShow fxHide.lt-lg="true"> Date Applied </th>
          <td [innerHTML]="element.submitted_at | localizedDate | highlightSearch: searchText" mat-cell
            *matCellDef="let element" fxShow fxHide.lt-lg="true"></td>
        </ng-container>

        <ng-container matColumnDef="requested_amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef fxShow fxHide.lt-lg="true"> Amount </th>
          <td
            [innerHTML]="element.requested_amount | currency: element.invoice_currency : 'symbol' : '1.0-0' | highlightSearch: searchText"
            mat-cell *matCellDef="let element" fxShow fxHide.lt-lg="true"> </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
          <td [innerHTML]="utils.getFinanceState(element.state) | highlightSearch: searchText" mat-cell
            *matCellDef="let element" [matTooltip] = "utils.getFinanceStateTooltip(element.state)" [style.color]="utils.getFinanceStatusColor(element.state)"> </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">

            <ng-container *ngIf="utils.shouldShowUpdateButton(element)">

              <a style="text-decoration:underline; cursor: pointer;"
                (click)="transactionsClicked(element.finance_id, element.finance_type)">
                Transactions
              </a>

              <span>&nbsp;/&nbsp;</span>

              <ng-container *ngIf="(element.finance_type=='TradeFinance') || ((element.finance_type=='LogisticsFinance') && (user.organisation_type!=='DTF'))">
                <a style="text-decoration:underline; cursor: pointer;"
                  (click)="actionClicked(element.finance_id, element.finance_type)">
                  Update
                </a>
  
                <span>&nbsp;/&nbsp;</span>
              </ng-container>

            </ng-container>

            <a style="text-decoration:underline; cursor: pointer;"
              (click)="viewClicked(element.finance_id, element.finance_type)">
              View
            </a>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </ng-container>
</div>