import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@core';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() nav: string[] = [];
  @Input() diplayData:any = {};

  constructor(private router: Router, private menuService: MenuService,
    private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    //console.log("ngOnChanges; BreadcrumbComponent");
    this.genBreadcrumb();

    this.ref.detectChanges();
  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  genBreadcrumb() {
    /*
    const states = this.router.url.slice(1).split('/');
    this.nav = this.menuService.getMenuLevel(states);
    this.nav.unshift('home');
    */
  }
}
