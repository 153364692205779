import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { MenuService } from './menu.service';
import { environment } from 'environments/environment';

@Injectable()
export class StartupService {
  constructor(private menuService: MenuService, private http: HttpClient) {}

  load(): Promise<any> {
    console.log('load');
    const menuUrl = 'assets/data/menu.json?_t=' + Date.now();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return new Promise((resolve, reject) => {
      this.http
        .get(menuUrl, httpOptions)
        .pipe(
          catchError(err => {
            console.log('StartupService -> errr -> res', err);
            resolve();
            return err;
          })
        )
        .subscribe(
          (res: any) => {
            this.menuService.set(res.menu);
          },
          () => {},
          () => {
            resolve();
          }
        );
    });
  }
}
