import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
import { RegisterService } from './register.service';
import { RegistrationResponse } from '../login/user.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})

/**
 * @author charles.skinner@dpworld.com
 */
export class RegisterComponent implements OnInit {
  reactiveForm: FormGroup;

  registrationSubscription:Subscription;

  constructor(private fb: FormBuilder,
    private ui:UiService,
    private logger:LogService,
    private registerService:RegisterService,
    private router:Router) {

    this.reactiveForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [this.confirmValidator]],
      accountType: ['', [Validators.required]],
      firstName:  ['', [Validators.required]],
      lastName:  ['', [Validators.required]],
      country:  ['', [Validators.required]],
      phoneNumber:  ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  ngOnInit() {}

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.reactiveForm.controls.password.value) {
      return { error: true, confirm: true };
    }
    return {};
  };

  register(){
    console.log("register;");

    let user = this.reactiveForm.value;

    this.registerService.register(user).subscribe((response:RegistrationResponse)=>{

      if (!response){
        this.logger.logError("The backend isn't accepting registration right now.");
        this.ui.snackbar("The backend isn't accepting registration right now.");
      } else {
        this.ui.snackbar("Your registration is complete! ");
        this.router.navigate(['/auth/login']);
      }
    }, 
    (error)=>{
      this.logger.logError("The backend isn't accepting registration right now.");
      this.ui.snackbar("The backend isn't accepting registration right now.");
    });
  }

  ngOnDestroy(){

    // When navigating to a new page/view make sure we don't have a snackbar from the last view still showing,
    // this can confuse the user and will routinely happen when the session is stale and we redirect the user to 
    // login page to relogin, the backend failure snackbar will still be showing
    this.ui.closeCurrentSnackbar();

    if (this.registrationSubscription){
      this.registrationSubscription.unsubscribe();
    }
  }
}
