import { Injectable } from '@angular/core';
import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { of } from 'rxjs';
import { UserRegistration } from '../login/user.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

/**
 * @author charles.skinner@dpworld.com
 */
export class RegisterService {

  constructor(private staticData:StaticDataService,
    private http: HttpClient) { }

  register(user:UserRegistration){
    return of();

    let url = environment.rootUrl + environment.urls.register;
    return this.http.get(url);
  }
}
