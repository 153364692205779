import * as i0 from "@angular/core";
var MenuService = /** @class */ (function () {
    function MenuService() {
        this.menu = [];
    }
    MenuService.prototype.getAll = function () {
        return this.menu;
    };
    MenuService.prototype.set = function (menu) {
        this.menu = this.menu.concat(menu);
        return this.menu;
    };
    MenuService.prototype.add = function (menu) {
        this.menu.push(menu);
    };
    MenuService.prototype.getMenuItemName = function (stateArr) {
        return this.getMenuLevel(stateArr)[stateArr.length - 1];
    };
    // TODO:
    MenuService.prototype.getMenuLevel = function (stateArr) {
        var tmpArr = [];
        //console.log("this.menu: "+JSON.stringify(this.menu) );
        this.menu.map(function (item) {
            if (item.state === stateArr[0]) {
                tmpArr.push(item.name);
                // Level1
                if (item.children && item.children.length) {
                    item.children.forEach(function (itemlvl1) {
                        if (stateArr[1] && itemlvl1.state === stateArr[1]) {
                            tmpArr.push(itemlvl1.name);
                            // Level2
                            if (itemlvl1.children && itemlvl1.children.length) {
                                itemlvl1.children.forEach(function (itemlvl2) {
                                    if (stateArr[2] && itemlvl2.state === stateArr[2]) {
                                        tmpArr.push(itemlvl2.name);
                                    }
                                });
                            }
                        }
                        else if (stateArr[1]) {
                            // Level2
                            if (itemlvl1.children && itemlvl1.children.length) {
                                itemlvl1.children.forEach(function (itemlvl2) {
                                    if (itemlvl2.state === stateArr[1]) {
                                        tmpArr.push(itemlvl1.name, itemlvl2.name);
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
        //console.log("tmpArr: "+JSON.stringify(tmpArr) );
        return tmpArr;
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
