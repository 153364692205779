import { Component } from '@angular/core';
import { AuthService } from 'app/routes/sessions/login/auth.service';
import { User } from 'app/routes/sessions/login/user.model';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-user',
  template: `
    <a
      mat-button
      class="matero-toolbar-button matero-avatar-button"
      href="javascript:void(0)"
      [matMenuTriggerFor]="menu"
    >
      <img draggable="false" class="matero-avatar" src="assets/images/avatar.jpg" width="32" alt="avatar" />
      <span class="matero-username" fxHide.lt-sm>{{user?.username}}</span>
    </a>

    <mat-menu style="width:200px;" #menu="matMenu">
      <a routerLink="/settings/def" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </a>
      <a (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </a>

      <div *ngIf="isDevMode()" style="margin:10px;" fxLayout="column" fxLayoutAlign="start center">
        <div>
        Role: {{ user?.role }}
        </div>
        <div>
        {{ user?.organisation_name }}
        </div>
      </div>
    </mat-menu>
  `,
})

/**
 * Top right of site, clicking opens this.
 */
export class UserComponent {

  isDevMode=isDevMode;

  user:User;

  constructor(private authService:AuthService){}

  ngOnInit(){
    //TODO: pull user info from login service once it's setup.
    this.user = this.authService.currentUserValue;
  }

  logout() {
    console.log("logout;");
    this.authService.logout();
  }
}
