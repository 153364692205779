import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar, MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})

/**
 * provides UI utilities.
 * @author charles.skinner@dpworld.com
 */
export class UiService {

  currentSnackBar:MatSnackBarRef<SimpleSnackBar>=null;

  constructor(private bar: MatSnackBar, private dialog: MatDialog) { }

  /**
   * Displays a message to the user, in the top center of their screen. Wraps angular material snackbar.
   * @param message 
   * @param action 
   * @param config 
   */
  snackbar(message: string, action: string=null, config: MatSnackBarConfig={}): MatSnackBarRef<SimpleSnackBar> {
    this.currentSnackBar = this.bar.open(message, action, { verticalPosition: 'top', duration: 4000, panelClass: 'warn', ...config });
    return this.currentSnackBar;
  }
  
  /**
   * Closes the currently displayed snackbar. This is called when the user navigates away from a tabs/view
   */
  closeCurrentSnackbar(){
    if (this.currentSnackBar){
      this.currentSnackBar.dismiss();
    }
  }
}
