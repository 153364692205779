import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from '@core';
import { isDevMode } from '@angular/core';
import { AuthService } from 'app/routes/sessions/login/auth.service';
import { User } from 'app/routes/sessions/login/user.model';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  // we use this to check if we are in dev or prod to only render some tabs in dev
  isDevMode = isDevMode;

  menus = this.menuService.getAll();

  user: User;

  userIsDTF=false;
  userIsBank=false;

  constructor(private menuService: MenuService, private authService: AuthService) {}

  ngOnInit() {
    this.user = this.authService.currentUserValue;

    if (this.user.organisation_type==="DTF"){
      this.userIsDTF = true;
    }

    if (this.user.organisation_type==="BANK"){
      this.userIsBank = true;
    }
  }

  // Delete empty values and rebuild state
  buildState(states: string[]) {
    let state = '';
    states.forEach(item => {
      if (item && item.trim()) {
        state += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return state;
  }
}
