import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../login/auth.service';
import { LogService } from 'app/dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { environment } from 'environments/environment';
import { isDevMode } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { UtilsService } from 'app/dp-world/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})

/**
 * The tab/screen that allows us to login.
 * @author charles.skinner@dpworld.com
 */
export class LoginComponent implements OnInit {
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  
  loginSubscription:Subscription;

  loginForm: FormGroup;
  isDevMode = isDevMode;
  captchaToken = null;
  loginAttempts = 0;

  loggingIn = false;

  environment=environment;

  usersList = {
    BCO_importer: {
      username: 'chandler@century.com',
    },
    BCO_exporter: {
      username: 'ahmed@maxo.com',
    },
    bank: {
      username: 'monica@dfb.com',
    },
    admin: {
      username: 'joey@dpworld.com',
    },
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private logger: LogService,
    private ui: UiService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public utils:UtilsService,
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  returnUrl: string;

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    // if we we're redirected here to login and hence don't have a 
    // destination page, just go to the dashboard when we finish.
    if (this.returnUrl === '/') {
      this.returnUrl = '/home/def';
    }
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : environment.recaptchaSiteKey,
      'callback': (response) => {
          this.captchaToken = response;
      }
    });
  }
 
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }
    // This method will load the Google ReCaptcha Library
    (function(div, element, id, obj){
      var js, fjs = div.getElementsByTagName(element)[0];
      if (div.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = div.createElement(element); 
      js.id = id;
      js.src = environment.recaptchaUrl;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  // called by testing tool
  setUsername(user) {
    this.loginForm.patchValue({
      username: user.username,
      password: 'demo1234',
    });

    this.login();
  }

  login() {
    if(this.loginAttempts >= environment.maxLoginAttemptsWithoutCaptcha && !this.captchaToken){
      this.ui.snackbar("Please verify the captcha");
      return;
    } else if(this.loginAttempts >= environment.maxLoginAttemptsWithoutCaptcha && this.captchaToken){
      this.authService
      .verifyCaptcha(this.captchaToken)
      .subscribe((response: any) => {
          if (!response) {
            this.ui.snackbar("The backend isn't verifying captcha now");
            window['grecaptcha'].reset();
            this.captchaToken = null;
            return;
          }
          if(!response.success){
            this.ui.snackbar("Captcha verification failed, please verify again");
            window['grecaptcha'].reset();
            this.captchaToken = null;
            return;
          }
        },
        error => {
          console.log('captcha verification error ', error);
          this.ui.snackbar("The backend isn't verifying captcha now");
          return;
        }
      );
    }

    this.loggingIn = true;

    this.loginSubscription = this.authService
      .login({
        username: this.loginForm.value.username,
        password: this.loginForm.value.password, 
      })
      .subscribe(
        (data: any) => {
          if (!data) {
            this.ui.snackbar("The backend isn't allowing logins right now.");
            return;
          }
          console.log('login; success!');

          // if this is our first time logging in
          if (data.has_reset_initial_password != null && data.has_reset_initial_password == false){
            // force them to reset password
            this.ui.snackbar("You must change your password.");
          }

          this.loggingIn = false;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          console.log('login; error: ', error);
          this.ui.snackbar('Invalid username or password!');
          this.loggingIn = false;
          this.loginAttempts += 1;
          if(this.loginAttempts == environment.maxLoginAttemptsWithoutCaptcha){
            this.addRecaptchaScript();
          } else if(this.loginAttempts >= environment.maxLoginAttemptsWithoutCaptcha){
            window['grecaptcha'].reset();
            this.captchaToken = null;
          }
        }
      );
  }

  ngOnDestroy(){

    // When navigating to a new page/view make sure we don't have a snackbar from the last view still showing,
    // this can confuse the user and will routinely happen when the session is stale and we redirect the user to 
    // login page to relogin, the backend failure snackbar will still be showing
    this.ui.closeCurrentSnackbar();

    if (this.loginSubscription){
      this.loginSubscription.unsubscribe();
    }
  }
}
