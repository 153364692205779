import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
    }
    NotificationService.prototype.getNotifications = function () {
        var url = environment.rootUrl + environment.urls.notifications;
        return this.http.get(url);
    };
    NotificationService.prototype.updateNotification = function (notificationId) {
        var url = environment.rootUrl + 'notifications/' + notificationId + '/status';
        return this.http.put(url, null);
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
