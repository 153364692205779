import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReasonDialogComponent } from 'app/routes/apply/def/reason-dialog/reason-dialog.component';

@Component({
  selector: 'app-apply-logistics-dialog',
  templateUrl: './apply-logistics-dialog.component.html',
  styleUrls: ['./apply-logistics-dialog.component.scss']
})

/**
 * Popup that leads to Logistics application.
 */
export class ApplyLogisticsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder:FormBuilder) { }

    typeControl:FormControl;

    isDevMode=isDevMode;

    financeType='normal';

  ngOnInit() {
    this.typeControl = new FormControl('');
  }

  submitClicked(){
    console.log("submitClicked;");
    this.dialogRef.close("logistics");
  }

  cancelClicked(){
    this.dialogRef.close("cancel");
  }

  financeTypeChanged(){
    console.log("financeTypeChanged;"+this.financeType);
  }

  logisticsClicked() {
    this.dialogRef.close("logistics");
  }

}
