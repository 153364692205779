export const commonEnvironment = {
  // --- LOCAL ----
  // rootUrl: 'http://localhost:3600/',

  // ---- staging ----
  rootUrl: 'http://staging-api.revma.dpworld.com:8089/',
  // rootUrl: 'http://18.191.0.111:8089/', // staging

  // ---- Prod  -----
  //rootUrl: 'http://prod-api.revma.dpworld.com:8089',
  //rootUrl: 'http://18.191.0.111/',   //prod

  // NOTE: below here is all stuff copied from CargoFlow, i'm going to trim this down later. ////////////////

  // special properties? ///////////////////////
  apiValue: '7cf0e70059e5bfb3c37171cc7cd229cc', // prod
  //rootUrl: 'http://prod-api.revma.dpworld.com/',

  productionHosting: false,
  staging: false,

  noHeaderRoutes: ['login', 'register'],
  HEADER: {
    X_API_KEY: 'X-DPW-ApiKey',
    X_USER_TOKEN: 'X-DPW-User-Token',
    X_USER_ID: 'X-User-Id',
    X_TRACE_CONTEXT: 'X-Trace-Context',
    X_CLIENT_SOURCE: 'X-Client-Source', // android-native, ios-native, android-web, ios-web web
    X_TOTAL_COUNT: 'X-Total-Count',
    X_DISPLAY_MODE: 'X-Display-Mode', // web, mobile
    AUTHORIZATION: 'Authorization',
  },

  urls: {
    // old stuff from CargoFlow
    register: 'admin/register',
    invite_users: 'admin/inviteUsers',
    remove_user: 'admin/users/:userCode/remove',
    get_user: 'admin/users',
    edit_user: 'admin/users/:userCode',
    change_password: 'admin/users/:userCode/password',
    verify_email: 'admin/verifyEmail/:verificationCode',
    
    shared_data: 'staticData', // GET

    // login endpoints
    login: 'login', // POST // Username: demouser and password: demo123
    logout: 'logout', // POST
    verify_captcha: 'verifyCaptcha',

    // save endpoints
    save_finance: 'trade-finance/save', // POST
    save_finance_by_id: 'trade-finance/:id/save', // PUT
    save_logistics_finance: 'logistics-finance/save', // POST
    save_logistics_finance_by_id: 'logistics-finance/:id/save', // PUT

    // submit endpoints
    submit_finance: 'trade-finance/submit', // POST
    submit_finance_by_id: 'trade-finance/:id/submit', // PUT
    submit_logistics_finance: 'logistics-finance/submit', // POST
    submit_logistics_finance_by_id: 'logistics-finance/:id/submit', // PUT

    get_finance: 'finances?_limit=60&_page=1', // GET
    get_finance_by_id: 'trade-finance/:id', // GET
    get_companies: 'organisations?verified=true', // GET
    get_transactions: 'transactions?_limit=100&_page=1',
    get_transaction: 'finances/:financeId/transactions/:transactionId', //GET
    notifications_read: 'notifications/:id/status', // PUT
    notifications: 'notifications', // GET
    trade_credit_limit: 'trade-finance/credit-summary', // GET
    logistics_credit_limit: 'logistics-finance/credit-summary', // GET
    vessel: 'https://fosq22wld7.execute-api.eu-west-1.amazonaws.com/dev/vesseltracksingle',
    get_settings: '',
    save_settings: '',
    upload_document: '',
  },
  storage: {
    currentUser: 'current-user',
    poFilters: 'po-filters',
    containerFilters: 'container-filters',
    oceanShipmentFilters: 'shipment-filters',
    productFilters: 'product-filters',
    locationFilters: 'location-filters',
    tpFilters: 'tp-filters',
    InlandShipmentFilters: 'inland-shipment-filters',
    ShipmentFilters: 'shipments-filters',
    applicationSettings: 'application-settings',
  },
  tablePageSize: 20,
  debounceTime: 450,
  alertRemoveTimer: 1500, // 1.5 seconds to mark alerts as read
  cacheMaxAge: {
    poList: 60000, // 60 seconds
    poSuppliers: 300000, // 5 minutes
  },
  maxLoginAttemptsWithoutCaptcha: 3,
  recaptchaSiteKey: '6Le6bvoUAAAAAHOyATilmn5kDxR_hB7E2gKxbpSs',
  recaptchaUrl: 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=6Le6bvoUAAAAAHOyATilmn5kDxR_hB7E2gKxbpSs',

  apiKey: 'X-DPW-ApiKey',
  apiKeyVessel: 'x-api-key',
  apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
  uiSetting: {
    select_All_DisplayText: 'All',
  },

  API_RESPONSE_CODES: {
    STATUS_CODES: {
      200: 'Ok',
      201: 'Created',
      400: 'Bad request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Not found',
      409: 'Conflict',
      429: 'Too Many Requests',
      440: 'Login expired',
      500: 'Server error',
    },
    REASON_CODES: {
      // the first three digits are the corresponding status code; the text will be used in log
      20000: 'Success',
      20100: 'Created',
      40010: 'Invalid request data',
      40020: 'Invalid email address',
      40030: 'Bad argument error',
      40110: 'No API Key provided',
      40120: 'Invalid API Key',
      40130: 'Blocked by access_control',
      40140: 'Blacklisted IP address',
      40150: 'Missing required header',
      40160: 'Invalid value in header',
      40310: 'Client not provisioned to access route',
      40320: 'Missing Authorization header or it is invalid',
      40330: 'Incorrect username or password',
      40340: 'Username in header does match login request parameter',
      40410: 'The requested object does not exist',
      40910: 'Conflict in creation',
      42910: 'The rate of requests is over provisioned limit',
      44010: 'User token expired',
      50090: 'Internal Sever Error',
    },
  },
};
