import { OnInit, EventEmitter, } from '@angular/core';
import * as screenfull from 'screenfull';
import { Router } from '@angular/router';
import { AddOrganizationComponent } from 'app/routes/logistics-finance/step-a/add-organization/add-organization.component';
import { MatDialog } from '@angular/material';
import { DashboardService } from 'app/routes/dashboard/dashboard.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, dialog, dashboardService, ui, logger) {
        this.router = router;
        this.dialog = dialog;
        this.dashboardService = dashboardService;
        this.ui = ui;
        this.logger = logger;
        this.showToggle = true;
        this.showBranding = false;
        this.toggleSidenav = new EventEmitter();
        this.toggleSidenavNotice = new EventEmitter();
    }
    Object.defineProperty(HeaderComponent.prototype, "screenfull", {
        get: function () {
            return screenfull;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () { };
    // TODO:
    HeaderComponent.prototype.toggleFullscreen = function () {
        if (this.screenfull.enabled) {
            this.screenfull.toggle();
        }
    };
    HeaderComponent.prototype.openFAQ = function () {
        console.log("openFAQ;");
        this.router.navigate(['/faq/def']);
    };
    HeaderComponent.prototype.addOrganizationClicked = function () {
        var _this = this;
        console.log("addOrganizationClicked; ");
        //navigate to new application.
        var dialogRef = this.dialog.open(AddOrganizationComponent, {
            width: '500px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed: ' + result);
            if (result) {
                _this.addOrganizationFinished(result);
            }
        });
    };
    HeaderComponent.prototype.addOrganizationFinished = function (response) {
        var _this = this;
        console.log("addOrganizationFinished; response: ", response);
        var request = {
            "fullName": response.name,
            "primaryContactPhone": response.phone,
            "primaryContactEmail": response.email
        };
        this.dashboardService.inviteOrganization(request).subscribe(function (result) {
            if (!result) {
                _this.ui.snackbar('The backend is currently not allowing add organization');
                _this.logger.logError('The backend is currently not allowing add organization');
                return;
            }
            _this.ui.snackbar('The organization has been invited! They will receive a signup email soon.');
            console.log("inviteOrganization; result: ", result);
        }, function (error) {
            if (error.error.errorMessage == "Organisation details already exist") {
                _this.ui.snackbar("This organization has already been invited to the platform.");
                return;
            }
            _this.ui.snackbar('The backend is currently not allowing add organization');
            _this.logger.logError('The backend is currently not allowing add organization');
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
