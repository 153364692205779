import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Constants for the project. Stored in service so can be easily replaced.
 * @author charles.skinner@dpworld.com
 */
export class ConstantsService {

  // this is the mock backend they setup
  public baseURL = 'http://18.191.0.111:3007';

  public directories = {
    // dashboard stuff
    'transactions': '/transactions',       // GET
    'finances': '/finances',               // GET
    'creditLimit': '/creditLimit',         // GET
    'notifications': '/:user/notifications', // GET
    'updateNotifications': '/update/readstatus'  // POST
  }

  public terminals = [
    "Seattle",
    "Dubai",
    "Shanghi",
  ];

  public proofTypes = [
    "Type1",
    "Type2",
    "Type3",
  ];

  public banks = [
    "Bank1",
    "Bank2"
  ];

  public currencys = [
    "USD",
    "AED",
  ];

  public transactionTypes = [
    "Account Settled",
  ];

  public statuses = [
    "Cargo inspected positive",
    "Bill of Lading submitted",
    "Vessel departed"
  ];

  public friendlyStateNames = {
    BCO_FINANCE_SAVED: 'Draft',         // Trade Finance Apply step
    BCO_FINANCE_SUBMITTED: 'In Process',     // Document Endorse step (other BCO reviewing)
    BCO_FINANCE_ACCEPTED: 'In Process',      // Enrich Data step (DTF review) 
    DTF_FINANCE_ACCEPTED: 'In Process',      // Bank Assessment (Banker review)
    BANKS_FINANCE_ASSESSED: 'In Process',    // Accept Terms (BCO's review terms)
    BCO_TERMS_AGREED: 'Approved',          // Trade Finance Approved

    // dynamic shipping events
    EXPORTER_PRODUCTION_COMPLETED: 'Funded',
    CARGO_READY_TO_SHIP: 'Funded',
    CARGO_GATED_IN_PORT: 'Funded',
    CARGO_RECEIVED_AT_YARD: 'Funded',
    CARGO_INSPECTED_ORIGINPORT: 'Funded',
    CARGO_LOADED: 'Funded',
    VESSEL_DEPARTED: 'Funded',

    BANK_FUNDS_TRANSFERRED: 'Funded',    // Funds Successfully Transferred
    ACCOUNT_SETTLED: 'Settled',           // Account Settled
    DTF_FINANCE_CLOSED: 'Settled',        // Account Settled
    FINANCE_CANCELLED: 'Settled'          // Account Settled
  };

  // used by state drop downs
  public friendlyStateNamesLong = {
    "PURCHASE_ORDER_ACCEPTED": "purchase order accepted",
    "PROFORMA_INVOICE_ACCEPTED": "proforma invoice accepted",
    "EXPORTER_PRODUCTION_COMPLETED": "exporter production completed",
    "CARGO_READY_TO_SHIP": "cargo ready to ship",
    "CARGO_GATED_IN_ORIGIN_PORT": "cargo gated in origin port",
    "CARGO_RECEIVED_ORIGIN_YARD": "cargo received origin yard",
    "CARGO_INSPECTED_ORIGIN_PORT": "cargo inspected origin port",
    "CARGO_LOADED_ORIGIN_PORT": "cargo loaded origin port",
    "VESSEL_DEPARTED_ORIGIN_PORT": "vessel departed origin port",
    "VESSEL_ARRIVED_DESTINATION_PORT": "vessel arrived destination port",
    "CARGO_UNLOADED_DESTINATION_YARD": "cargo unloaded destination yard",
    "CARGO_CLEARED_IMPORTS_DESTINATION_PORT": "cargo cleared imports destination port",
    "CARGO_INSPECTED_DESTINATION_PORT": "cargo inspected destination port",
    "CARGO_RELEASE_GENERATED_DESTINATION_PORT": "cargo released generated destination port",
    "CARGO_GATED_OUT_DESTINATION_PORT": "cargo gated out destination port",
    "CARGO_RECEIVED_FREIGHT_FORWARDER": "cargo recieved freight forwarder",
    "CARGO_LOADED_DESTINATION_TRUCK": "cargo loaded destination truck",
    "CARGO_RECEIVED_IMPORTER_FACILITY": "cargo received importer facility"
  };

  applicantTypes = [
    'importer',
    'exporter',
  ];

  // used by tables in Dashboard and Applications
  financeTypeDisplayName = {
    TradeFinance: 'Trade',
    LogisticsFinance: 'Logistics'
  };
}
