/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "../../../../../node_modules/ngx-file-drop/ngx-file-drop.ngfactory";
import * as i17 from "ngx-file-drop";
import * as i18 from "@angular/common";
import * as i19 from "./upload-wrapper.component";
import * as i20 from "../../../dp-world/services/ui.service";
var styles_UploadWrapperComponent = [i0.styles];
var RenderType_UploadWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadWrapperComponent, data: {} });
export { RenderType_UploadWrapperComponent as RenderType_UploadWrapperComponent };
function View_UploadWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag and drop a file here or"])), (_l()(), i1.ɵeld(6, 16777216, null, null, 5, "button", [["class", "matero-toolbar-button"], ["mat-icon-button", ""], ["matTooltip", "Select a File"], ["style", "margin-left: 0px;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_v.context.openFileSelector() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 212992, null, 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i11.Platform, i6.AriaDescriber, i6.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["folder_open"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "text-gray-600"], ["style", "font-size: smaller;"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["Supported file formats: ", " "]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "Select a File"; _ck(_v, 8, 0, currVal_4); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.supportedFileTypes; _ck(_v, 13, 0, currVal_7); }); }
function View_UploadWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"], ["style", "vertical-align: middle;"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["attachment"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploaded File - "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.file.fileEntry.name; _ck(_v, 7, 0, currVal_3); }); }
export function View_UploadWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "min-width: 400px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ngx-file-drop", [], null, [[null, "onFileDrop"], [null, "onFileOver"], [null, "onFileLeave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileDrop" === en)) {
        var pd_0 = (_co.fileDropped($event) !== false);
        ad = (pd_0 && ad);
    } if (("onFileOver" === en)) {
        var pd_1 = (_co.fileOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("onFileLeave" === en)) {
        var pd_2 = (_co.fileLeave($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i16.View_NgxFileDropComponent_0, i16.RenderType_NgxFileDropComponent)), i1.ɵdid(2, 180224, null, 1, i17.NgxFileDropComponent, [i1.NgZone, i1.Renderer2], null, { onFileDrop: "onFileDrop", onFileOver: "onFileOver", onFileLeave: "onFileLeave" }), i1.ɵqud(603979776, 1, { contentTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_UploadWrapperComponent_1)), i1.ɵdid(5, 16384, null, 0, i17.ɵa, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "upload-file"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadWrapperComponent_2)), i1.ɵdid(8, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_UploadWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-wrapper", [], null, null, null, View_UploadWrapperComponent_0, RenderType_UploadWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i19.UploadWrapperComponent, [i20.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadWrapperComponentNgFactory = i1.ɵccf("app-upload-wrapper", i19.UploadWrapperComponent, View_UploadWrapperComponent_Host_0, { supportedFileTypes: "supportedFileTypes" }, { fileUploaded: "fileUploaded" }, []);
export { UploadWrapperComponentNgFactory as UploadWrapperComponentNgFactory };
