import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'app/routes/sessions/login/auth.service';

@Pipe({
  name: 'localizedDate'
})

// converts to this format: 3/31/2020
export class LocalizedDatePipe implements PipeTransform {

  datePipe: PipeTransform

  transform(value: any, format: string = 'short'): any {
    return value ? this.datePipe.transform(value, format) : '';
  }

  constructor(private authService: AuthService) {
    let localeId = this.authService.getUserLocale();
    this.datePipe = new DatePipe(localeId);
  }
}
