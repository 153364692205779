import { Subject } from 'rxjs';
import { defaults } from '../settings';
import * as i0 from "@angular/core";
var SettingsService = /** @class */ (function () {
    function SettingsService() {
        this.notice$ = new Subject();
        this.options = defaults;
    }
    Object.defineProperty(SettingsService.prototype, "notice", {
        get: function () {
            return this.notice$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    // What calls this?
    // March 9, 2020 - since we don't know what calls this, we'll work around it.
    SettingsService.prototype.setLayout = function (options) {
        //console.log("setLayout; options: "+JSON.stringify(options) );
        var settings = localStorage.getItem("userSettings");
        this.options = Object.assign(defaults, settings);
        return this.options;
    };
    SettingsService.prototype.setNavState = function (type, value) {
        //console.log("setNavState;  type : "+type+" value: "+value);
        this.notice$.next({ type: type, value: value });
    };
    SettingsService.prototype.getOptions = function () {
        var settings = {
            navPos: 'side',
            theme: 'light',
            dir: 'ltr',
            showHeader: true,
            headerPos: 'fixed',
            showUserPanel: true,
            sidenavOpened: false,
            sidenavCollapsed: true,
        };
        //console.log("getOptions; settings : ", settings );
        return settings;
        //
        //return this.options;
    };
    SettingsService.prototype.setOptions = function (settings) {
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
