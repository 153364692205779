import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    //private uiService: UIService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = this.authenticationService.currentUserValue;

    //const isHandset = this.uiService.isHandsetChanged.getValue();

    if (request.url === environment.urls.vessel) {
      request = request.clone({
        setHeaders: {
          [environment.apiKeyVessel]: environment.apiValueVessel,
        }
      });
    } else if (currentUser && currentUser.token) {

      let headers = {
        [environment.HEADER.X_USER_TOKEN]: currentUser.token,
        'x-user-id': currentUser.user_id || '',
        //[environment.HEADER.X_DISPLAY_MODE]: 'web'
        //[environment.HEADER.X_DISPLAY_MODE]: isHandset ? 'mobile' : 'web'
      };

      request = request.clone({
        setHeaders: headers
      });

    } else {
      request = request.clone({
        setHeaders: {
          [environment.apiKey]: environment.apiValue,
          [environment.HEADER.X_DISPLAY_MODE]: 'web'
          //[environment.HEADER.X_DISPLAY_MODE]: isHandset ? 'mobile' : 'web'
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const token = event.headers.get(environment.HEADER.X_USER_TOKEN);
            if (token && currentUser) {
              // //console.log(
              //   '%c token intercepted=' + token,
              //   'color: lightgreen'
              // );
              currentUser.token = token;
            }
          }
        },
        (error: any) => {

          if (error instanceof HttpErrorResponse) {

            console.log("error.status: "+error.status);

            // authorization has expired
            if ([440, 401].includes(error.status)) {
              this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });

            // internet disconnected
            } else if ([0].includes(error.status)){

              this.router.navigate(['/error-handling/def'], { queryParams: { } });
            }
          }
        }
      )
    );
  }
}