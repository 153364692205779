import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { Transaction, Finance, Notification } from './dashboard.models';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FullFinance, FinanceType } from '../apply/apply-models';

@Injectable({
  providedIn: 'root',
})

/**
 * Provides data for the Dashboard.
 * @author charles.skinner@dpworld.com
 */
export class DashboardService {
  constructor(private staticData: StaticDataService, private http: HttpClient) {}

  /*
  headers = new HttpHeaders({
    'X-User-Id': 'cf1e612e47663b9be6bd3fe1962b8a529846073f190905a86feb29ae7ad719b7',
    'X-DPW-User-Token':
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwMDAwMDA4LCJpYXQiOjE1ODY1MDU2MzgsImV4cCI6MTU4NjU0ODgzOH0.15GwzXG8n3JOnJycJksq4pn1DokHA2FzyPQxXgB5KYM',
  });*/

  getTransactions(): Observable<Array<Transaction>> {
    //return of(this.staticData.transactions);

    let url = environment.rootUrl + environment.urls.get_transactions;
    return this.http.get<Array<Transaction>>(url);
  }

  /**
   * Used by Dashboard and Applications. Applications passes dates, Dashboard doesn't.
   * @param from 
   * @param to 
   */
  getFinances(from:string='', to:string=''): Observable<Array<FullFinance>> {
    let url = environment.rootUrl + environment.urls.get_finance
    
    // if we have a date range, use it
    if (from != '' && to != ''){
      url += '&created_at_from='+from+'&created_at_to='+to;
    }

    return this.http.get<Array<FullFinance>>(url);
  }

  markNotificationsRead(): Observable<any> {
    let url = environment.rootUrl + environment.urls.notifications_read;
    return this.http.get(url);
  }

  // 4-7 working, but no mock data.
  getTradeCreditLimitInfo() {
    //return of(this.staticData.creditLimit2);

    let url = environment.rootUrl + environment.urls.trade_credit_limit;
    return this.http.get(url);
  }

  getLogisticsCreditLimitInfo() {
    //return of(this.staticData.creditLimit2);

    let url = environment.rootUrl + environment.urls.logistics_credit_limit;
    return this.http.get(url);
  }

  ////////////// API Integrations //////////////////
  // TODO: need to shift this apis where ever required

  // GET /organisations
  // params: org_name (search keys), org_type, _limit, _page
  getOrganisations(queries: Array<any>) {
    // url and path
    const url = environment.rootUrl + environment.urls.get_companies;

    // Add query params
    let queryparams = new HttpParams();

    queries.forEach(query => {
      queryparams = queryparams.append(Object.keys(query)[0], query[Object.keys(query)[0]]);
    });

    //return this.http.get(url, { headers: this.headers, params: queryparams });
    return this.http.get(url, { params: queryparams });
  }

  // POST /trade-finance/save
  // Formdata in body.
  saveFinance(formData: FormData, id: string = '', financeType:FinanceType=FinanceType.Trade) {

    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      console.log('submitNewFinance; this is an existing finance! ');
      url += id+"/";
    }

    url += 'save';

    console.log("submitNewFinance; url: "+url);

    return this.http.post(url, formData);
  }

  /*
  // 3. PUT /trade-finance/:id/save
  saveExistingFinance(formData: FormData, financeId: number) {
    // TODO: customize path from environment to add finance_id
    // const url = environment.rootUrl + environment.urls.save_finance_by_id;
    const url = environment.rootUrl + `finance/${financeId}/save`;
    //return this.http.put(url, formData, { headers: this.headers });
    return this.http.put(url, formData);
  }*/

  // POST /trade-finance/submit
  submitNewFinance(formData: FormData, id: string = '', financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      console.log('submitNewFinance; this is an existing finance! ');
      url += id+"/";
    }

    url += 'submit';

    console.log("submitNewFinance; url: "+url);

    return this.http.post(url, formData);
  }

  /*
  // 5. PUT /trade-finance/:id/submit
  submitExistingFinance(formData: FormData, financeId: number) {
    // TODO: customize path from environment to add finance_id
    // const url = environment.rootUrl + environment.urls.save_finance_by_id;

    const url = environment.rootUrl + `trade-finance/${financeId}/submit`;
    //return this.http.put(url, formData, { headers: this.headers });
    return this.http.put(url, formData);
  }*/

  // GET /trade-finance/:id
  getFinanceById(id: number, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    url += id;

    return this.http.get(url);
  }

  // PUT /trade-finance/:id/other-bco-approval
  otherBCOApproveFinance(payload: FormData, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'other-bco-approval';

    return this.http.put(url, payload);
  }

  // PUT /trade-finance/:id/dtf-admin-approval
  dtfAdminApproveFinance(payload: FormData, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'dtf-admin-approval';

    return this.http.put(url, payload);
  }

  // PUT /trade-finance/:id/bank-assessment
  bankAssessment(payload, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'bank-assessment';

    return this.http.put(url, payload);
  }

  // PUT /trade-finance/:id/accept-payment-terms
  acceptTerms(payload, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'accept-payment-terms';

    return this.http.put(url, payload);
  }

  // PUT /trade-finance/:id/update-shipment-event
  updateShipmentEvents(payload, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'update-shipment-event';

    return this.http.put(url, payload);
  }

  // PUT /trade-finance/:id/cancel
  cancelFinance(payload, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'cancel';

    return this.http.put(url, payload);
  }

  // called by owner BCO
  // PUT /trade-finance/:id
  deleteFinance(payload, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id;
    }

    return this.http.delete(url, payload);
  }

  // POST /trade-finance/:id/transaction
  createTransaction(formData: FormData, id: string, financeType:FinanceType=FinanceType.Trade) {
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (id != '') {
      url += id+"/";
    }

    url += 'transaction';

    return this.http.post(url, formData);
  }

  inviteOrganization(request:any){
    let url = environment.rootUrl + 'organisation/invite';
    return this.http.post(url, request);
  }
}
