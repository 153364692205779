
export enum FinanceSubClassification {
    pre = "pre",
    post = "post",
}

export interface Finance {
    financeSubClassification: FinanceSubClassification;         //"pre",
    createdBy: string;                //"ac0f370623415f8be75c2dac39b3cf6ce6933660c4e8510d16a907cccd3102b1",
    importerId: string;               //"org_id_1",
    exporterId: string;               //"org_id_2",
    incoTerms: string;                //"EXW",
    amount: string;                   //"1234.56789",
    currency: string;                 //"USD",
    shipmentOriginPort: string;       //"Dubai",
    shipmentDestinationPort: string;  //"Mumbai"
    currentStep: ApplySteps;          // The current step the user is on.
}

// new version of Finance, used to save and submit to backend.
export interface Finance2 {
    "financeSubClassification": string;     //"pre",
    "id"?: string;
    "createdBy": string;                    //"ac0f370623415f8be75c2dac39b3cf6ce6933660c4e8510d16a907cccd3102b1",
    "importerId": string;                   //"org_id_1",
    "exporterId": string;                   //"org_id_2",
    "incoTerms": string;                    //"EXW",
    "shipmentOriginPort": string;           //"Dubai",
    "shipmentDestinationPort": string;      //"Mumbai",
    "invoiceAmount": number;                //1234.56789,
    "requestedAmount": number;              //1200,
    "financeCurrency": string;              //"USD",
    "cargoDetails": [
        {
            "cargoDescription": string;      //"details about cargo",
            "quantity": number;              //3,
            "unitPrice": number;             //1.50
        }
    ]
    "requestedBanks": [
        {
            "id": string;                   //"1000000", 
            "accountNumber": string;        //"acc_num01"
        }
    ],
    "disbursalEvents": [
        {
            "paymentPercentage": number;     //20,
            "paymentEvent": string;          //"EXPORTER_PRODUCTION_COMPLETED",
            "daysAfterDisbursalEvent": number; //10
            "interestBearer": string;        //"IMPORTER",
            "recourse": string;              //"EXPORTER"
        }
    ],
    "settlementEvents": [
        {
            "settlementPercentage": number;     //20,
            "settlementEvent": string;          //"EXPORTER_PRODUCTION_COMPLETED",
            "daysAfterSettlementEvent": number; //10
        }
    ],
};

export enum ApplySteps {
    Start = "Start",
    Apply = "Apply",
    Endorse = "Endorse",
    Enrich = "Enrich",
    Assessment = "Assessment",
    AcceptTerms = "AcceptTerms",
    Approved = "Approved",
    CargoLoaded = "CargoLoaded",
    FundsTransfered = "FundsTransferred",
    AccountSettled = "AccountSettled",
}

export enum FinanceStates {
    // WHEN I RECIEVE     ->      // WHAT I SHOW
    BCO_FINANCE_SAVED = "BCO_FINANCE_SAVED",                // Trade Finance Apply step
    BCO_FINANCE_SUBMITTED = "BCO_FINANCE_SUBMITTED",        // Document Endorse step (other BCO reviewing)
    BCO_FINANCE_ACCEPTED = "BCO_FINANCE_ACCEPTED",          // Enrich Data step (DTF review) 
    DTF_FINANCE_ACCEPTED = "DTF_FINANCE_ACCEPTED",          // Bank Assessment (Banker review)
    BANKS_FINANCE_ASSESSED = "BANKS_FINANCE_ASSESSED",      // Accept Terms (BCO's review terms)
    BCO_TERMS_AGREED = "BCO_TERMS_AGREED",                  // Trade Finance Approved

    
    // dynamic exporter shipping events
    PURCHASE_ORDER_ACCEPTED = "PURCHASE_ORDER_ACCEPTED",
    PROFORMA_INVOICE_ACCEPTED = "PROFORMA_INVOICE_ACCEPTED",
    EXPORTER_PRODUCTION_COMPLETED = "EXPORTER_PRODUCTION_COMPLETED", // Funds Successfully Transferred
    CARGO_READY_TO_SHIP = "CARGO_READY_TO_SHIP",                     // Funds Successfully Transferred
    CARGO_GATED_IN_ORIGIN_PORT = "CARGO_GATED_IN_ORIGIN_PORT",       // Funds Successfully Transferred
    CARGO_RECEIVED_ORIGIN_YARD = "CARGO_RECEIVED_ORIGIN_YARD",       // Funds Successfully Transferred
    CARGO_INSPECTED_ORIGIN_PORT = "CARGO_INSPECTED_ORIGIN_PORT",     // Funds Successfully Transferred
    CARGO_LOADED_ORIGIN_PORT = "CARGO_LOADED_ORIGIN_PORT",           // Funds Successfully Transferred
    VESSEL_DEPARTED_ORIGIN_PORT = "VESSEL_DEPARTED_ORIGIN_PORT",     // Funds Successfully Transferred

    // dynamic importer shipping events
    VESSEL_ARRIVED_DESTINATION_PORT = 'VESSEL_ARRIVED_DESTINATION_PORT',
    CARGO_UNLOADED_DESTINATION_YARD = 'CARGO_UNLOADED_DESTINATION_YARD',
    CARGO_CLEARED_IMPORTS_DESTINATION_PORT = 'CARGO_CLEARED_IMPORTS_DESTINATION_PORT',
    CARGO_INSPECTED_DESTINATION_PORT = 'CARGO_INSPECTED_DESTINATION_PORT',
    CARGO_RELEASE_GENERATED_DESTINATION_PORT = 'CARGO_RELEASE_GENERATED_DESTINATION_PORT',
    CARGO_GATED_OUT_DESTINATION_PORT = 'CARGO_GATED_OUT_DESTINATION_PORT',
    CARGO_RECEIVED_FREIGHT_FORWARDER = 'CARGO_RECEIVED_FREIGHT_FORWARDER',
    CARGO_LOADED_DESTINATION_TRUCK = 'CARGO_LOADED_DESTINATION_TRUCK',
    CARGO_RECEIVED_IMPORTER_FACILITY = 'CARGO_RECEIVED_IMPORTER_FACILITY',

    BANK_FUNDS_TRANSFERRED = "BANK_FUNDS_TRANSFERRED",      // Funds Successfully Transferred
    ACCOUNT_SETTLED = "ACCOUNT_SETTLED",                    // Account Settled
    DTF_FINANCE_CLOSED = "DTF_FINANCE_CLOSED",              // Account Settled
    FINANCE_CANCELLED = "FINANCE_CANCELLED",                 // Account Settled
}


//used by save and submit
export interface FinanceReply {
    data: {
        financeId: number; //1234
    }
}

export interface SubmitFinanceResponse {

}

export interface UserDetails {
    user_id: string;                    //"fe8559dd3683b1ebf2413724d8d9b6cfb1e51aa3",
    user_full_name:string;              //"Joey Tribbiani",
    organisation_full_name: string;     //"Digital Trade Finance",
    approval_status: string;            //"pending",
    created_at: string;                 //"2020-04-14T09:17:35.744Z",
    updated_at: string;                 //null,
    deleted_at: string;                 //null,
    user_organisation_type: string;     //"DTF",
    organisation_id: string;            //1000001
}

export interface CargoDetail {
    id: number;                               //1000005,
    cargo_description: string;                //"details about cargo",
    quantity: number;                         //3,
    unit_price: string;                       //"1.500000"
}

export interface BankDetail {
    bank_id: number;                          //1000011,
    bank_account_number: string;              //null,
    approval_status: string;                  //"pending"
    bank_full_name: string;
}

export interface DisbursalEvent {
    payment_term_id: number;          //1000006,
    payment_percentage: string;       //"20.00",
    disburse_on_event: string;        //"EXPORTER_PRODUCTION_COMPLETED",
    interest_bearer: string;          //"IMPORTER",
    payment_deadline_in_days: number, //10
    recourse: string;                 //"EXPORTER",
    actual_disbursal_date: string;    //null,
    created_at: string;               //"2020-03-30T03:35:10.171Z",
    updated_at: string;               //null,
    deleted_at: string;               //null
    actual_payment_date: string;
}

export interface SettlementEvent {
    payment_term_id: number;          //1000006,
    settlement_percentage: string;    //"20.00",
    settle_on_event: string;          //"EXPORTER_PRODUCTION_COMPLETED",
    actual_settlement_date: string;   //null,
    settlement_deadline_in_days: number,
    created_at: string;               //"2020-03-30T03:35:10.171Z",
    updated_at: string;               //null,
    deleted_at: string;               //null
}

export interface FinanceDocumentDetail {
    id: number;                       //1000008,
    document_name: string;            //"jXF7PcmLCVCCv98Xrecuct7Z.png",
    document_type: string;            //"proforma_invoice",
    document_location: string;        //"https://dtlp-file-storage-development.s3.ap-south-1.amazonaws.com/trade-finance/finances/1000003/jXF7PcmLCVCCv98Xrecuct7Z.png",
    uploaded_by: string;              //"Griffie Dillingston",
    accessibleUsers: [
        "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665"
    ]
}

export interface UserDisbursalEvent {
    created_at: string;                 //"2020-05-22T18:15:05.838Z",
    deleted_at: string;                 //null,
    disburse_on_event: string;          //"EXPORTER_PRODUCTION_COMPLETED",
    finance_id: number;                 //1000034,
    id: number;                         //1000059,
    interest_bearer: string;            //"IMPORTER",
    payment_percentage: string;         //"80.00",
    payment_deadline_in_days: number,   //10
    recourse: string;                   //"EXPORTER",
    updated_at: string;                 //null,

    // Logistics
    discount_rate: string;
}

export interface UserSettlementEvent {
    settle_on_event: string;            //"EXPORTER_PRODUCTION_COMPLETED",
    finance_id: number;                 //1000034,
    id: number;                         //1000059,
    settlement_percentage: string;      //"80.00",
    settlement_deadline_in_days: number,   //10
    created_at: string;                 //"2020-05-22T18:15:05.838Z",
    deleted_at: string;                 //null,
    updated_at: string;                 //null,
}

export interface TimelineDetail {
    completed_on: string;
    state: string;
}

export interface StateTransactionsDetail {
    user_id: string;                  //"258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
    user_full_name: string;           //"Griffie Dillingston",
    state: string;                    //"BCO_FINANCE_SAVED",
    notes: string;                    //"finance raised",
    created_at: string;               //"2020-03-30T03:34:53.924Z",
    updated_at: string;               //null,
    deleted_at: string;               //null
}

export interface TrackingDetail {
    completed_on: string;                   //null
    shipment_state: string;                 //"PURCHASE_ORDER_ACCEPTED"
}

export interface PaymentTerms {
    id: number;                               //1000006,
    finance_id: number;                       //1000003,
    bank_id: number;                          //1000012,
    sanctioned_amount: string;                //null,
    sanctioned_currency: string;              //null,
    interest_rate: string;                    //null,
    payment_deadline: string;                 //null,
    processing_fee: string;                   //null,
    exporter_acceptance: string;              //"pending",
    importer_acceptance: string;              //"pending",
    created_at: string;                       //"2020-03-30T03:35:10.171Z",
    updated_at: string;                       //null,
    deleted_at: string;                       //null,
    disbursalEvents: Array<DisbursalEvent>;
    settlementEvents: Array<SettlementEvent>;
}

export enum FinanceType {
    Trade,
    Logistics,
}

/**
 * When we load an existing finance by id on apply tab.
 */
export interface FullFinance {
    assigned_to: string;                              // looks like a GUID //"fe8559dd3683b1ebf2413724d8d9b6cfb1e51aa3" DPW admin this finance is assigned to
    collection_amount: number;                        // usually null, what is this for?
    //financeId: string;                              // old DEPRECATED "1000003",
    finance_id: string;                               // identifier for this finance
    finance_sub_classification: string;               //"pre",
    financeType?:FinanceType;
    finance_type: string;                         // was added as a plugin after getting back from backend.
    importer_id: number;                              //1000009,
    exporter_id: number;                              //1000010,
    created_by: string;                               //"258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
    state: FinanceStates;                             //"BCO_FINANCE_SUBMITTED",
    proof_type: string;                               //"purchase_order",
    endorse_proof_type: string;                       //"purchase_order",
    inco_terms: string;                               //"EXW",
    submitted_at: string;                             //"2020-03-30T03:35:10.050Z",
    shipment_origin_port: string;                     //"Dubai",
    shipment_destination_port: string;                //"Mumbai",
    shipment_origin_city: string;
    shipment_destination_city: string;
    shipment_origin_country: string;
    shipment_destination_country: string;
    importer_name: string;
    exporter_name: string;
    shipment_description: null;
    invoice_amount: string;                           //"1212.444568",
    invoice_currency: string;                         //"USD",
    requested_amount: string;                         //"800.000000",
    requested_currency: string;                       //"USD",
    collection_currency: null;
    created_at: string;                               //"2020-03-30T03:34:53.924Z",
    updated_at: string;                               //"2020-03-30T03:35:10.171Z",
    deleted_at: string;
    banksDetails: Array<BankDetail>;
    cargoDetails: Array<CargoDetail>;
    documentsDetails: Array<FinanceDocumentDetail>;
    paymentTermsDetails: Array<PaymentTerms>;
    shipmentTrackingDetails: Array<TrackingDetail>;
    stateTransactionsDetails: Array<StateTransactionsDetail>;
    timelineDetails: Array<TimelineDetail>;
    transactionsDetails: [];    //TODO: fill in the model for this.
    userDisbursalEventsDetails: Array<UserDisbursalEvent>;
    userSettlementEventsDetails: Array<UserSettlementEvent>;
    usersDetails: Array<UserDetails>;

    receivable_amount: string;
    invoice_id: string;
    invoice_date: string;
    invoice_due_date: string;
};
