import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SharedDataService = /** @class */ (function () {
    function SharedDataService(http) {
        this.http = http;
        this.sharedData = null;
    }
    SharedDataService.prototype.getSharedStaticData = function () {
        var _this = this;
        // if we've already fetched this data, just return it
        if (this.sharedData) {
            return of(this.sharedData);
        }
        // if we're currently fetching the data, return the fetch
        if (this.observableInProgress) {
            return this.observableInProgress;
        }
        // otherwise, start fetching the data
        var url = environment.rootUrl + environment.urls.shared_data;
        this.observableInProgress = this.http.get(url);
        this.observableInProgress.subscribe(function (data) {
            // save the data for later
            _this.sharedData = data;
            console.log("sharedData: ", _this.sharedData);
            // make sure it's clear we aren't fetching anymore.
            _this.observableInProgress = null;
        });
        return this.observableInProgress;
    };
    SharedDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedDataService_Factory() { return new SharedDataService(i0.ɵɵinject(i1.HttpClient)); }, token: SharedDataService, providedIn: "root" });
    return SharedDataService;
}());
export { SharedDataService };
