<div fxLayout="column" *ngIf="creditLimitInfo"
    style="height: 500px; border: 0.5px solid #cecece; background-color: white; margin-right: 20px;">

    <div fxLayout="row" style="border-bottom: 0.5px solid #cecece;">
        <div style="width:100px; height:90px;">
            <img src="assets/images/Content_Icon2b.png" style="width: 100%; height:60px;"
                class="matero-branding-logo-expanded" alt="logo" style="margin:10px;" />
        </div>
        <div>
            <h2 style="margin-bottom: 3px; color: #173A64;">Finance Credit</h2>
            <div style="color:gray; font-size:small;">Available & Consumed Limits</div>
        </div>

        <div style="margin-left: auto;">
            <div style="margin-right: 12px; margin-top: 8px;">
                <form [formGroup]="financeTypeGroup" class="matero-form-horizontal">
                    <mat-form-field appearance="outline" class="my-form-field">
                        <mat-label>Finance Type</mat-label>
                        <mat-select formControlName="financeType" name="financeType"
                            (selectionChange)="financeTypeChanged()">
                            <mat-option *ngFor="let financeType of financeTypes" [value]="financeType">
                                {{ financeType }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="grow" *ngIf="isTradeFinance">

        <ng-container *ngIf="bankOrDTFAdmin; else elseBlock">

            <div style="width: 50%" fxShow fxHide.lt-lg="true" fxLayoutAlign="center center">
                <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme" [results]="tradePieChartData"
                    [gradient]="gradient" [legend]="showLegend" [legendPosition]="legendPosition" [labels]="showLabels"
                    [doughnut]="true" (select)="onSelect($event)">
                </ngx-charts-pie-chart>
            </div>

            <div style="width: 50%; box-sizing: border-box;" fxLayout="column" fxLayoutAlign="center center">
                <div style="width:210px; display: inline-block;">
                    <h2 style="margin: 0">{{ tradeCreditLimitInfo.max_limit | currency: 'USD' : 'symbol' : '1.0-0' }}
                    </h2>
                    <p>Credit Limit
                        <!-- <button mat-button style="line-height: 20px; width:10px; color:black;"
                        matTooltip="The credit limit is set as per your credit history. The total finance availed should be less than the credit limit."
                        aria-label="Button that displays a tooltip when focused or hovered over"> -->
                        <mat-icon style="font-size: 20px;"
                            matTooltip="The credit limit is set as per your credit history. The total finance availed should be less than the credit limit."
                            class="material-icons">help</mat-icon>
                        <!-- </button> -->
                    </p>
                    <h2 style="margin: 0">
                        {{ tradeCreditLimitInfo.availed_amount | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                    <p>Finance Availed</p>
                    <button mat-button (click)="applyClicked()"
                        style="margin-bottom:10px; width:210px; background-color: orange; color:white;">
                        APPLY NEW FINANCE</button>
                    <button mat-button (click)="payNowClicked()"
                        style="width:210px; background-color: #173A64; color:white;">PAY
                        NOW</button>
                </div>
            </div>
        </ng-container>

        <ng-template #elseBlock>
            <div style="width: 50%" fxShow fxHide.lt-lg="true" fxLayoutAlign="center center">
                <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme"
                    [results]="tradePieChartDataForBanksandAdmins" [gradient]="gradient" [legend]="showLegend"
                    [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="true"
                    (select)="onSelect($event)">
                </ngx-charts-pie-chart>
            </div>

            <div style="width: 50%; box-sizing: border-box;" fxLayout="column" fxLayoutAlign="center center">
                <div style="width:210px; display: inline-block;">
                    <p>Total finance requested</p>
                    <h2 style="margin: 0">
                        {{ tradeCreditLimitInfo.total_finance_requested | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                    <p>Total finance approved</p>
                    <h2 style="margin: 0">
                        {{ tradeCreditLimitInfo.total_finance_approved | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                    <p>Total finance given</p>
                    <h2 style="margin: 0">
                        {{ tradeCreditLimitInfo.total_finance_given | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                </div>
            </div>
        </ng-template>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="grow" *ngIf="!isTradeFinance">

        <ng-container *ngIf="bankOrDTFAdmin; else elseBlock">

            <div style="width: 50%" fxShow fxHide.lt-lg="true" fxLayoutAlign="center center">
                <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme" [results]="logisticsPieChartData"
                    [gradient]="gradient" [legend]="showLegend" [legendPosition]="legendPosition" [labels]="showLabels"
                    [doughnut]="true" (select)="onSelect($event)">
                </ngx-charts-pie-chart>
            </div>

            <div style="width: 50%; box-sizing: border-box;" fxLayout="column" fxLayoutAlign="center center">
                <div style="width:210px; display: inline-block;">
                    <h2 style="margin: 0">
                        {{ logisticsCreditLimitInfo.max_limit | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                    <p>Credit Limit
                        <!-- <button mat-button style="line-height: 20px; width:10px; color:black;"
                        matTooltip="The credit limit is set as per your credit history. The total finance availed should be less than the credit limit."
                        aria-label="Button that displays a tooltip when focused or hovered over"> -->
                        <mat-icon style="font-size: 20px;"
                            matTooltip="The credit limit is set as per your credit history. The total finance availed should be less than the credit limit."
                            class="material-icons">help</mat-icon>
                        <!-- </button> -->
                    </p>
                    <h2 style="margin: 0">
                        {{ logisticsCreditLimitInfo.availed_amount | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                    <p>Finance Availed</p>
                    <button mat-button (click)="applyClicked()"
                        style="margin-bottom:10px; width:210px; background-color: orange; color:white;">
                        APPLY NEW FINANCE</button>
                    <button mat-button (click)="payNowClicked()"
                        style="width:210px; background-color: #173A64; color:white;">PAY
                        NOW</button>
                </div>
            </div>
        </ng-container>

        <ng-template #elseBlock>
            <div style="width: 50%" fxShow fxHide.lt-lg="true" fxLayoutAlign="center center">
                <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme"
                    [results]="logisticsPieChartDataForBanksandAdmins" [gradient]="gradient" [legend]="showLegend"
                    [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="true"
                    (select)="onSelect($event)">
                </ngx-charts-pie-chart>
            </div>

            <div style="width: 50%; box-sizing: border-box;" fxLayout="column" fxLayoutAlign="center center">
                <div style="width:210px; display: inline-block;">
                    <p>Total finance requested</p>
                    <h2 style="margin: 0">
                        {{ logisticsCreditLimitInfo.total_finance_requested | currency: 'USD' : 'symbol' : '1.0-0' }}
                    </h2>
                    <p>Total finance approved</p>
                    <h2 style="margin: 0">
                        {{ logisticsCreditLimitInfo.total_finance_approved | currency: 'USD' : 'symbol' : '1.0-0' }}
                    </h2>
                    <p>Total finance given</p>
                    <h2 style="margin: 0">
                        {{ logisticsCreditLimitInfo.total_finance_given | currency: 'USD' : 'symbol' : '1.0-0' }}</h2>
                </div>
            </div>
        </ng-template>
    </div>
</div>