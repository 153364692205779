/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i2 from "@angular/material/tabs";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "./sidebar-notice.component";
var styles_SidebarNoticeComponent = [];
var RenderType_SidebarNoticeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarNoticeComponent, data: {} });
export { RenderType_SidebarNoticeComponent as RenderType_SidebarNoticeComponent };
export function View_SidebarNoticeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i1.View_MatTabGroup_0, i1.RenderType_MatTabGroup)), i0.ɵdid(1, 3325952, null, 1, i2.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i2.MAT_TABS_CONFIG], [2, i3.ANIMATION_MODULE_TYPE]], null, null), i0.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i0.ɵeld(3, 16777216, null, null, 4, "mat-tab", [["label", "Today"]], null, null, null, i1.View_MatTab_0, i1.RenderType_MatTab)), i0.ɵdid(4, 770048, [[1, 4]], 2, i2.MatTab, [i0.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i0.ɵqud(603979776, 2, { templateLabel: 0 }), i0.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i0.ɵted(-1, 0, [" Content 1 "])), (_l()(), i0.ɵeld(8, 16777216, null, null, 4, "mat-tab", [["label", "Notifications"]], null, null, null, i1.View_MatTab_0, i1.RenderType_MatTab)), i0.ɵdid(9, 770048, [[1, 4]], 2, i2.MatTab, [i0.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i0.ɵqud(603979776, 4, { templateLabel: 0 }), i0.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i0.ɵted(-1, 0, [" Content 2 "]))], function (_ck, _v) { var currVal_2 = "Today"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "Notifications"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i0.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SidebarNoticeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-notice", [], null, null, null, View_SidebarNoticeComponent_0, RenderType_SidebarNoticeComponent)), i0.ɵdid(1, 49152, null, 0, i4.SidebarNoticeComponent, [], null, null)], null, null); }
var SidebarNoticeComponentNgFactory = i0.ɵccf("app-sidebar-notice", i4.SidebarNoticeComponent, View_SidebarNoticeComponent_Host_0, {}, {}, []);
export { SidebarNoticeComponentNgFactory as SidebarNoticeComponentNgFactory };
