<div style="height: calc( 100vh - 50px ) !important;" class="matero-container-wrap"
  [dir]="options.dir" [ngClass]="{
  'matero-sidenav-collapsed': true && options.navPos!=='top',
  'matero-navbar-side': options.navPos==='side',
  'matero-navbar-top': options.navPos==='top',
  'matero-header-above': options.headerPos==='above',
  'matero-header-fixed': options.headerPos==='fixed',
  'matero-header-white': options.theme==='light'}">
  <ng-progress></ng-progress>

  <!-- Header Above -->
  <app-header #header class="matero-header" *ngIf="options.showHeader && options.headerPos==='above'"
    (toggleSidenav)="sidenav.toggle()" (toggleSidenavNotice)="sidenavNotice.toggle()" [showBranding]="true">
  </app-header>

  <mat-sidenav-container style="height: 100vh !important;" class="matero-container"
    autosize autoFocus>
    <mat-sidenav #sidenav class="matero-sidenav" style="height: 100vh !important;" style="background-color: #173A64;" [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos==='side' && options.sidenavOpened && !isOver"
      (openedChange)="sidenavOpenedChange($event)" (closedStart)="sidenavCloseStart()">
      <app-sidebar (toggleCollapsed)="toggleCollapsed()" [toggleChecked]="options.sidenavCollapsed"
        [showToggle]="!isOver" [showUser]="options.showUserPanel" [showHeader]="options.headerPos!=='above'">
      </app-sidebar>
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over" opened="false">
      <app-sidebar-notice></app-sidebar-notice>
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap" style="height: 100vh !important; background-color: rgb(241, 247, 255);">
      <app-header #header class="matero-header" *ngIf="options.showHeader && options.headerPos!=='above'"
        (toggleSidenav)="sidenav.toggle()" (toggleSidenavNotice)="sidenavNotice.toggle()"
        [showToggle]="!options.sidenavCollapsed && options.navPos!=='top'" [showBranding]="options.navPos==='top'">
      </app-header>

      <app-topmenu *ngIf="options.navPos==='top'"></app-topmenu>

      <div class="matero-content" style="min-height: calc( 100vh - 115px ) !important; height: auto;">
        <router-outlet style="min-height: calc( 100vh - 50px ) !important; height: auto;"></router-outlet>
      </div>

      <ng-container *ngIf="options.sidenavOpened">
        <div fxLayout="row" fxLayoutAlign="end center"
          style="width: 100%; height: 51px; background-color: #173A64; color:white;">
          <div style="margin: 10px; font-size: 10px;">
            <div>2020 Trade Finance. All rights reserved</div>
            <div>Privacy Policy | Terms & Conditions</div>
          </div>
        </div>
      </ng-container>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>