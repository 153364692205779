import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
  Route,
  CanLoad,
} from '@angular/router';
import { AuthService } from './auth.service';
import { User } from './user.model';
import { LogService } from '../../../dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';

/**
 * Controls whether users can access routes of not.
 */
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LogService,
    private ui: UiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authService.isAuth()) {
      // restrict admin only pages.
      if (route.data && route.data.adminOnly) {
        let user: User = this.authService.currentUserValue;
        //console.log("canActivate; user: " + JSON.stringify(user));
        // if the user object is missing of misformed
        if (!user || user.userCode === null) {
          this.logger.logError('AuthGuard.canActivate; !user');
          this.ui.snackbar('You are not authorized to access that page.');
          return false;
        } else if (!user.isAdmin) {
          //console.log("canActivate; user is not authorized to access an admin page.");
          this.ui.snackbar('You are not authorized to access that page.');
          return false;
        }
      }

      return true;
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
    }
  }

  /**
   * called when routing is trying to determine if a routes assets should be
   * lazy loaded
   * @param route
   */
  canLoad(route: Route) {
    return true;
    /*
    if (this.authService.isAuth()) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
    */
  }
}
