import { Component, OnInit, Input } from '@angular/core';
import { ConstantsService } from 'app/dp-world/services/constants.service';
import { FormGroup, FormArray, FormControl, FormBuilder, FormGroupDirective } from '@angular/forms';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ApplyService } from '../apply.service';
import { LogService } from 'app/dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SharedDataService } from 'app/dp-world/services/shared-data.service';
import { Organization } from 'app/routes/dashboard/dashboard.models';
import { DashboardService } from 'app/routes/dashboard/dashboard.service';
import { DocumentService } from '@core/services/document.service';
import { AuthService } from 'app/routes/sessions/login/auth.service';
import { User } from 'app/routes/sessions/login/user.model';
import { PipeUtilsService } from 'app/dp-world/services/pipe-utils.service';
import { UtilsService } from 'app/dp-world/services/utils.service';
import { FinanceType } from '../apply-models';
import { AddOrganizationComponent } from 'app/routes/logistics-finance/step-a/add-organization/add-organization.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-finance-request-form',
  templateUrl: './finance-request-form.component.html',
  styleUrls: ['./finance-request-form.component.scss'],
})

/**
 * Used by a couple steps in the Apply functionality.
 * @author charles.skinner@dpworld.com
 */
export class FinanceRequestFormComponent implements OnInit {
  @Input() form: FormGroup = null;
  @Input() canEdit: boolean;
  @Input() hideTradeFinanceSection: boolean = false;
  @Input() finance: any = null;
  @Input() hideApplyAs: boolean = false;
  @Input() showFileUpload = true;
  @Input() shared: any = null;
  @Input() endorseColumn: boolean = false;
  @Input() financeType: FinanceType = FinanceType.Trade;

  public percentageLimitExceeded: boolean = false;
  public file: NgxFileDropEntry = null;
  public cargoFile: NgxFileDropEntry = null;
  public endorseFile: NgxFileDropEntry = null;
  options = [];
  filteredOptions: Observable<any[]>;

  // used by toCountry and fromCountry
  locations: any = null;

  incoterms = [];
  banks: any = null;
  proofTypes = [];
  currencies = [];

  // old from static data, probably deprecated
  events = [];
  importerEvents = [];
  exporterEvents = [];

  parties = ['IMPORTER', 'EXPORTER'];
  recourseParties = ['IMPORTER', 'EXPORTER', 'NONE'];

  discountRates = ['0.075%', '0.05%', '0.1%'];

  user: User;

  showCargoDetailsFileUpload = false;
  totalAmountEditable = false;

  applyAsImporter = false;

  // TODO: the form isn't allowing us to assign a calculated value, it shows up, but then can't be retrieved from form
  // so this is a hack to get around that.
  totalAmount = 0;
  requestedAmount = 0;

  // Dates to validate calender
  InvoiceDate: Date;
  InvoiceDueDate: Date;
  currDate: Date = new Date();
  oneYearBelowDate: Date = new Date();
  oneYearAboveDate: Date = new Date();

  // used by the autocompletes, what they actually databind too, result after main list has been filtered.
  toCountryFilteredOptions = [];
  fromCountryFilteredOptions = [];
  toPortFilteredOptions = [];
  fromPortFilteredOptions = [];
  toCitiesFilteredOptions = [];
  fromCitiesFilteredOptions = [];

  fromLabel = "From City"

  constructor(
    private _formBuilder: FormBuilder,
    public constants: ConstantsService,
    public applyService: ApplyService,
    public logger: LogService,
    private ui: UiService,
    public sharedData: SharedDataService,
    public dashboardService: DashboardService,
    private documentService: DocumentService,
    private authService: AuthService,
    public pipeUtils: PipeUtilsService,
    public utils: UtilsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // disable these text boxes because they are calulated fields

    this.form.get('amount').disable();
    this.form.get('totalAmount').disable();
    this.form.get('requestedAmount').disable();

    if (this.financeType == FinanceType.Logistics) {
      this.form.get('discountRate').disable();
      this.form.get('receivableAmount').disable();
    }

    this.user = this.authService.currentUserValue;

    this.filteredOptions = this.form.get('importer').valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice()))
    );

    this.locations = this.shared.countryPortsAndCities;

    this.oneYearBelowDate.setFullYear(this.oneYearBelowDate.getFullYear() - 1);
    this.oneYearAboveDate.setFullYear(this.oneYearAboveDate.getFullYear() + 1);

    // fill in our autocomplete values
    this.toCountryFilteredOptions = this.locations;
    this.fromCountryFilteredOptions = this.locations;

    this.incoterms = this.shared.supportedIncoTerms;
    this.proofTypes = this.shared.supportedProofTypes;
    this.currencies = this.shared.supportedCurrencies;
    this.banks = this.shared.supportedBanks;
    this.importerEvents = this.shared.supportedImporterShipmentEvents;
    this.exporterEvents = this.shared.supportedExporterShipmentEvents;
    if (this.finance.documentsDetails) {
      let documentDetails = this.finance.documentsDetails;
      for (let i = 0; i < documentDetails.length; i++) {
        if (documentDetails[i].document_type === "cargo_details") {
          this.totalAmountEditable = true;
        }
      }
    }
  }

  toPorts = [];
  toCities = [];
  selectedToCity = null;
  selectedToCountry = null;
  selectedToCountryChanged() {
    console.log('selectedToCountryChanged;');
    let to: any = this.form.get('toCountry');
    if (to && to.value) {
      this.toCities = to.value.cities;
      this.toPorts = to.value.ports;

      this.toCitiesFilteredOptions = this.toCities;
      this.toPortFilteredOptions = this.toPorts;
    }
  }

  fromPorts = [];
  fromCities = [];
  selectedFromCity = null;
  selectedFromCountry = null;
  selectedFromCountryChanged() {
    console.log('selectedFromCountryChanged;');
    let from: any = this.form.get('fromCountry');
    if (from && from.value) {
      this.fromCities = from.value.cities;
      this.fromPorts = from.value.ports;

      this.fromCitiesFilteredOptions = this.fromCities;
      this.fromPortFilteredOptions = this.fromPorts;
    }
  }

  /**
   * called when users enter a new character in a search box.
   * @param $event 
   * @param options 
   * @param type 
   */
  applyFilter($event, options: Array<any>, type: string) {

    // filter all options by the search text.
    let filter = $event.target.value;
    let filterValue = filter.toLowerCase();
    let filtered = options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);

    switch (type) {
      case 'toCity':
        this.toCitiesFilteredOptions = filtered;
      case 'fromCity':
        this.fromCitiesFilteredOptions = filtered;
      case 'toPort':
        this.toPortFilteredOptions = filtered;
      case 'fromPort':
        this.fromPortFilteredOptions = filtered;
    }
  }

  /**
   * Called when users enter a new character in the Country search box.
   * @param $event 
   * @param options 
   * @param type 
   */
  applyCountryFilter($event, options: Array<any>, type: string) {

    // filter all options by the search text
    let filter = $event.target.value;
    let filterValue = filter.toLowerCase();
    let filtered = options.filter(option => option.country_name.toLowerCase().indexOf(filterValue) === 0);

    switch (type) {
      case 'toCountry':
        this.toCountryFilteredOptions = filtered;
      case 'fromCountry':
        this.fromCountryFilteredOptions = filtered;
    }
  }

  get cargoDetailsFormArray(): FormArray {
    return this.form.get('cargoDetails') as FormArray;
  }

  get eventDetailsFormArray(): FormArray {
    return this.form.get('eventDetails') as FormArray;
  }

  get settlementEventDetailsFormArray(): FormArray {
    return this.form.get('settlementEventDetails') as FormArray;
  }

  get logisticsDetailsFormArray(): FormArray {
    return this.form.get('logisticsDetails') as FormArray;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.full_name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayCountryFn(obj: any): string {
    if (obj) {
      return obj.country_name;
    } else {
      return '';
    }
  }

  // TODO: why is full_name missing now?
  displayFn(obj: any): string {
    if (obj && obj.full_name) {
      return obj.full_name;
    }
    return null;
  }

  currentAutoCompleteValue: string = '';
  updateOptionsTimeout = null;
  /**
   * called when user enters a key in importer or exporter search boxes.
   * @param $event 
   */
  onKey($event) {
    console.log('onKey;' + $event.target.value);
    this.currentAutoCompleteValue = $event.target.value;

    clearTimeout(this.updateOptionsTimeout);
    this.updateOptionsTimeout = setTimeout(() => {
      this.getCompanyOptions(this.currentAutoCompleteValue);
    }, 300);
  }

  /**
   * actually calls the backend to retrieve all the companies that match the search text.
   * @param searchText 
   */
  getCompanyOptions(searchText: string) {

    // if the user just deleted their search text, no point in searching
    if (searchText == '') {
      return;
    }

    const queryParams = [{ org_type: 'ORG' }, { _limit: 20 }, { org_name: searchText }];

    this.dashboardService.getOrganisations(queryParams).subscribe(
      (data: Array<Organization>) => {
        if (!data) {
          this.logger.logError('The backend is not currently returning autocomplete options.');
          this.ui.snackbar('The backend is not currently returning autocomplete options.');
          return;
        }

        this.options = data;

        // make the filtering of autocomplete update.
        this.filteredOptions = of(this._filter(this.currentAutoCompleteValue));
      },
      () => {
        this.logger.logError('The backend is not currently returning autocomplete options.');
        this.ui.snackbar('The backend is not currently returning autocomplete options.');
      }
    );
  }

  /**
   * Called by dragging and dropping a file in the form.
   * @param files 
   */
  public dropped(fileEntry: FileSystemFileEntry) {
    console.log('File dropped or selected: ', fileEntry);

    let fileControl = this.form.get('file');
    if (fileControl) {
      fileEntry.file((file: File) => {
        fileControl.setValue(file);
        console.log('fileControl', fileControl);
      });
    }
  }

  /**
   * On Trading Partner Review step, this is called when you drop a document in the drag and drop
   * @param fileEntry 
   */
  public droppedEndorseFile(fileEntry: FileSystemFileEntry) {

    let fileControl = this.form.get('endorseFile');
    if (fileControl) {
      fileEntry.file((file: File) => {
        fileControl.setValue(file);
        console.log('fileControl', fileControl);
      });
    }
  }

  public droppedCargoFile(fileEntry: FileSystemFileEntry) {

    console.log('droppedCargoFile; File dropped or selected: ', fileEntry);

    let fileControl = this.form.get('cargoFile');
    if (fileControl) {
      fileEntry.file((file: File) => {
        fileControl.setValue(file);
        console.log('fileControl', fileControl);
      });
    }
  }

  openFileSelector() {
    if (!this.canEdit) {
      return;
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  getProofTypeDisabled(proof) {
    return (proof == this.form.get('proofType').value);
  }

  /**
   * Called when the user selects the importer/exporter radio button. automatically fills the importer/exporter fields below
   */
  importerExporterChanged() {

    // determine if the user is importer or exporter
    let weAre = this.form.get('BCOType').value;
    let orgName = this.user.organisation_name;

    const queryParams = [{ org_type: 'ORG' }, { _limit: 20 }, { org_name: orgName }];

    this.dashboardService.getOrganisations(queryParams).subscribe((data: Array<Organization>) => {
      let org = data[0];

      if (weAre == 'importer') {
        this.applyAsImporter = true;
        // auto fill 
        this.form.get('importer').setValue(org);

        // wipe potential old selection
        this.form.get('exporter').setValue(null);

      } else if (weAre == 'exporter') {
        this.applyAsImporter = false;
        // auto fill
        this.form.get('exporter').setValue(org);

        // wipe potential old selection
        this.form.get('importer').setValue(null);
      }
    });
  }

  /**
   * called when someone selects to upload a file rather than fill in cargoDetails.
   */
  showCargoFileUploadOption() {
    this.showCargoDetailsFileUpload = !this.showCargoDetailsFileUpload;
    this.totalAmountEditable = !this.totalAmountEditable;

    this.showCargoDetailsFileUpload ? this.form.get('totalAmount').enable() : this.form.get('totalAmount').disable();
  }

  addEvent() {
    if (this.eventDetailsFormArray.length <= 2) {
      let eventDetails = new FormGroup({
        paymentEvent: new FormControl(''),
        daysAfterDisbursalEvent: new FormControl(''),
        paymentPercentage: new FormControl(''),
        interestBearer: new FormControl(''),
        recourse: new FormControl(''),
      });
      this.eventDetailsFormArray.push(eventDetails);
    } else {
      this.ui.snackbar("You can only have 3 details.")
    }
  }

  addSettlementEvent() {
    if (this.settlementEventDetailsFormArray.length <= 2) {
      let eventDetails = new FormGroup({
        settlementEvent: new FormControl(''),
        daysAfterSettlementEvent: new FormControl(''),
        settlementPercentage: new FormControl(''),
      });
      this.settlementEventDetailsFormArray.push(eventDetails);
    } else {
      this.ui.snackbar("You can only have 3 settlement details.")
    }
  }

  removeEvent(index: number) {
    this.eventDetailsFormArray.removeAt(index);
  }

  removeSettlementEvent(index: number) {
    this.settlementEventDetailsFormArray.removeAt(index);
  }

  addCargo() {
    if (this.cargoDetailsFormArray.length <= 4) {
      const group = new FormGroup({
        cargoDescription: new FormControl(''),
        quantity: new FormControl(''),
        unitPrice: new FormControl(''),
      });
      this.cargoDetailsFormArray.push(group);
    } else {
      this.ui.snackbar("You can only have 5 cargo details.")
    }
  }

  removeCargo(index: number) {
    this.cargoDetailsFormArray.removeAt(index);
  }

  percentageErrorMatcher = {
    isErrorState: (control: FormControl, form: FormGroupDirective): boolean => {
      let totalPercentage = 0;

      const eventDetailsFormGroup = this.eventDetailsFormArray.controls;
      eventDetailsFormGroup.forEach((eventDetails: FormGroup) => {
        const paymentPercentage = eventDetails.get(`paymentPercentage`).value;
        totalPercentage += parseFloat(paymentPercentage) || 0;
      });
      if (totalPercentage > 100) {
        return true;
      }
      return false;
    },
  };

  settlementPercentageErrorMatcher = {
    isErrorState: (control: FormControl, form: FormGroupDirective): boolean => {
      let totalPercentage = 0;

      const settlementEventDetailsFormGroup = this.settlementEventDetailsFormArray.controls;
      settlementEventDetailsFormGroup.forEach((settlementEventDetails: FormGroup) => {
        const settlementPercentage = settlementEventDetails.get(`settlementPercentage`).value;
        totalPercentage += parseFloat(settlementPercentage) || 0;
      });
      if (totalPercentage > 100) {
        return true;
      }
      return false;
    },
  };

  logisticsPercentageErrorMatcher = {
    isErrorState: (control: FormControl, form: FormGroupDirective): boolean => {
      let totalPercentage = 0;

      const logisticsDetailsFormGroup = this.logisticsDetailsFormArray.controls;
      logisticsDetailsFormGroup.forEach((logisticsDetails: FormGroup) => {
        const paymentPercentage = logisticsDetails.get(`paymentPercentage`).value;
        totalPercentage += parseFloat(paymentPercentage) || 0;
      });
      if (totalPercentage > 100) {
        return true;
      }
      return false;
    },
  };

  // sets to int value since we display as string
  updatePrice(unitPriceHolder, item) {
    item.get('unitPriceHolder').setValue(unitPriceHolder);
  }

  getAmount(unitPrice, quantity, cargoDetails) {
    //let amount = this.utils.currencyStringToNumber(unitPrice) * quantity;

    let amount = unitPrice * quantity;
    return amount.toFixed(2);
  }

  getTotalAmount() {
    let totalAmount = 0;


    // this scenario must be when they uploaded the file
    if (this.totalAmountEditable) {

      let total = this.form.get(`totalAmount`).value;

      if (total) {
        console.log("total: "+total);
        totalAmount = parseFloat(total);
      }

      //console.log("getTotalAmount; editable ");
    }
    // the normal scenario when no cargo details file is uploaded
    else {

      //console.log("getTotalAmount; not editable ");

      if (this.cargoDetailsFormArray) {
        const cargoDetailsFormGroup = this.cargoDetailsFormArray.controls;
        cargoDetailsFormGroup.forEach((cargoDetails: FormGroup) => {
          const quantity = cargoDetails.get(`quantity`).value;
          const unitPrice = cargoDetails.get(`unitPrice`).value;

          //const unitPrice = this.utils.currencyStringToNumber(cargoDetails.get(`unitPrice`).value);
          //console.log("unitPrice: "+unitPrice);
          //console.log("typeof unitPrice: "+ typeof unitPrice);

          totalAmount += quantity * unitPrice;
        });
      }
    }

    //console.log("totalAmount: "+totalAmount);

    this.form.get('totalAmountHolder').setValue(totalAmount);

    // removed .toFixed() since i'm using a pipe to format the data
    return totalAmount;
  }

  getRequestedAmount() {

    let requestedAmount = 0;

    if (this.eventDetailsFormArray) {
      let totalPercentage = 0;
      const eventDetailsFormGroup = this.eventDetailsFormArray.controls;
      eventDetailsFormGroup.forEach((eventDetails: FormGroup) => {
        const paymentPercentage = eventDetails.get(`paymentPercentage`).value;
        totalPercentage += parseFloat(paymentPercentage) || 0;
      });

      const totalAmount = this.getTotalAmount();
      requestedAmount = (totalAmount * totalPercentage) / 100;
      this.form.get('requestedAmountHolder').setValue(requestedAmount);
    }

    // removed .toFixed() since i'm using a pipe to format the data
    return requestedAmount;
  }

  selectionChange() {
    console.log('selectionChange;');
  }

  downloadDoc(doc) {
    this.documentService.downloadDocument(this.finance.finance_id, doc.id, this.financeType).subscribe(
      response => {
        this.downloadFile(response, doc.document_name);
      },
      error => {
        this.ui.snackbar(`Error occured while downloading ${doc.document_name} file`);
        console.log(error);
      }
    );
  }

  downloadMiscDoc(docName) {
    this.documentService.downloadMiscDocument(docName).subscribe(
      response => {
        this.downloadFile(response, docName);
      },
      error => {
        this.ui.snackbar(`Error occured while downloading ${docName} file`);
        console.log(error);
      }
    );
  }

  downloadFile(result, filename) {
    const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    const templateURL = URL.createObjectURL(new Blob([result]));
    csvDownloadAnchor.href = templateURL;
    csvDownloadAnchor.download = filename;
    csvDownloadAnchor.click();
    URL.revokeObjectURL(templateURL);
  }

  /**
   * When a user selects an autocomplete, this resets the dropdowns displayed options.
   * This gets around a bug in autocomplete where it shows options from last search.
   * @param toSelect 
   */
  inputFocused(toSelect) {

    let fromCountry = this.form.get('fromCountry').value;
    let toCountry = this.form.get('toCountry').value;

    switch (toSelect) {
      case 'fromPort':

        // if a user hasn't selected a fromCountry yet, we don't have options for this loaded yet 
        if (fromCountry === '') {
          this.ui.snackbar("You must select a 'From Country' before you can select this. ");
        }

        this.fromPortFilteredOptions = this.fromPorts;
        break;
      case 'toPort':

        // if a user hasn't selected a toCountry yet, we don't have options for this loaded yet 
        if (toCountry === '') {
          this.ui.snackbar("You must select a 'To Country' before you can select this. ");
        }

        this.toPortFilteredOptions = this.toPorts;
        break;
      case 'toCities':

        // if a user hasn't selected a toCountry yet, we don't have options for this loaded yet 
        if (toCountry === '') {
          this.ui.snackbar("You must select a 'To Country' before you can select this. ");
        }

        this.toCitiesFilteredOptions = this.toCities;
        break;
      case 'fromCities':

        // if a user hasn't selected a fromCountry yet, we don't have options for this loaded yet        
        if (fromCountry === '') {
          this.ui.snackbar("You must select a 'From Country' before you can select this. ");
        }

        this.fromCitiesFilteredOptions = this.fromCities;
        break;
      case 'fromCountry':
        this.fromCountryFilteredOptions = this.locations;
        break;
      case 'toCountry':
        this.toCountryFilteredOptions = this.locations;
        break;
    }
  }

  /**
   * Called when user selection leaves an autocomplete, if what they typed is not
   * a valid value we wipe it out. (mat-autocomplete should have this built in)
   * @param controlName 
   * @param options 
   */
  blur(controlName, options) {
    let value = this.form.get(controlName).value;

    // if a country object is selected
    if (typeof value !== 'string') {
      return;
    }

    let filterValue = value.toLowerCase();
    let found = options.find((opt: any) => {
      if (typeof opt === "string") {
        return (opt.toLowerCase() === filterValue.toLowerCase());
      } else if (opt.country_name) {
        return (opt.country_name.toLowerCase() === filterValue.toLowerCase());
      }
    });

    if (!found) {
      this.form.get(controlName).setValue('');
    }
  }

  // Logistic Finance Functions /////////////////////////////////////////////////////////////

  invoiceDateChanged($event) {
    console.log("invoiceDateChanged;");
    //this.getDiscountRate();
    this.InvoiceDate = this.form.get('invoiceDate').value as Date;
  }

  invoiceDueDateChanged($event) {
    console.log("invoiceDueDateChanged;");
    this.InvoiceDueDate = this.form.get('invoiceDueDate').value as Date;
    this.InvoiceDueDate.setDate(this.InvoiceDueDate.getDate() - 1);
    this.getDiscountRates();
  }

  paymentDateChanged($event) {
    console.log("paymentDateChanged;");
    this.getDiscountRates();
  }

  isLogistics() {
    return this.financeType == FinanceType.Logistics;
  }

  logisticsRequestedAmount() {
    return this.utils.logisticsRequestedAmount(this.form);
  }

  logisticsReceivableAmount() {
    return this.utils.logisticsReceivableAmount(this.form);
  }

  logisticsTotalAmount() {
    let total = 0;
    total = this.form.get('invoiceAmount').value;
    return total;
  }

  addLogisticsDetails() {
    let logisticsDetails: FormArray = this.form.get("logisticsDetails") as FormArray;
    if (logisticsDetails.length <= 2) {
      const group = new FormGroup({
        discountRate: new FormControl(''),
        recourse: new FormControl(''),
        paymentDate: new FormControl(''),
        paymentPercentage: new FormControl(''),
      });

      group.get('discountRate').disable();

      logisticsDetails.push(group);
    } else {
      this.ui.snackbar("You can only have 3 logistics details.");
    }
  }

  removeLogisticsDetails(index: number) {
    let logisticsDetails: FormArray = this.form.get("logisticsDetails") as FormArray;
    logisticsDetails.removeAt(index);
  }

  getDiscountRates() {
    console.log("getDiscountRates;");
    this.applyService.getDiscountRates(this.form);
  }
}
