import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { LogService } from 'app/dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';
var AuthService = /** @class */ (function () {
    function AuthService(router, http, logger, ui) {
        this.router = router;
        this.http = http;
        this.logger = logger;
        this.ui = ui;
        this.currentUserSubject = new BehaviorSubject(null);
        this.currentUser = this.currentUserSubject.asObservable();
        var user = JSON.parse(localStorage.getItem(environment.storage.currentUser));
        if (user == null) {
            return;
        }
        var hours = 3600000;
        var original = user.timestamp;
        var now = new Date().getTime();
        var diff = now - original;
        // token still valid
        if (diff < hours * 8) {
            this.handleNewUser(user);
            // token expired
        }
        else {
            console.log("login is too old, removing! " + diff);
            localStorage.removeItem(environment.storage.currentUser);
        }
        /*
        // what is this for?
        if (!user && environment.hasOwnProperty('mockUser')) {
          // tslint:disable-next-line: no-string-literal
          user = environment['mockUser'] as User;
          localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
        }
        this.handleNewUser(user);
        */
    }
    AuthService.prototype.handleNewUser = function (user) {
        this.user = user;
        if (user) {
            //moment.locale(user.locale);
        }
        this.currentUserSubject.next(user);
    };
    Object.defineProperty(AuthService.prototype, "currentUserValue", {
        get: function () {
            return this.user;
            //return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.registerUser = function (authData) {
        var _a;
        return this.http
            .post(environment.rootUrl + environment.urls.register, {}, {
            headers: new HttpHeaders((_a = {},
                _a[environment.apiKey] = environment.apiValue,
                _a.Authorization = 'Basic ' + btoa(authData.username + ':' + authData.password),
                _a)),
            observe: 'response'
        })
            .pipe(map(function (response) {
            var user = response.body;
            return user;
        }));
    };
    AuthService.prototype.login = function (authData) {
        var _a;
        var _this = this;
        return this.http
            .post(environment.rootUrl + environment.urls.login, {}, {
            headers: new HttpHeaders((_a = {},
                _a[environment.apiKey] = environment.apiValue,
                _a.Authorization = 'Basic ' + btoa(authData.username + ':' + authData.password),
                _a)),
            observe: 'response'
        })
            .pipe(map(function (response) {
            //console.log("login; response: ", response);
            //console.log("login; response.headers: ", response.headers);
            var user = response.body;
            console.log("login; user: ", user);
            user.token = response.headers.get('x-dpw-user-token');
            user.timestamp = moment().toDate().getTime();
            localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
            _this.handleNewUser(user);
            return user;
        }));
        // .pipe(
        //   map(res => {
        //     //console.log(res);
        //   })
        // );
        // this.user = {
        //   email: authData.email,
        //   userId: Math.round(Math.random() * 10000).toString()
        // };
        // this.authSuccessfully();
    };
    AuthService.prototype.verifyCaptcha = function (captchaToken) {
        return this.http.post(environment.rootUrl + environment.urls.verify_captcha, { captcha_token: captchaToken });
    };
    AuthService.prototype.authSuccessfully = function () {
        // this.authChange.next(true);
        this.router.navigate(['/welcome']);
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        console.log("logout;");
        this.http.post(environment.rootUrl + environment.urls.logout, {}).subscribe(function (response) {
            console.log("logout; response: ", response);
            _this.ui.snackbar("You have been logged out!");
            localStorage.removeItem(environment.storage.currentUser);
            _this.currentUserSubject.next(null);
            setTimeout(function () {
                _this.router.navigate(['/auth/login']);
            }, 200);
        }, function (error) {
            _this.logger.logError("The backend can't log you out right now.");
            //this.ui.snackbar("The backend can't log you out right now.");
            setTimeout(function () {
                _this.router.navigate(['/auth/login']);
            }, 200);
        });
    };
    AuthService.prototype.isAuth = function () {
        console.log("isAuth; this.currentUserValue: ", this.currentUserValue);
        return this.currentUserValue != null;
    };
    AuthService.prototype.isAdmin = function () {
        return this.currentUserValue && this.currentUserValue.isAdmin;
    };
    AuthService.prototype.onSubmit = function (form) {
        //console.log(form);
    };
    AuthService.prototype.getUserLocale = function () {
        var user = this.currentUserValue;
        var allowedLocales = ['en-US', 'en-GB'];
        if (user && user.locale && allowedLocales.indexOf(user.locale) !== -1) {
            return user.locale;
        }
        console.warn('check user locale for its validation');
        return 'en-US';
    };
    return AuthService;
}());
export { AuthService };
