import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import { DefaultInterceptor } from '@core';
import { StartupService } from '@core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './routes/sessions/login/auth.service';
import { AuthGuard } from './routes/sessions/login/auth.guard';
import { BasicAuthInterceptor } from './routes/sessions/login/basic-auth.interceptor';
import { CurrencyPipe } from '@angular/common';

export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ThemeModule,
    RoutesModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    StartupService,
    AuthService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
