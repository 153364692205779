<mat-toolbar class="matero-toolbar">

  <page-header></page-header>

  <span fxFlex></span>

  <button matTooltip="Invite Organization" mat-icon-button class="matero-toolbar-button" (click)="addOrganizationClicked()">
    <mat-icon style="font-size: 30px;">group_add</mat-icon>
  </button>

  <app-notification matTooltip="Show Notifications"></app-notification>

  <button matTooltip="Show FAQ" mat-icon-button class="matero-toolbar-button" (click)="openFAQ()">
    <mat-icon>help</mat-icon>
  </button>

  <app-user></app-user>

</mat-toolbar>