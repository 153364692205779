import { Pipe, PipeTransform } from '@angular/core';

// by default replace underscore _ to a space
@Pipe({ name: 'replace' })
export class StringReplacePipe implements PipeTransform {
  transform(value: string, strToBeReplaced = '_', replacementStr = ' '): string {
    if (!value) {
      return value;
    }

    return value.replace(new RegExp(strToBeReplaced, 'g'), replacementStr);
  }
}
