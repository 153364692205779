import { Component, OnInit, isDevMode } from '@angular/core';
import { UiService } from 'app/dp-world/services/ui.service';
import { ApplyDialogComponent } from '../apply-dialog/apply-dialog.component';
import { ApplyLogisticsDialogComponent } from '../apply-logistics-dialog/apply-logistics-dialog.component';
import { Router } from '@angular/router';
import { LogService } from 'app/dp-world/services/log.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ReasonDialogComponent } from 'app/routes/apply/def/reason-dialog/reason-dialog.component';

@Component({
  selector: 'app-choose-dialog',
  templateUrl: './choose-dialog.component.html',
  styleUrls: ['./choose-dialog.component.scss']
})

/**
 * Popup that opens when user clicks 'Apply Trade Finance' on Dashboard.
 */
export class ChooseDialogComponent implements OnInit {

  financeType = null;

  constructor(private ui: UiService,
    private router: Router,
    private logger: LogService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReasonDialogComponent>,) { }

  ngOnInit() {
  }

  financeTypeChanged() {
    console.log("financeTypeChanged; " + this.financeType);

    if (this.financeType == 'logistics') {


      //navigate to new application.
      const dialogRef = this.dialog.open(ApplyLogisticsDialogComponent, {
        width: '94%',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed: ' + result);
        this.applyPopupFinished(result);
      });

      //this.router.navigate(['/apply/def'], { queryParams: { financeType: 'logistics' } } );
      //this.dialogRef.close();

    } else if (this.financeType == 'normal') {

      //navigate to new application.
      const dialogRef = this.dialog.open(ApplyDialogComponent, {
        width: '94%',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed: ' + result);
        this.applyPopupFinished(result);
      });
    }
  }

  applyPopupFinished(response: string) {
    if (response == "proceed") {
      this.router.navigate(['/apply/def']);
      this.dialogRef.close();
    } else if (response == "logistics") {
      this.router.navigate(['/apply/def'], { queryParams: { financeType:'LogisticsFinance' } });
      this.dialogRef.close();
    
    // if the user dismissed either popup
    } else {
      this.financeType=null;
    }


  }
}
