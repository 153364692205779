import * as i0 from "@angular/core";
var PreloaderService = /** @class */ (function () {
    function PreloaderService() {
        this.selector = 'globalLoader';
    }
    PreloaderService.prototype.getElement = function () {
        return document.getElementById(this.selector);
    };
    PreloaderService.prototype.hide = function () {
        var el = this.getElement();
        if (el) {
            el.addEventListener('transitionend', function () {
                el.className = 'global-loader-hidden';
            });
            if (!el.className.includes('global-loader-hidden')) {
                el.className += ' global-loader-fade-in';
            }
        }
    };
    PreloaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreloaderService_Factory() { return new PreloaderService(); }, token: PreloaderService, providedIn: "root" });
    return PreloaderService;
}());
export { PreloaderService };
