import { Component, OnInit, ViewEncapsulation, Input, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MenuService } from '@core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'page-header',
  host: {
    //class: 'matero-page-header',
  },
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PageHeaderComponent implements OnInit {
  @Input() title = '';
  //@Input() subtitle = '';
  @Input() nav: string[] = [];
  @Input() showBreadCrumb = true;

  urlMap = {
    "dashboard": {
      title: 'Dashboard',
      subTitle: 'Welcome to the Finance Portal',
      //secondLinkURL:'dashboard',
      //secondLink:'Dashboard',
      //firstLink:'Home',
    },
    "status/def": {
      title: 'Finance Applications',
      secondLink: 'Applications',
      firstLink: 'Home',
    },
    "update/def": {
      title: 'Update Finance',
      secondLink: 'Update',
      firstLink: 'Home',
    },
    "apply/def": {
      title: 'Apply',
      secondLink: 'Apply',
      firstLink: 'Home',
    },
    "transaction-history/def": {
      title: 'Transactions',
      secondLink: 'Transactions',
      firstLink: 'Home',
    },
    "settings/def": {
      title: 'Settings',
      secondLink: 'Settings',
      firstLink: 'Home',
    },
    "view/def": {
      title: 'View',
      secondLink: 'View',
      firstLink: 'Home',
    },
    "faq/def": {
      title: 'FAQ',
      secondLink: 'FAQ',
      firstLink: 'Home',
    },
    "home/def": {
      title: 'Home',
      firstLink: 'Welcome to the Finance Portal'
    }
  }

  mapDisplayed = null;

  constructor(private router: Router, private menuService: MenuService, private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    this.genBreadcrumb();

    this.title = this.title || this.nav[this.nav.length - 1];

    // check for user changing tabs
    this.router.events.subscribe((event: Event) => {
      //console.log("route changed!");
      if (event instanceof NavigationEnd) {

        // refresh what breadcrumbs show
        this.genBreadcrumb();
      }
    });
  }

  genBreadcrumb() {
    let path = this.router.url.slice(1);

    // if we have url parameters
    if (path.indexOf("?") > 0) {
      // remove them
      path = path.substring(0, path.indexOf("?"));
    }
    
    //console.log("pageHeader.genBreadcrumb; path: "+path);
    //get what we want to show
    this.mapDisplayed = this.urlMap[path];
    this.ref.detectChanges();
  }
}
