<div style="margin-left: 20px;">
  <div style="margin-bottom: 0;">
    <div style="color: #173A64;">{{mapDisplayed?.title}}</div>
  </div>

  <div style="font-size: 12px; margin-top: 0; line-height: 1;" *ngIf="mapDisplayed?.subTitle">
    {{mapDisplayed.subTitle}}
  </div>

  <breadcrumb *ngIf="showBreadCrumb" [diplayData]="mapDisplayed" [nav]="nav"></breadcrumb>
</div>