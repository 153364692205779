import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as screenfull from 'screenfull';
import { Router } from '@angular/router';
import { AddOrganizationComponent } from 'app/routes/logistics-finance/step-a/add-organization/add-organization.component';
import { MatDialog } from '@angular/material';
import { DashboardService } from 'app/routes/dashboard/dashboard.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * Component that runs along the top of the site.
 */
export class HeaderComponent implements OnInit {
  @Input() showToggle = true;
  @Input() showBranding = false;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();

  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  constructor(private router:Router,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private ui: UiService,
    private logger: LogService) {}

  ngOnInit() {}

  // TODO:
  toggleFullscreen() {
    if (this.screenfull.enabled) {
      this.screenfull.toggle();
    }
  }

  openFAQ() {
    console.log("openFAQ;");
    this.router.navigate(['/faq/def']);
  }

  addOrganizationClicked(){
    console.log("addOrganizationClicked; ");

    //navigate to new application.
    const dialogRef = this.dialog.open( AddOrganizationComponent , {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: '+result);
      if (result){
        this.addOrganizationFinished(result);
      }
    });
  }

  addOrganizationFinished(response){

    console.log("addOrganizationFinished; response: ", response);

    let request = {
      "fullName": response.name,
      "primaryContactPhone": response.phone,
      "primaryContactEmail": response.email
    }

    this.dashboardService.inviteOrganization(request).subscribe( (result)=>{

      if (!result) {
        this.ui.snackbar('The backend is currently not allowing add organization');
        this.logger.logError('The backend is currently not allowing add organization');
        return;
      }

      this.ui.snackbar('The organization has been invited! They will receive a signup email soon.');

      console.log("inviteOrganization; result: ", result);
    },
    error => {

      if (error.error.errorMessage == "Organisation details already exist"){
        this.ui.snackbar("This organization has already been invited to the platform.");
        return;
      }

      this.ui.snackbar('The backend is currently not allowing add organization');
      this.logger.logError('The backend is currently not allowing add organization');
    });
  }

}
