import { FinanceStates } from '../apply/apply-models';

/**
 * used by TransactionHistory tab
 */
export interface Transaction {
  external_transaction_id: string; //"22087014"
  finance_id: number; //1000044
  transaction_type: string; //"Credit"
  transaction_amount: string; //"2901.141596"
  transaction_currency: string; //"USD"
  credit_account: string; //"78146598"
  mode_of_payment: string; //"Account Transfer"
  debit_from_org_name: string;
  credit_to_org_name: string;
  created_at: string;
  notes: string; //null
  invoice_currency: string;
  transaction_finace_type: string; // which FinanceType was this against?
}

export enum StatusType {
  'PROCESSING' = 'PROCESSING',
  'PENDING' = 'PENDING',
  'REVERTED' = 'REVERTED',
  'DRAFT' = 'DRAFT',
  'APPROVED' = 'APPROVED',
  'IN_PROCESS' = 'IN_PROCESS',
  'SETTLED' = 'SETTLED',
}

export enum FinanceStateType {
  //'APPLIED' = FinanceStates.BCO_FINANCE_SAVED,
  'ACCEPTED' = FinanceStates.DTF_FINANCE_ACCEPTED,
  'PROCESSING' = FinanceStates.BANKS_FINANCE_ASSESSED,
  'SETTLED' = FinanceStates.ACCOUNT_SETTLED,
  'APPROVED' = FinanceStates.BCO_TERMS_AGREED,
}

export interface Settings {
  user: {
    userName: string;
    name: string;
    email: string;
    phone: string;
  };
  bank: {
    bank: string;
    branch: string;
    country: string;
    city: string;
    accountName: string;
    accountNumber: string;
    IBAN: string;
    swiftCode: string;
  };
  compliance: {
    panCard: string;
    GST_Num: string;
  };
}

export interface SaveSettingsResponse {}

/**
 * used by dashboard.
 */
export interface Finance {
  finance_id: number; //654678,
  finance_sub_classification: string; //"Pre/Post",
  proof_id: string; //"223456789",
  proof_type: string; //"PO/Sales/Contract",
  amount: number; //50000.00,
  currency_type: string; //"dollar",
  current_state: string; //"Banks_Finance_Assessed",
  timestamp: string; //"2011-10-05T14:48:00.000Z"
}

export interface Notification {
  id?: string; // new
  message_id: string; // old DEPRECATED "001",
  finance_id: string; //"123456",
  message: string; //"DTF has been created",
  timestamp: string; //"2020-03-09T08:27:07Z"
  has_seen?: boolean;
}

export interface CreditLimitInfo {
  max_limit: string; //"100000.00",
  availed_amount: number; //50000.00,
  currency_type: string; //"dollar",
  num_of_active_loans: number; //5
  total_finance_requested?: number;
  total_finance_approved?: number;
  total_finance_given?: number;
}

// TODO: Shift this required place
export interface Organization {
  id: string;
  organisation_type: string;
  full_name: string;
  organisation_address_primary: string;
  organisation_contact_phone_primary: string;
  organisation_contact_email_primary: string;
  organisation_address_secondary: string | null;
  organisation_contact_phone_secondary: string | null;
  organisation_contact_email_secondary: string | null;
  country: string;
  credit_limit: string;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}
