import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, Route, CanLoad } from '@angular/router';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
import { CreditLimitInfo } from '../dashboard.models';
import { MatDialog } from '@angular/material';
import { ApplyDialogComponent } from '../apply-dialog/apply-dialog.component';
import { ChooseDialogComponent } from '../choose-dialog/choose-dialog.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-available-credit',
  templateUrl: './available-credit.component.html',
  styleUrls: ['./available-credit.component.scss']
})

/** 
 * Doughnut chart area on dashboard. 
 * @author charles.skinner@dpworld.com
 */
export class AvailableCreditComponent implements OnInit {
  @Input() creditLimitInfo: any;
  @Output() tradeFinanceFilterFlag = new EventEmitter<boolean>();
  tradeCreditLimitInfo: CreditLimitInfo;
  logisticsCreditLimitInfo: CreditLimitInfo;

  tradePieChartData: any[] = [
    {
      "name": "Finance Availed",
      "value": 0
    },
    {
      "name": "Finance Available",
      "value": 0
    },
  ];

  tradePieChartDataForBanksandAdmins: any[] = [
    {
      "name": "Total Finance Requested",
      "value": 0
    },
    {
      "name": "Total Finance Approved",
      "value": 0
    },
    {
      "name": "Total Finance Given",
      "value": 0
    },
  ];

  logisticsPieChartData: any[] = [
    {
      "name": "Finance Availed",
      "value": 0
    },
    {
      "name": "Finance Available",
      "value": 0
    },
  ];

  logisticsPieChartDataForBanksandAdmins: any[] = [
    {
      "name": "Total Finance Requested",
      "value": 0
    },
    {
      "name": "Total Finance Approved",
      "value": 0
    },
    {
      "name": "Total Finance Given",
      "value": 0
    },
  ];

  view: any[] = [250, 250, 250];

  bankOrDTFAdmin:boolean=true;

  financeTypeGroup: FormGroup;
  financeTypes = ["Trade Finance", "Logistics Finance"];

  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;

  isTradeFinance: boolean = true;

  colorScheme = {
    domain: ['orange', 'lightblue', '#C7B42C', '#AAAAAA']
  };

  constructor(private router:Router, private ui:UiService,
    private logger:LogService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.tradeCreditLimitInfo = this.creditLimitInfo.get("Trade");
    if (!this.tradeCreditLimitInfo.availed_amount){
      this.bankOrDTFAdmin=false;

      this.tradePieChartDataForBanksandAdmins[0].value = this.tradeCreditLimitInfo.total_finance_requested;
      this.tradePieChartDataForBanksandAdmins[1].value = this.tradeCreditLimitInfo.total_finance_approved;
      this.tradePieChartDataForBanksandAdmins[2].value = this.tradeCreditLimitInfo.total_finance_given;

    } else {
      //plug data into pie chart
      this.tradePieChartData[0].value = this.tradeCreditLimitInfo.availed_amount;
      this.tradePieChartData[1].value = Number(this.tradeCreditLimitInfo.max_limit) - this.tradeCreditLimitInfo.availed_amount;
    }
    
    this.logisticsCreditLimitInfo = this.creditLimitInfo.get("Logistics");
    if (!this.logisticsCreditLimitInfo.availed_amount){
      this.bankOrDTFAdmin=false;

      this.logisticsPieChartDataForBanksandAdmins[0].value = this.logisticsCreditLimitInfo.total_finance_requested;
      this.logisticsPieChartDataForBanksandAdmins[1].value = this.logisticsCreditLimitInfo.total_finance_approved;
      this.logisticsPieChartDataForBanksandAdmins[2].value = this.logisticsCreditLimitInfo.total_finance_given;

    } else {
      //plug data into pie chart
      this.logisticsPieChartData[0].value = this.logisticsCreditLimitInfo.availed_amount;
      this.logisticsPieChartData[1].value = Number(this.logisticsCreditLimitInfo.max_limit) - this.logisticsCreditLimitInfo.availed_amount;
    }
    this.financeTypeGroup = this._formBuilder.group({
      financeType: ['', Validators.required],
    });
  }

  applyClicked() {

    const dialogRef = this.dialog.open( ChooseDialogComponent , {
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: '+result);
      //this.applyFinished(result);


    });
  }

  financeTypeChanged(){
    let selectedFinanceType: any = this.financeTypeGroup.get('financeType').value;
    console.log("selectedFinanceType", selectedFinanceType);
    if(selectedFinanceType == "Logistics Finance"){
      this.isTradeFinance = false;
    } else if(selectedFinanceType == "Trade Finance"){
      this.isTradeFinance = true;
    }
    this.tradeFinanceFilterFlag.emit(this.isTradeFinance);
  }

  applyFinished(response:string){

    if (response=="proceed"){
      this.router.navigate(['/apply/def']);
    } else if (response=="logistics") {
      this.router.navigate(['/apply/def'], { queryParams: { financeType: 'logistics' } } );
    }
  }

  payNowClicked(){
    this.ui.snackbar("This functionality doesn't exist yet. Please pay the bank directly.");
  }
}
