import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../settings';

@Injectable({
  providedIn: 'root',
})


/**
 * TODO: 3-9-2020 - this settings system is really messed up, will rip this out
 * and write a real one.
 */
export class SettingsService {
  private notice$ = new Subject<any>();

  private options = defaults;

  get notice(): Observable<any> {
    return this.notice$.asObservable();
  }

  // What calls this?
  // March 9, 2020 - since we don't know what calls this, we'll work around it.
  setLayout(options?: AppSettings): AppSettings {
    
    //console.log("setLayout; options: "+JSON.stringify(options) );
    let settings = localStorage.getItem("userSettings");
    this.options = Object.assign(defaults, settings);
    return this.options;
  }

  setNavState(type: string, value: boolean) {

    //console.log("setNavState;  type : "+type+" value: "+value);

    this.notice$.next({ type, value } as any);
  }

  getOptions(): any {

    let settings = {
      navPos: 'side',
      theme: 'light',
      dir: 'ltr',
      showHeader: true,
      headerPos: 'fixed',
      showUserPanel: true,
      sidenavOpened: false,    //
      sidenavCollapsed: true, // what does this do?
    };

    //console.log("getOptions; settings : ", settings );

    return settings;

    //
    //return this.options;
  }

  setOptions(settings: AppSettings){
    
  }
}
