import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-finance-status',
  templateUrl: './finance-status.component.html',
  styleUrls: ['./finance-status.component.scss']
})

/** 
 * Bar chart section of dashboard.
 * @author charles.skinner@dpworld.com
 */
export class FinanceStatusComponent implements OnInit {
  @Input() financeStatus:any;
  @Input() isTradeFinance: boolean;
  // width and height of the chart
  view: any[] = [700, 380];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = '';
  showYAxisLabel = false;
  yAxisLabel = 'Population';
  tradeFinanceStatus: any;
  logisticsFinanceStatus: any;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', 'blue', '#AAAAAA', 'purple' ]
  };
  constructor() {}
  ngOnInit() {
    this.tradeFinanceStatus = this.financeStatus.get("Trade");
    this.logisticsFinanceStatus = this.financeStatus.get("Logistics");
  }
  onSelect(event){}
}
