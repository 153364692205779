import { StatusType, FinanceStateType } from 'app/routes/dashboard/dashboard.models';
import { FinanceStates, FinanceType } from 'app/routes/apply/apply-models';
import { Validators, FormControl } from '@angular/forms';
import * as i0 from "@angular/core";
var UtilsService = /** @class */ (function () {
    function UtilsService() {
    }
    /**
     * Used by tables to display the state color of finances.
     */
    UtilsService.prototype.getColor = function (status) {
        switch (status) {
            case StatusType.PROCESSING:
                return '#CCCC00';
            case StatusType.REVERTED:
                return 'brown';
            case StatusType.DRAFT:
                return 'blue';
            case StatusType.APPROVED:
                return 'green';
            case StatusType.IN_PROCESS:
                return '#CCCC00';
            case StatusType.SETTLED:
                return 'green';
            default:
                return 'red';
        }
    };
    /**
     * Used by tables on several views.
     * @param state
     */
    UtilsService.prototype.getFinanceStatusColor = function (state) {
        // used by tables to display the state color of finances.
        switch (state) {
            case FinanceStateType.ACCEPTED:
                return 'blue';
            case FinanceStates.BCO_FINANCE_SAVED:
                return '#CCCC00';
            case FinanceStateType.SETTLED:
                return 'blue';
            case FinanceStateType.APPROVED:
                return 'green';
            case FinanceStates.BCO_FINANCE_SUBMITTED:
                return 'blue';
            case FinanceStateType.PROCESSING:
                return 'orange';
            default:
                return 'green';
        }
    };
    /**
     * Converts a FinanceState to a user friendly string.
     * @param state
     */
    UtilsService.prototype.getFinanceState = function (state) {
        // used by tables to display the state color of finances.
        switch (state) {
            case FinanceStateType.ACCEPTED:
                return 'Under Assessment';
            case FinanceStates.BCO_FINANCE_SAVED:
                return 'Draft';
            case FinanceStateType.SETTLED:
                return 'Application Settled';
            case FinanceStateType.APPROVED:
                return 'Terms Accepted';
            case FinanceStateType.PROCESSING:
                return 'Bank Assessed';
            case FinanceStates.FINANCE_CANCELLED:
                return 'Cancelled';
            case FinanceStates.BCO_FINANCE_ACCEPTED:
                return 'Endorsed';
            case FinanceStates.BCO_FINANCE_SUBMITTED:
                return 'Submitted';
            case FinanceStates.BANK_FUNDS_TRANSFERRED:
                return 'Funds Transferred';
            case FinanceStates.PROFORMA_INVOICE_ACCEPTED:
                return 'Invoice Accepted';
            case FinanceStates.PURCHASE_ORDER_ACCEPTED:
                return 'Purchase Order Accepted';
            case FinanceStates.PROFORMA_INVOICE_ACCEPTED:
                return 'Proforma Invoice Accepted';
            case FinanceStates.EXPORTER_PRODUCTION_COMPLETED:
                return 'Exporter Production Completed';
            case FinanceStates.CARGO_READY_TO_SHIP:
                return 'Cargo Ready For Ship';
            case FinanceStates.CARGO_GATED_IN_ORIGIN_PORT:
                return 'Cargo Gated In Origin Port';
            case FinanceStates.CARGO_RECEIVED_ORIGIN_YARD:
                return 'Cargo Received At Origin Yard';
            case FinanceStates.CARGO_INSPECTED_ORIGIN_PORT:
                return 'Cargo Inspected At Origin Port';
            case FinanceStates.CARGO_LOADED_ORIGIN_PORT:
                return 'Cargo Loaded At Origin Port';
            case FinanceStates.VESSEL_DEPARTED_ORIGIN_PORT:
                return 'Vessel Departed From Origin Port';
            case FinanceStates.VESSEL_ARRIVED_DESTINATION_PORT:
                return 'Vessel Arrived At Destination Port';
            case FinanceStates.CARGO_UNLOADED_DESTINATION_YARD:
                return 'Cargo Unloaded At Destination Yard';
            case FinanceStates.CARGO_CLEARED_IMPORTS_DESTINATION_PORT:
                return 'Cargo Cleared Imports At Destination Port';
            case FinanceStates.CARGO_INSPECTED_DESTINATION_PORT:
                return 'Cargo Inspected At Destination Port';
            case FinanceStates.CARGO_RELEASE_GENERATED_DESTINATION_PORT:
                return 'Cargo Release Generated At Destination Port';
            case FinanceStates.CARGO_GATED_OUT_DESTINATION_PORT:
                return 'Cargo Gated Out Of Destination Port';
            case FinanceStates.CARGO_RECEIVED_FREIGHT_FORWARDER:
                return 'Cargo Received By Freight Forwarder';
            case FinanceStates.CARGO_LOADED_DESTINATION_TRUCK:
                return 'Cargo Loaded In Destination Truck';
            case FinanceStates.CARGO_RECEIVED_IMPORTER_FACILITY:
                return 'Cargo Received By Importer';
            case FinanceStates.ACCOUNT_SETTLED:
                return 'Payment Settled';
            case FinanceStates.DTF_FINANCE_CLOSED:
                return 'Application closed';
            default:
                return state;
        }
    };
    /**
     * Converts a FinanceState to a user friendly string.
     * @param state
     */
    UtilsService.prototype.getFinanceStateTooltip = function (state) {
        // used by tables to display the state color of finances.
        switch (state) {
            case FinanceStateType.ACCEPTED:
                return 'The DTF admin has accepted the application';
            case FinanceStates.BCO_FINANCE_SAVED:
                return 'The application is drafted';
            case FinanceStateType.SETTLED:
                return 'The application is settled';
            case FinanceStateType.APPROVED:
                return 'Terms are accepted by both BCOs';
            case FinanceStateType.PROCESSING:
                return 'BCO parties has to accept the terms set by bank for amount to be sanctioned';
            case FinanceStates.FINANCE_CANCELLED:
                return 'The application has been cancelled';
            case FinanceStates.BCO_FINANCE_ACCEPTED:
                return 'The application has been endorsed by other BCO.';
            case FinanceStates.BCO_FINANCE_SUBMITTED:
                return 'The application is submitted';
            case FinanceStates.BANK_FUNDS_TRANSFERRED:
                return 'The funds has been transferred';
            case FinanceStates.PROFORMA_INVOICE_ACCEPTED:
                return 'Invoice has been accepted';
            case FinanceStates.PURCHASE_ORDER_ACCEPTED:
                return 'Purchase Order has been accepted';
            case FinanceStates.PROFORMA_INVOICE_ACCEPTED:
                return 'Proforma Invoice has been accepted';
            case FinanceStates.EXPORTER_PRODUCTION_COMPLETED:
                return 'Exporter production is completed';
            case FinanceStates.CARGO_READY_TO_SHIP:
                return 'Cargo is ready to ship';
            case FinanceStates.CARGO_GATED_IN_ORIGIN_PORT:
                return 'Cargo is gated at the origin port';
            case FinanceStates.CARGO_RECEIVED_ORIGIN_YARD:
                return 'Cargo is received at the origin yard';
            case FinanceStates.CARGO_INSPECTED_ORIGIN_PORT:
                return 'Cargo  is inspected at origin port';
            case FinanceStates.CARGO_LOADED_ORIGIN_PORT:
                return 'Cargo is loaded at the origin port';
            case FinanceStates.VESSEL_DEPARTED_ORIGIN_PORT:
                return 'Vessel has departed from the origin port';
            case FinanceStates.VESSEL_ARRIVED_DESTINATION_PORT:
                return 'Vessel has arrived at the destination port';
            case FinanceStates.CARGO_UNLOADED_DESTINATION_YARD:
                return 'Cargo is unloaded at the destination yard';
            case FinanceStates.CARGO_CLEARED_IMPORTS_DESTINATION_PORT:
                return 'Cargo has cleared Imports at the destination port';
            case FinanceStates.CARGO_INSPECTED_DESTINATION_PORT:
                return 'Cargo is inspected at the destination port';
            case FinanceStates.CARGO_RELEASE_GENERATED_DESTINATION_PORT:
                return 'Cargo Release has been generated at the destination port';
            case FinanceStates.CARGO_GATED_OUT_DESTINATION_PORT:
                return 'Cargo has been gated out of the destination port';
            case FinanceStates.CARGO_RECEIVED_FREIGHT_FORWARDER:
                return 'Cargo is received by the freight forwarder';
            case FinanceStates.CARGO_LOADED_DESTINATION_TRUCK:
                return 'Cargo is loaded in the destination truck';
            case FinanceStates.CARGO_RECEIVED_IMPORTER_FACILITY:
                return 'Cargo is received by the importer';
            case FinanceStates.ACCOUNT_SETTLED:
                return 'The Importer has paid back';
            case FinanceStates.DTF_FINANCE_CLOSED:
                return 'Application has been successfully closed';
            default:
                return state;
        }
    };
    /**
     * used by several tables to determine if they should show update button.
     * @param finance
     */
    UtilsService.prototype.shouldShowUpdateButton = function (finance) {
        if (finance.state === FinanceStates.FINANCE_CANCELLED ||
            finance.state === FinanceStates.DTF_FINANCE_CLOSED) {
            return false;
        }
        return true;
    };
    /**
     * Used by applications tab.
     * @param data
     */
    UtilsService.prototype.processData = function (data) {
        var processedData = [];
        var financeMap = {};
        for (var i = 0; i < data.length; i++) {
            var finance = data[i];
            var record = {
                finance_id: finance.finance_id,
                importer_name: finance.importer_name,
                exporter_name: finance.exporter_name,
                submitted_at: finance.submitted_at,
                finance_type: finance.finance_type,
                requested_amount: finance.requested_amount,
                state: finance.state,
                action: finance.action,
            };
            processedData.push(record);
            financeMap[finance.finance_id] = finance;
        }
        return [processedData, financeMap];
    };
    // DEPRECATED - doesn't work for multi-level
    UtilsService.prototype.getFormData = function (object) {
        var formData = new FormData();
        Object.keys(object).forEach(function (key) {
            //console.log("getFormData; "+key);
            formData.append(key, object[key]);
        });
        return formData;
    };
    /**
     * Is this the BCO that started the application?
     * @param user
     * @param finance
     */
    UtilsService.prototype.isApplicantBCO = function (user, finance) {
        return user.user_id === finance.created_by;
    };
    /**
     * Returns whether the user is a BCO, but not the one that started this application.
     * @param user
     * @param finance
     */
    UtilsService.prototype.isNoneApplicantBCO = function (user, finance) {
        var lookingFor = '';
        if (!finance.usersDetails) {
            return false;
        }
        for (var i = 0; i < finance.usersDetails.length; i++) {
            var detail = finance.usersDetails[i];
            if (detail.user_id == finance.created_by) {
                console.log('isNoneApplicantBCO; creator was : ', detail);
                if (detail.user_organisation_type == 'IMPORTER') {
                    console.log('isNoneApplicantBCO; creator was EXPORTER!');
                    lookingFor = 'EXPORTER';
                }
                else {
                    console.log('isNoneApplicantBCO; creator was IMPORTER!');
                    lookingFor = 'IMPORTER';
                }
                break;
            }
        }
        for (var i = 0; i < finance.usersDetails.length; i++) {
            var detail = finance.usersDetails[i];
            if (detail.user_organisation_type == lookingFor) {
                console.log('isNoneApplicantBCO; none creator is: ', detail);
                if (detail.user_id == user.user_id) {
                    console.log('isNoneApplicantBCO; we are the none creator!');
                    return true;
                }
                else {
                    console.log('isNoneApplicantBCO; we are NOT the none creator!');
                    return false;
                }
            }
        }
        return false;
    };
    /**
     * Used by View and Apply tabs.
     * @param finance
     * @param locations
     * @param banks
     * @param formBuilder
     */
    UtilsService.prototype.createFormFromFinance = function (finance, locations, banks, formBuilder) {
        // setup countries
        //dynamically assign defaults so changes to static data will not break testing tool.
        var toCountry = null;
        var toCountryName = '';
        var selectedBank = null;
        var fromCountry = null;
        var fromCountryName = '';
        for (var i = 0; i < locations.length; i++) {
            if (locations[i].country_name == finance.shipment_origin_country) {
                fromCountry = locations[i];
                fromCountryName = fromCountry.country_name;
            }
        }
        for (var i = 0; i < locations.length; i++) {
            if (locations[i].country_name == finance.shipment_destination_country) {
                toCountry = locations[i];
                toCountryName = toCountry.country_name;
                break;
            }
        }
        // this can be empty in logistics case
        if (finance.banksDetails.length > 0) {
            for (var i = 0; i < banks.length; i++) {
                if (banks[i].id == finance.banksDetails[0].bank_id) {
                    selectedBank = banks[i];
                    break;
                }
            }
        }
        // hack to deal with Save scenario not passing back currency
        if (!finance.requested_currency) {
            finance.requested_currency = 'USD';
        }
        console.log('selectedBank: ', selectedBank);
        // used by second step of apply process
        var formGroup = formBuilder.group({
            fromCountry: [fromCountry, Validators.required],
            fromCountryName: [fromCountryName, Validators.required],
            toCountry: [toCountry, Validators.required],
            toCountryName: [toCountryName, Validators.required],
            fromCity: [finance.shipment_origin_city, Validators.required],
            toCity: [finance.shipment_destination_city, Validators.required],
            fromPort: [finance.shipment_origin_port, Validators.required],
            toPort: [finance.shipment_destination_port, Validators.required],
            amount: [1, Validators.required],
            contentDescription: [finance.shipment_description, Validators.required],
            importer: [finance.importer_id, Validators.required],
            importerName: [finance.importer_name, Validators.required],
            exporter: [finance.exporter_id, Validators.required],
            exporterName: [finance.exporter_name, Validators.required],
            cargoType: ['', Validators.required],
            loading: ['', Validators.required],
            discharge: ['', Validators.required],
            proofType: [finance.proof_type, Validators.required],
            incoterms: [finance.inco_terms, Validators.required],
            totalAmount: [finance.invoice_amount, Validators.required],
            requestedAmount: [finance.requested_amount, Validators.required],
            totalAmountHolder: ['', Validators.required],
            requestedAmountHolder: ['', Validators.required],
            endorseProofType: ['', Validators.required],
            endorseFile: [null, Validators.required],
            dispersalTime: ['', Validators.required],
            bank: [selectedBank, Validators.required],
            currency: [finance.requested_currency, Validators.required],
            BCOType: ['', Validators.required],
            sanctionedAmount: ['', Validators.required],
            discountRate: ['', Validators.required],
            processingFee: ['', Validators.required],
            amountReceivable: ['', Validators.required],
            transactionEvent: ['', Validators.required],
        });
        if (finance.financeType == FinanceType.Logistics) {
            formGroup.addControl('invoiceId', new FormControl(finance.invoice_id));
            formGroup.addControl('invoiceDate', new FormControl(finance.invoice_date));
            formGroup.addControl('invoiceDueDate', new FormControl(finance.invoice_due_date));
            formGroup.addControl('invoiceAmount', new FormControl(finance.invoice_amount));
            formGroup.addControl('invoiceCurrency', new FormControl(finance.invoice_currency));
            formGroup.addControl('dispersalCurrency', new FormControl(finance.requested_currency));
            formGroup.addControl('receivableAmount', new FormControl(finance.receivable_amount));
            // TODO: do i still need this?
            // this might not exist in a save scenario
            var dispersal = finance.userDisbursalEventsDetails[0];
            if (dispersal) {
                formGroup.addControl('discountRate', new FormControl(finance.userDisbursalEventsDetails[0].discount_rate));
            }
            formGroup.addControl('requestedAmount', new FormControl(finance.requested_amount));
            formGroup.addControl('logisticsDetails', formBuilder.array([]));
            var logisticsDetails = formGroup.get('logisticsDetails');
            for (var i = 0; i < finance.userDisbursalEventsDetails.length; i++) {
                var event_1 = finance.userDisbursalEventsDetails[i];
                logisticsDetails.push(this.createLogisticsDetails(event_1, formBuilder));
            }
        }
        // can be null in logistics case
        if (finance.paymentTermsDetails.length > 0) {
            formGroup.addControl('eventDetails', formBuilder.array([]));
            var events = formGroup.get('eventDetails');
            for (var i = 0; i < finance.paymentTermsDetails[0].disbursalEvents.length; i++) {
                var event_2 = finance.paymentTermsDetails[0].disbursalEvents[i];
                events.push(this.createEventsDetails(event_2, formBuilder));
            }
            if (finance.paymentTermsDetails[0].settlementEvents) {
                formGroup.addControl('settlementEventDetails', formBuilder.array([]));
                var settlementEvents = formGroup.get('settlementEventDetails');
                for (var i = 0; i < finance.paymentTermsDetails[0].settlementEvents.length; i++) {
                    var settlementEvent = finance.paymentTermsDetails[0].settlementEvents[i];
                    settlementEvents.push(this.createSettlementEventsDetails(settlementEvent, formBuilder));
                }
            }
        }
        if (finance.cargoDetails && finance.cargoDetails.length > 0) {
            formGroup.addControl('cargoDetails', formBuilder.array([]));
            var cargoDetails = formGroup.get('cargoDetails');
            for (var i = 0; i < finance.cargoDetails.length; i++) {
                var cargo = finance.cargoDetails[i];
                cargoDetails.push(this.createCargoDetails(cargo, formBuilder));
            }
        }
        return formGroup;
    };
    /**
     * takes data from finance object and constructs CargoDetails our form can read.
     * @param cargo
     * @param formBuilder
     */
    UtilsService.prototype.createCargoDetails = function (cargo, formBuilder) {
        return formBuilder.group({
            cargoDescription: [cargo.cargo_description, Validators.required],
            quantity: [cargo.quantity, Validators.required],
            unitPrice: [cargo.unit_price, Validators.required],
        });
    };
    UtilsService.prototype.createEventsDetails = function (event, formBuilder) {
        var details = formBuilder.group({
            paymentEvent: [event.disburse_on_event, Validators.required],
            daysAfterDisbursalEvent: [event.payment_deadline_in_days, Validators.required],
            paymentPercentage: [event.payment_percentage, [Validators.required, Validators.max(100)]],
            interestBearer: [event.interest_bearer, Validators.required],
            recourse: [event.recourse, Validators.required],
            interestRate: [event.interest_rate, Validators.required],
        });
        console.log("createEventsDetails; event: ", event);
        return details;
    };
    UtilsService.prototype.createLogisticsDetails = function (detail, formBuilder) {
        console.log("createLogisticsDetails; detail: ", detail);
        var logisticsDetails = formBuilder.group({
            discountRate: [detail.discount_rate, Validators.required],
            paymentDate: [detail.payment_date, Validators.required],
            paymentPercentage: [detail.payment_percentage, [Validators.required, Validators.max(100)]],
            recourse: [detail.recourse, Validators.required],
            id: [detail.id]
        });
        logisticsDetails.get('discountRate').disable();
        return logisticsDetails;
    };
    /**
     * shown in Accept Terms for Trade Finance.
     * @param event
     * @param formBuilder
     */
    UtilsService.prototype.createSettlementEventsDetails = function (event, formBuilder) {
        console.log("createSettlementEventsDetails; event: ", event);
        var terms = formBuilder.group({
            settlementEvent: [event.settle_on_event, Validators.required],
            daysAfterSettlementEvent: [event.settlement_deadline_in_days, Validators.required],
            settlementPercentage: [event.settlement_percentage, [Validators.required, Validators.max(100)],],
        });
        return terms;
    };
    /**
     * Just returns the BCO that applied for this finance.
     * @param finance
     */
    UtilsService.prototype.getAppliedBCO = function (finance) {
        if (!finance.usersDetails) {
            return null;
        }
        for (var i = 0; i < finance.usersDetails.length; i++) {
            var detail = finance.usersDetails[i];
            if (detail.user_id == finance.created_by) {
                console.log('isNoneApplicantBCO; creator was : ', detail);
                return detail;
            }
        }
        return null;
    };
    UtilsService.prototype.currencyStringToNumber = function (currencyString) {
        //console.log("currencyStringToNumber; "+currencyString);
        var firstDigit = currencyString.charAt(0);
        if (isNaN(firstDigit)) {
            var num1 = parseFloat(currencyString.substr(1, currencyString.length));
            //console.log("num1: "+num1);
            return num1;
        }
        else {
            var num2 = parseFloat(currencyString);
            //console.log("num2: "+num2);
            return num2;
        }
    };
    /**
     * our azure pipeline isn't setup to deploy a staging build to staging, it deploys prod build
     * there instead, so we can't use a bool in environment file to detect staging right now. So
     * we are using this work around.
     */
    UtilsService.prototype.isStaging = function () {
        return (window.location.host == 'staging.revma.dpworld.com' || window.location.host == 'staging.finance.cargoes.com');
    };
    UtilsService.prototype.logisticsReceivableAmount = function (form) {
        var totalReceivableAmount = 0;
        //sum of discountRate * %'s totalAmount
        var invoiceAmount = form.get('invoiceAmount').value;
        //sum of %s * invoiceAmount
        var logisticsDetailsFormGroup = form.get('logisticsDetails').controls;
        logisticsDetailsFormGroup.forEach(function (eventDetails) {
            var percentageControl = eventDetails.get("paymentPercentage");
            var discountRateControl = eventDetails.get('discountRate');
            if (percentageControl && discountRateControl) {
                var paymentPercentage = percentageControl.value / 100;
                var discountRate = discountRateControl.value;
                var thisPaymentAmount = invoiceAmount * paymentPercentage;
                var totalDiscount = thisPaymentAmount * (discountRate / 100);
                var receivable = thisPaymentAmount - totalDiscount;
                totalReceivableAmount += receivable;
                //console.log("logisticsReceivableAmount calc: "+totalReceivableAmount);
            }
        });
        return totalReceivableAmount.toFixed(2);
    };
    UtilsService.prototype.logisticsRequestedAmount = function (form) {
        var totalPercentage = 0;
        //sum of %s * invoiceAmount
        var logisticsDetailsFormGroup = form.get('logisticsDetails').controls;
        logisticsDetailsFormGroup.forEach(function (eventDetails) {
            var percentage = eventDetails.get("paymentPercentage");
            if (percentage) {
                var paymentPercentage = percentage.value;
                totalPercentage += parseFloat(paymentPercentage) || 0;
            }
        });
        var invoiceAmount = form.get('invoiceAmount').value;
        //console.log("invoiceAmount: " + invoiceAmount + " totalPercentage: " + totalPercentage);
        var requestedAmount = (totalPercentage / 100) * invoiceAmount;
        return requestedAmount.toFixed(2);
    };
    /** used by logistics finance */
    UtilsService.prototype.getLogisticsRecourse = function (by) {
        if (by == "IMPORTER") {
            return "BUYER";
        }
        else if (by == "EXPORTER") {
            return "SELLER";
        }
        else {
            return by;
        }
    };
    UtilsService.prototype.financeTypeDisplayString = function (financeType) {
        if (financeType == "TradeFinance") {
            return "Trade";
        }
        else if (financeType == "LogisticsFinance") {
            return "Logistics";
        }
    };
    UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
