<button mat-icon-button class="matero-toolbar-button" [matMenuTriggerFor]="menu" (menuOpened)="menuOpened()">
  <mat-icon>notifications</mat-icon>
  <span class="badge bg-red-500" *ngIf="numUnread > 0">{{ numUnread }}</span>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="this.messages.length === 0">
    <div style="margin:20px;">
      You have no notifications.
    </div>
  </ng-container>
  <ul class="list" *ngFor="let notification of messages">
    <li *ngIf="notification.has_seen" class="list-item">
      <div fxLayout="column">
        <div class="message" (click)="notificationClicked(notification.owner_id, notification.owner_type)">{{ notification.message }}</div>
        <div class="message-type">{{notification.owner_type}}</div>
      </div>
    </li>
    <li *ngIf="!notification.has_seen" class="list-item new">
      <div fxLayout="column">
        <div class="message" (click)="notificationClicked(notification.owner_id, notification.owner_type)">{{ notification.message }}</div>
        <div class="message-type">{{notification.owner_type}}</div>
      </div>
    </li>
  </ul>
</mat-menu>