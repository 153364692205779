/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apply-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../apply/start-step/step-explain/step-explain.component.ngfactory";
import * as i4 from "../../apply/start-step/step-explain/step-explain.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./apply-dialog.component";
import * as i12 from "@angular/forms";
var styles_ApplyDialogComponent = [i0.styles];
var RenderType_ApplyDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplyDialogComponent, data: {} });
export { RenderType_ApplyDialogComponent as RenderType_ApplyDialogComponent };
export function View_ApplyDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-step-explain", [], null, null, null, i3.View_StepExplainComponent_0, i3.RenderType_StepExplainComponent)), i1.ɵdid(5, 114688, null, 0, i4.StepExplainComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["fxLayout", "row"], ["style", "margin-top: 20px;"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "action-button"], ["mat-button", ""], ["style", "color:white; margin-bottom: 20px;"], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" PROCEED WITH APPLICATION "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["style", "margin-left: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["mat-button", ""], ["style", "border: brown 1px solid; color:brown;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["CANCEL"]))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); var currVal_1 = "row"; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_ApplyDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apply-dialog", [], null, null, null, View_ApplyDialogComponent_0, RenderType_ApplyDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.ApplyDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i12.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplyDialogComponentNgFactory = i1.ɵccf("app-apply-dialog", i11.ApplyDialogComponent, View_ApplyDialogComponent_Host_0, {}, {}, []);
export { ApplyDialogComponentNgFactory as ApplyDialogComponentNgFactory };
