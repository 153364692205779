<div style="padding: 20px;">
  <div style="width: 100%;" fxLayoutAlign="center center">
    <h2>
      DP World Trade Finance
    </h2>
  </div>

  <div class="w-full h-full" style="min-height: 100vh !important; height: auto;" fxLayout="row wrap"
    fxLayoutAlign="center center">

    <div fxShow fxHide.lt-lg="true" style="background-image: url('assets/images/login_BG.png');
  background-repeat: no-repeat;
  background-size: cover !important; width: 852px; height:613px;">
    </div>

    <mat-card class="mat-elevation-z4 m-b-0" style="max-width: 380px; margin-left: 40px;">

      <div style="margin-bottom: 20px;">
        <mat-card-title style="color:#173A64;">Sign in</mat-card-title>
        <p class="text-grey-400">DP World Trade Finance</p>
      </div>

      <mat-card-content>
        <form class="matero-form-horizontal" [formGroup]="loginForm">

          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input autocomplete="new-password" matInput placeholder="user name" formControlName="username" required>
            <mat-error *ngIf="loginForm.get('username').invalid">Please enter username</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input autocomplete="new-password" matInput placeholder="password" type="password"
              formControlName="password" required>
            <mat-error *ngIf="loginForm.get('password').invalid">Please enter password</mat-error>
          </mat-form-field>

          <div #recaptcha required></div>

          <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-checkbox>Remember Me</mat-checkbox>
            <a routerLink="./">Forgot Password?</a>
          </div>

          <div *ngIf="loggingIn" style="width:348px; height: 40px;" fxLayoutAlign="center center" fxLayout="row">
            <mat-spinner diameter="35"></mat-spinner>
            <div style="margin-left: 10px;"> Logging in... </div>
          </div>

          <ng-container *ngIf="!loggingIn">
            <div style="height: 40px;">
              <button class="w-full" mat-raised-button color="primary" (click)="login()"
                [disabled]="loginForm.invalid">Sign
                In</button>
            </div>
          </ng-container>

          <div class="m-t-16">Don't have an account? <a routerLink="/auth/register">Click here to
              create one</a>
          </div>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="utils.isStaging() || isDevMode()" style="width: 215px; margin-left: 40px; margin-top: 20px;"
      fxLayout="column">

      <div style="margin-bottom: 10px;">
        Dev/Staging only
      </div>
      <button mat-button (click)="setUsername(usersList.BCO_importer)"
        style="margin-bottom: 20px; background-color: gray; color:white; width: 180px;">
        Login as BCO importer
      </button>
      <button mat-button (click)="setUsername(usersList.BCO_exporter)"
        style="margin-bottom: 20px; background-color: gray; color:white; width: 180px;">
        Login as BCO exporter
      </button>
      <button mat-button (click)="setUsername(usersList.bank)"
        style="margin-bottom: 20px; background-color: gray; color:white; width: 180px;">
        Login as Bank
      </button>
      <button mat-button (click)="setUsername(usersList.admin)"
        style="margin-bottom: 20px; background-color: gray; color:white; width: 180px;">
        Login as DP Admin
      </button>
    </mat-card>

  </div>
</div>