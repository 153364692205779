/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apply-logistics-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../apply/start-step/step-explain/step-explain.component.ngfactory";
import * as i3 from "../../apply/start-step/step-explain/step-explain.component";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@angular/common";
import * as i12 from "./apply-logistics-dialog.component";
import * as i13 from "@angular/forms";
var styles_ApplyLogisticsDialogComponent = [i0.styles];
var RenderType_ApplyLogisticsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplyLogisticsDialogComponent, data: {} });
export { RenderType_ApplyLogisticsDialogComponent as RenderType_ApplyLogisticsDialogComponent };
function View_ApplyLogisticsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-step-explain", [], null, null, null, i2.View_StepExplainComponent_0, i2.RenderType_StepExplainComponent)), i1.ɵdid(2, 114688, null, 0, i3.StepExplainComponent, [], { financeType: [0, "financeType"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["fxLayout", "row"], ["style", "margin-top: 20px;"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "action-button"], ["mat-button", ""], ["style", "color:white; margin-bottom: 20px;"], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" PROCEED WITH APPLICATION "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["style", "margin-left: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""], ["style", "border: brown 1px solid; color:brown;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["CANCEL"]))], function (_ck, _v) { var currVal_0 = "Logistics"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_ApplyLogisticsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["mat-button", ""], ["style", "background-color: brown; color:white; margin-bottom: 20px;"], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logisticsClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" PROCEED WITH APPLICATION "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ApplyLogisticsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i10.MatDialogTitle, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplyLogisticsDialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplyLogisticsDialogComponent_2)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = (_co.financeType == "normal"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.financeType == "logistics"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); }); }
export function View_ApplyLogisticsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apply-logistics-dialog", [], null, null, null, View_ApplyLogisticsDialogComponent_0, RenderType_ApplyLogisticsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.ApplyLogisticsDialogComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA, i13.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplyLogisticsDialogComponentNgFactory = i1.ɵccf("app-apply-logistics-dialog", i12.ApplyLogisticsDialogComponent, View_ApplyLogisticsDialogComponent_Host_0, {}, {}, []);
export { ApplyLogisticsDialogComponentNgFactory as ApplyLogisticsDialogComponentNgFactory };
