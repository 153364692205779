<div>
    <h1 mat-dialog-title></h1>

    <div>
        <app-step-explain></app-step-explain>

        <div fxLayout="row" style="margin-top: 20px;">

            <button mat-button type="submit" class="action-button" style="color:white; margin-bottom: 20px;"
                (click)="submitClicked()">
                PROCEED WITH APPLICATION
            </button>

            <div style="margin-left: auto;">
                <button mat-button (click)="cancelClicked()"
                    style="border: brown 1px solid; color:brown;">CANCEL</button>
            </div>
        </div>
    </div>

</div>