import { OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { SettingsService } from '@core';
import { SharedDataService } from 'app/dp-world/services/shared-data.service';
var MOBILE_MEDIAQUERY = 'screen and (max-width: 599px)';
var TABLET_MEDIAQUERY = 'screen and (min-width: 600px) and (max-width: 959px)';
var MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';
var AdminLayoutComponent = /** @class */ (function () {
    function AdminLayoutComponent(router, breakpointObserver, overlay, settings, sharedData) {
        var _this = this;
        this.router = router;
        this.breakpointObserver = breakpointObserver;
        this.overlay = overlay;
        this.settings = settings;
        this.sharedData = sharedData;
        this.options = this.settings.getOptions();
        this.isMobileScreen = false;
        this.contentWidthFix = true;
        this.collapsedWidthFix = true;
        this.materoSidenavCollapsed = null; // true && options.navPos!=='top',
        this.materoNavbarSide = null; // options.navPos==='side',
        this.materoNavbarTop = null; //options.navPos==='top',
        this.materoHeaderAbove = null; //options.headerPos==='above',
        this.materoHeaderFixed = null; //options.headerPos==='fixed',
        this.materoHeaderWhite = null; //options.theme==='light'}"
        // Set dir attr on body
        document.body.dir = this.options.dir;
        this.layoutChanges = this.breakpointObserver
            .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
            .subscribe(function (state) {
            // SidenavOpened must be reset true when layout changes
            _this.options.sidenavOpened = true;
            _this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
            _this.options.sidenavCollapsed = state.breakpoints[TABLET_MEDIAQUERY];
            _this.contentWidthFix = state.breakpoints[MONITOR_MEDIAQUERY];
        });
        // TODO: Scroll top to container
        this.router.events.subscribe(function (evt) {
            if (evt instanceof NavigationEnd) {
                _this.content.scrollTo({ top: 0 });
            }
        });
    }
    Object.defineProperty(AdminLayoutComponent.prototype, "isOver", {
        get: function () {
            return this.isMobileScreen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminLayoutComponent.prototype, "isContentWidthFix", {
        get: function () {
            return (this.contentWidthFix &&
                this.options.navPos === 'side' &&
                this.options.sidenavOpened &&
                !this.isOver);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminLayoutComponent.prototype, "isCollapsedWidthFix", {
        get: function () {
            return (this.collapsedWidthFix &&
                (this.options.navPos === 'top' || (this.options.sidenavOpened && this.isOver)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminLayoutComponent.prototype, "themeDark", {
        // Demo purposes only
        get: function () {
            return this.options.theme === 'dark';
        },
        enumerable: true,
        configurable: true
    });
    AdminLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return (_this.contentWidthFix = _this.collapsedWidthFix = false); });
        // this data runs the site, make sure we have a copy of it.
        this.sharedData.getSharedStaticData();
        this.materoSidenavCollapsed = true && this.options.navPos !== 'top';
        this.materoNavbarSide = this.options.navPos === 'side';
        this.materoNavbarTop = this.options.navPos === 'top';
        this.materoHeaderAbove = this.options.headerPos === 'above';
        this.materoHeaderFixed = this.options.headerPos === 'fixed';
        this.materoHeaderWhite = this.options.theme === 'light';
        console.log("this.materoSidenavCollapsed:" + this.materoSidenavCollapsed);
        console.log("this.materoNavbarSide: " + this.materoNavbarSide);
        console.log("this.materoNavbarTop: " + this.materoNavbarTop);
        console.log("this.materoHeaderAbove: " + this.materoHeaderAbove);
        console.log("this.materoHeaderFixed: " + this.materoHeaderFixed);
        console.log("this.materoHeaderWhite: " + this.materoHeaderWhite);
    };
    AdminLayoutComponent.prototype.ngOnDestroy = function () {
        this.layoutChanges.unsubscribe();
    };
    AdminLayoutComponent.prototype.toggleCollapsed = function () {
        console.log("toggleCollapsed; ");
        this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
        this.resetCollapsedState();
    };
    AdminLayoutComponent.prototype.resetCollapsedState = function (timer) {
        var _this = this;
        if (timer === void 0) { timer = 400; }
        // TODO: Trigger when transition end
        setTimeout(function () {
            _this.settings.setNavState('collapsed', _this.options.sidenavCollapsed);
        }, timer);
    };
    AdminLayoutComponent.prototype.sidenavCloseStart = function () {
        this.contentWidthFix = false;
    };
    AdminLayoutComponent.prototype.sidenavOpenedChange = function (isOpened) {
        this.options.sidenavOpened = isOpened;
        this.settings.setNavState('opened', isOpened);
        this.collapsedWidthFix = !this.isOver;
        this.resetCollapsedState();
    };
    // Demo purposes only
    AdminLayoutComponent.prototype.receiveOptions = function (options) {
        this.options = options;
        this.setTheme(options);
        this.setBodyDir(options);
    };
    AdminLayoutComponent.prototype.setTheme = function (options) {
        if (options.theme === 'dark') {
            this.overlay.getContainerElement().classList.add('theme-dark');
        }
        else {
            this.overlay.getContainerElement().classList.remove('theme-dark');
        }
    };
    AdminLayoutComponent.prototype.setBodyDir = function (options) {
        if (options.dir === 'rtl') {
            document.body.dir = 'rtl';
        }
        else {
            document.body.dir = 'ltr';
        }
    };
    return AdminLayoutComponent;
}());
export { AdminLayoutComponent };
