/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "./branding.component";
var styles_BrandingComponent = [];
var RenderType_BrandingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BrandingComponent, data: {} });
export { RenderType_BrandingComponent as RenderType_BrandingComponent };
export function View_BrandingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "matero-branding"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["href", "#/home/def"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i1.DefaultLayoutAlignDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutAlignStyleBuilder], i2.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "logo"], ["class", "matero-branding-logo-expanded"], ["draggable", "false"], ["src", "./assets/images/Cargoes.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "images"], ["draggable", "false"], ["src", "./assets/images/cargoflow-logo-white-v2.png"], ["style", "margin-left:10px; width: 120px; height: 34px;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); }, null); }
export function View_BrandingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branding", [], null, null, null, View_BrandingComponent_0, RenderType_BrandingComponent)), i0.ɵdid(1, 49152, null, 0, i3.BrandingComponent, [], null, null)], null, null); }
var BrandingComponentNgFactory = i0.ɵccf("app-branding", i3.BrandingComponent, View_BrandingComponent_Host_0, {}, {}, []);
export { BrandingComponentNgFactory as BrandingComponentNgFactory };
