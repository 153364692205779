import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { AvailableCreditComponent } from './dashboard/available-credit/available-credit.component';
import { FinanceStatusComponent } from './dashboard/finance-status/finance-status.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from '../dp-world/pipes/pipes.module';
import { ApplyDialogComponent } from './dashboard/apply-dialog/apply-dialog.component';
import { StepExplainComponent } from './apply/start-step/step-explain/step-explain.component';
import { FinanceRequestFormModule } from './apply/finance-request-form/finance-request-form.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddOrganizationComponent } from './logistics-finance/step-a/add-organization/add-organization.component';
import { ChooseDialogComponent } from './dashboard/choose-dialog/choose-dialog.component';
import { ApplyLogisticsDialogComponent } from './dashboard/apply-logistics-dialog/apply-logistics-dialog.component';

const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent];
const COMPONENTS_DYNAMIC = [ ];

@NgModule({
  imports: [
    SharedModule, 
    RoutesRoutingModule, 
    NgxChartsModule, 
    PipesModule, 
    NgxFileDropModule,
    FinanceRequestFormModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC, 
    AvailableCreditComponent,
    FinanceStatusComponent, 
    ApplyDialogComponent, 
    AddOrganizationComponent,
    StepExplainComponent,
    ChooseDialogComponent,
    ApplyLogisticsDialogComponent, 
  ],
  entryComponents: [ 
    ...COMPONENTS_DYNAMIC, 
    ApplyDialogComponent,
    AddOrganizationComponent,
    ChooseDialogComponent,
    ApplyLogisticsDialogComponent, 
  ],
})
export class RoutesModule {}
