import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { CreditLimitInfo } from './dashboard.models';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';
import { Router } from '@angular/router';
import { UtilsService } from 'app/dp-world/services/utils.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { AuthService } from '../sessions/login/auth.service';
import { User } from '../sessions/login/user.model';
import { FullFinance, FinanceStates, FinanceType } from '../apply/apply-models';
import { Subscription } from 'rxjs';
import { ConstantsService } from 'app/dp-world/services/constants.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation : ViewEncapsulation.None,
})

/**
 * Landing page for Trade Finance.
 * @author charles.skinner@dpworld.com
 */
export class DashboardComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private ui: UiService,
    private logger: LogService,
    private router: Router,
    public utils: UtilsService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    public constants:ConstantsService
  ) {}

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  creditLimitSubscription:Subscription;
  financesSubscription:Subscription;

  finances: Array<FullFinance>;

  // FullFinance minus anything that isn't shown, so the search functionality won't select things that aren't shown.
  financeLimited:Array<any>;

  tradeCreditLimitInfo: CreditLimitInfo;
  logisticsCreditLimitInfo: CreditLimitInfo;
  financeStatus = new Map();
  dataSource: any = null;
  user: User;
  creditLimitInfoMap = new Map();
  isTradeFinance: boolean = true;

  // controls showing our loading spinner vs the page content
  loadingTable:boolean = true;
  loadingAvailableCredit:boolean = true;

  // used by tables in Dashboard and Applications
  financeTypeDisplayName={
    TradeFinance:'Trade',
    LogisticsFinance:'Logistics'
  }

  displayedColumns: string[] = [
    'finance_id',
    'importer_name',
    'exporter_name',
    'finance_type',
    'submitted_at',
    'requested_amount',
    'state',
    'action',
  ];

  ngOnInit() {
    this.user = this.authService.currentUserValue;

    // fetch data for the table
    this.creditLimitSubscription = this.dashboardService.getFinances().subscribe(
      (data: Array<FullFinance>) => {
        if (!data) {
          this.ui.snackbar('The backend is currently not returning finance data.');
          this.logger.logError('The backend is currently not returning finance data.');
          return;
        }

        console.log("getFinances;");

        let results = this.utils.processData(data);
        this.financeLimited = results[0];

        this.finances = data;

        this.dataSource = new MatTableDataSource(this.financeLimited);
        this.dataSource.sort = this.sort;

        this.loadingTable = false;

        this.calculateBarChartData();

        console.log("getFinances; done loading: "+ (!this.loadingTable && !this.loadingAvailableCredit) );

        // force the view to update
        this.ref.detectChanges();

        console.log('DashboardComponent; this.finances: ', this.finances);
      },
      error => {
        this.ui.snackbar('The backend is currently not returning finance data.');
        this.logger.logError('The backend is currently not returning finance data.');

        this.loadingTable = false;
      }
    );

    // fetch data for the doughnut chart
    this.creditLimitSubscription = this.dashboardService.getTradeCreditLimitInfo().subscribe(
      (data: CreditLimitInfo) => {
        if (!data) {
          this.ui.snackbar('The backend is currently not returning Credit Limit data.');
          this.logger.logError('The backend is currently not returning Credit Limit data.');
          return;
        }
        this.tradeCreditLimitInfo = data;
        this.creditLimitInfoMap.set("Trade", this.tradeCreditLimitInfo);

        this.loadingAvailableCredit=false;

        console.log("getTradeCreditLimitInfo; done loading: "+ (!this.loadingTable && !this.loadingAvailableCredit) );

        // force the view to update
        this.ref.detectChanges();

        console.log('DashboardComponent; this.tradeCreditLimitInfo: ', this.tradeCreditLimitInfo);
      },
      error => {
        this.ui.snackbar('The backend is currently not returning Credit Limit data.');
        this.logger.logError('The backend is currently not returning Credit Limit data.');
      }
    );

    // fetch data for the doughnut chart
    this.creditLimitSubscription = this.dashboardService.getLogisticsCreditLimitInfo().subscribe(
      (data: CreditLimitInfo) => {
        if (!data) {
          this.ui.snackbar('The backend is currently not returning Credit Limit data.');
          this.logger.logError('The backend is currently not returning Credit Limit data.');
          return;
        }
        this.logisticsCreditLimitInfo = data;
        this.creditLimitInfoMap.set("Logistics", this.logisticsCreditLimitInfo);

        this.loadingAvailableCredit=false;

        console.log("getLogisticsCreditLimitInfo; done loading: "+ (!this.loadingTable && !this.loadingAvailableCredit) );

        // force the view to update
        this.ref.detectChanges();

        console.log('DashboardComponent; this.logisticsCreditLimitInfo: ', this.logisticsCreditLimitInfo);
      },
      error => {
        this.ui.snackbar('The backend is currently not returning Credit Limit data.');
        this.logger.logError('The backend is currently not returning Credit Limit data.');
      }
    );
  }

  actionClicked(financeId: number, financeType:string="TradeFinance") {
    this.router.navigate(['update/def'], { queryParams: { finance_id: financeId, financeType:financeType } });
  }

  transactionsClicked(financeId: number, financeType:string="TradeFinance"){
    this.router.navigate(['transaction-history/def'], { queryParams: { finance_id: financeId, financeType:financeType } });
  }

  viewClicked(financeId: number, financeType:string="TradeFinance"){

    console.log("financeId: "+financeId);

    this.router.navigate(['view/def'], { queryParams: { finance_id: financeId, financeType:financeType } });
  }

  calculateBarChartData() {

    let tradeStatuses = [
      {
        "name": "Draft",
        "value": 0,
      },
      {
        "name": "Applied",
        "value": 0,
      },
      {
        "name": "Reverted",
        "value": 0,
      },
      {
        "name": "Inprocess",
        "value": 0,
      },
      {
        "name": "Approved",
        "value": 0,
      },
      {
        "name": "Settled",
        "value": 0,
      }
    ];

    let logisticsStatuses = [
      {
        "name": "Draft",
        "value": 0,
      },
      {
        "name": "Applied",
        "value": 0,
      },
      {
        "name": "Reverted",
        "value": 0,
      },
      {
        "name": "Inprocess",
        "value": 0,
      },
      {
        "name": "Approved",
        "value": 0,
      },
      {
        "name": "Settled",
        "value": 0,
      }
    ];

    for (let i = 0; i < this.finances.length; i++) {
      let finance = this.finances[i];
      if(finance.finance_type == "TradeFinance"){
        if (finance.state === FinanceStates.BCO_FINANCE_SAVED) {
          tradeStatuses[0].value++;
        } else if (finance.state === FinanceStates.BCO_FINANCE_SUBMITTED) {
          tradeStatuses[1].value++;
        } else if (finance.state === FinanceStates.ACCOUNT_SETTLED) {
          tradeStatuses[5].value++;
  
          // we don't show settled on dashboard
          this.finances.splice(i, 1);
          i--;
        } else if (finance.state === FinanceStates.BCO_TERMS_AGREED) {
          tradeStatuses[4].value++;
  
        } else {
          if (finance.state === FinanceStates.FINANCE_CANCELLED ||
            finance.state === FinanceStates.DTF_FINANCE_CLOSED) {
            // we don't show settled on dashboard
            this.finances.splice(i, 1);
            i--;
          } else {
            tradeStatuses[3].value++;
          }
        }
      } else if(finance.finance_type == "LogisticsFinance"){
          if (finance.state === FinanceStates.BCO_FINANCE_SAVED) {
            logisticsStatuses[0].value++;
          } else if (finance.state === FinanceStates.BCO_FINANCE_SUBMITTED) {
            logisticsStatuses[1].value++;
          } else if (finance.state === FinanceStates.ACCOUNT_SETTLED) {
            logisticsStatuses[5].value++;
    
            // we don't show settled on dashboard
            this.finances.splice(i, 1);
            i--;
          } else if (finance.state === FinanceStates.BANKS_FINANCE_ASSESSED) {
            logisticsStatuses[4].value++;
    
          } else {
            if (finance.state === FinanceStates.FINANCE_CANCELLED ||
              finance.state === FinanceStates.DTF_FINANCE_CLOSED) {
              // we don't show settled on dashboard
              this.finances.splice(i, 1);
              i--;
            } else {
              logisticsStatuses[3].value++;
            }
          }
      }
    }   

    this.financeStatus.set("Trade", tradeStatuses);
    this.financeStatus.set("Logistics", logisticsStatuses);
  }

  financeNumClicked(financeId: number, financeType:string="TradeFinance") {

    console.log("financeNumClicked; financeType: "+financeType);

    let address = 'apply/def';
    this.router.navigate([address], { queryParams: { finance: financeId, financeType:financeType  } });
  }

  searchText: string = '';
  searchTextChanged(searchText: string) {
    this.dataSource.filter = searchText;
  }

  importerOrExporter(finance: any) {
    if (finance.importer_id === this.user.organisation_id) {
      return "importer";
    } else {
      return "exporter";
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setFinanceFlag(tradeFinanceFlag: boolean){
    this.isTradeFinance = tradeFinanceFlag;
  }

  ngOnDestroy(){

    // When navigating to a new page/view make sure we don't have a snackbar from the last view still showing,
    // this can confuse the user and will routinely happen when the session is stale and we redirect the user to 
    // login page to relogin, the backend failure snackbar will still be showing
    this.ui.closeCurrentSnackbar();

    if (this.creditLimitSubscription){
      this.creditLimitSubscription.unsubscribe();
    }

    if (this.financesSubscription){
      this.financesSubscription.unsubscribe();
    }
  }
}
