<ul navAccordion class="matero-sidemenu level-0">
  <ng-container *ngFor="let menuItem of menus">

    <ng-container *ngIf="isDevMode() || !menuItem.hideInProd">
      <ng-container *ngIf="!(menuItem.hideForBank && userIsBank)">
        <ng-container *ngIf="!(menuItem.hideForDTF && userIsDTF)">

          <li navAccordionLink class="matero-sidemenu-item" routerLinkActive="active" [group]="menuItem.state">

            <a class="matero-sidemenu-link" [routerLink]="buildState([menuItem.state])" *ngIf="menuItem.type === 'link'"
              style="color: white;" matRipple [matRippleDisabled]="!ripple">
              
              <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>

              <!-- commented out 5-18-2020 -->
              <!--<img draggable="false" [src]="'assets/images/'+menuItem.assetName+'.png'" style="margin-right:20px;" height="20" width="20">-->
              <span class="menu-name">{{menuItem.name}}</span>
              <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
                {{menuItem.label.value}}
              </span>
              <span fxFlex></span>
              <!--
              <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
                {{menuItem.badge.value}}
              </span>
              -->
            </a>
            <a class="matero-sidemenu-link" [href]="menuItem.state" *ngIf="menuItem.type === 'extLink'" matRipple
              [matRippleDisabled]="!ripple">
              <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
              <span class="menu-name">{{menuItem.name}}</span>
              <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
                {{menuItem.label.value}}
              </span>
              <span fxFlex></span>
              <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
                {{menuItem.badge.value}}
              </span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
            <a class="matero-sidemenu-link" [href]="menuItem.state" target="_blank"
              *ngIf="menuItem.type === 'extTabLink'" matRipple [matRippleDisabled]="!ripple">
              <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
              <span class="menu-name">{{menuItem.name}}</span>
              <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
                {{menuItem.label.value}}
              </span>
              <span fxFlex></span>
              <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
                {{menuItem.badge.value}}
              </span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
            <!-- level 1 -->
            <a navAccordionToggle class="matero-sidemenu-toggle" href="javascript:void(0);"
              *ngIf="menuItem.type === 'sub'" style="color: white;" matRipple [matRippleDisabled]="!ripple">
              <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
              <span class="menu-name">{{menuItem.name}}</span>
              <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
                {{menuItem.label.value}}
              </span>
              <span fxFlex></span>
              <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
                {{menuItem.badge.value}}
              </span>
              <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
            </a>
            <ul navAccordion class="matero-sidemenu submenu level-1" *ngIf="menuItem.type === 'sub'">
              <li navAccordionLink class="matero-sidemenu-item" routerLinkActive="active"
                *ngFor="let childLvl1 of menuItem.children" [group]="childLvl1.state">
                <a class="matero-sidemenu-link" [routerLink]="buildState([menuItem.state, childLvl1.state])"
                  *ngIf="childLvl1.type === 'link'" matRipple [matRippleDisabled]="!ripple">
                  <span class="menu-name">{{childLvl1.name}}</span>
                </a>
                <a class="matero-sidemenu-link" [href]="childLvl1.state" *ngIf="childLvl1.type === 'extLink'" matRipple
                  [matRippleDisabled]="!ripple">
                  <span class="menu-name">{{childLvl1.name}}</span>
                  <span fxFlex></span>
                  <mat-icon class="menu-caret">launch</mat-icon>
                </a>
                <a class="matero-sidemenu-link" [href]="childLvl1.state" target="_blank"
                  *ngIf="childLvl1.type === 'extTabLink'" matRipple [matRippleDisabled]="!ripple">
                  <span class="menu-name">{{childLvl1.name}}</span>
                  <span fxFlex></span>
                  <mat-icon class="menu-caret">launch</mat-icon>
                </a>
                <!-- level 2 -->
                <a navAccordionToggle class="matero-sidemenu-toggle" href="javascript:void(0);"
                  *ngIf="childLvl1.type === 'sub'" matRipple [matRippleDisabled]="!ripple">
                  <span class="menu-name">{{childLvl1.name}}</span>
                  <span fxFlex></span>
                  <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
                </a>
                <ul class="matero-sidemenu submenu level-2" *ngIf="childLvl1.type === 'sub'">
                  <li class="matero-sidemenu-item" routerLinkActive="active"
                    *ngFor="let childLvl2 of childLvl1.children">
                    <a class="matero-sidemenu-link"
                      [routerLink]="buildState([menuItem.state, childLvl1.state, childLvl2.state])"
                      *ngIf="childLvl2.type === 'link'" matRipple [matRippleDisabled]="!ripple">
                      <span class="menu-name">{{childLvl2.name}}</span>
                    </a>
                    <a class="matero-sidemenu-link" [href]="childLvl2.state" *ngIf="childLvl2.type === 'extLink'"
                      matRipple [matRippleDisabled]="!ripple">
                      <span class="menu-name">{{childLvl2.name}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">launch</mat-icon>
                    </a>
                    <a class="matero-sidemenu-link" [href]="childLvl2.state" target="_blank"
                      *ngIf="childLvl2.type === 'extTabLink'" matRipple [matRippleDisabled]="!ripple">
                      <span class="menu-name">{{childLvl2.name}}</span>
                      <span fxFlex></span>
                      <mat-icon class="menu-caret">launch</mat-icon>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>