<div>
    <h1 mat-dialog-title style="text-align: center;"> Select an Application </h1>

    <div style="margin: 0px 0 20px 0px; width: 100%;">
        <mat-divider style="width:100%;"></mat-divider>
    </div>

    <div fxLayoutAlign="start center" style="margin: 0 0 20px 0; width:100%;">

        <mat-radio-group aria-label="Select an option" [(ngModel)]="financeType" (change)="financeTypeChanged()"
            style="width: 100%;">

            <div fxLayout="row wrap" fxLayoutGap="20px grid" fxLayoutAlign="center center" style="width: 100%;">

                <div fxFlex="1 1 400px" style="border: grey 1px solid; border-radius: 5px;">
                    <div style="width: 400px; background-color: lightgray; height: 60px;">
                        <mat-radio-button name="radio" value="normal" [checked]="true"
                            style="margin: 20px;">
                            APPLY TRADE FINANCE
                        </mat-radio-button>
                    </div>
                    <div style="width: 400px; height: 500px; padding: 10px;">

                        <span style="font-style: italic;">Avail finance for International cargo movement
                            viz.a.viz Pre-shipment finance,Post-shipment finance
                            and extended payment terms for importer</span>

                        <br><br>
                        <ul>
                            <li>
                                <span style="font-weight:bold">Pre-shipment finance</span>: The finance can be applied by an Importer/Exporter. The exporters
                                will get
                                the amount before shipping the cargo, to prepare buyers’ orders (pre-shipment) and to
                                meet
                                working capital requirements after cargo is shipped till Importer pays.Option available
                                to cover
                                buyer’s default risk with recourse or non-recourse.
                            </li>
                            <li>
                                <span style="font-weight:bold">Post-Shipment finance</span>: The finance can be applied by an Importer/Exporter. The exporters
                                will
                                get the amount after shipping the cargo if the cargo inspection is positive to meet
                                their
                                working capital requirements and maintain cash flow .Option available to cover buyer’s
                                default
                                risk with recourse or non-recourse.
                            </li>
                            <li>
                                <span style="font-weight:bold">Importer Finance</span>: The finance can be applied by the importer. This gives the importer
                                more time
                                to pay for their imports while our platform ensures the exporters are paid in-time.
                            </li>
                        </ul>

                    </div>
                </div>

                <div fxFlex="1 1 20px"></div>

                <div fxFlex="1 1 400px" style="border: grey 1px solid; border-radius: 5px; overflow: hidden;">
                    <div style="width: 400px; background-color: lightgray; height: 60px;">
                        <mat-radio-button value="logistics" style="margin: 20px;">
                            APPLY LOGISTICS FINANCE
                        </mat-radio-button>
                    </div>
                    <div style="width: 400px; height: 500px; padding: 10px;">

                        <span style="font-style: italic;">Avail finance for domestic logistic services viz.a.viz
                            Invoice discounting and Invoice factoring</span>
                        <br><br>
                        <ul>
                            <li>
                                <span style="font-weight:bold">Invoice Factoring</span>: Factoring is a type of short-term accounts receivable financing,
                                where you effectively ‘sell’ your outstanding invoices to a third-party commercial
                                finance company.
                                The sellers gets the cash basis the commercial terms agreed.
                                The liability is with Bank to get the money, hence the seller applies without recourse.
                            </li>
                            <li>
                                <span style="font-weight:bold">Invoice Discounting</span>: Invoice discounting is a form of short-term borrowing against your
                                outstanding invoices. Risk/Liability is with the seller. With invoice discounting, you
                                maintain responsibility for your sales ledger, payment chasing, and invoice processing.
                                In other words, Invoice discounting is with recourse.
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

        </mat-radio-group>
    </div>


</div>