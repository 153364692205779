import * as i0 from "@angular/core";
var ConstantsService = /** @class */ (function () {
    function ConstantsService() {
        // this is the mock backend they setup
        this.baseURL = 'http://18.191.0.111:3007';
        this.directories = {
            // dashboard stuff
            'transactions': '/transactions',
            'finances': '/finances',
            'creditLimit': '/creditLimit',
            'notifications': '/:user/notifications',
            'updateNotifications': '/update/readstatus' // POST
        };
        this.terminals = [
            "Seattle",
            "Dubai",
            "Shanghi",
        ];
        this.proofTypes = [
            "Type1",
            "Type2",
            "Type3",
        ];
        this.banks = [
            "Bank1",
            "Bank2"
        ];
        this.currencys = [
            "USD",
            "AED",
        ];
        this.transactionTypes = [
            "Account Settled",
        ];
        this.statuses = [
            "Cargo inspected positive",
            "Bill of Lading submitted",
            "Vessel departed"
        ];
        this.friendlyStateNames = {
            BCO_FINANCE_SAVED: 'Draft',
            BCO_FINANCE_SUBMITTED: 'In Process',
            BCO_FINANCE_ACCEPTED: 'In Process',
            DTF_FINANCE_ACCEPTED: 'In Process',
            BANKS_FINANCE_ASSESSED: 'In Process',
            BCO_TERMS_AGREED: 'Approved',
            // dynamic shipping events
            EXPORTER_PRODUCTION_COMPLETED: 'Funded',
            CARGO_READY_TO_SHIP: 'Funded',
            CARGO_GATED_IN_PORT: 'Funded',
            CARGO_RECEIVED_AT_YARD: 'Funded',
            CARGO_INSPECTED_ORIGINPORT: 'Funded',
            CARGO_LOADED: 'Funded',
            VESSEL_DEPARTED: 'Funded',
            BANK_FUNDS_TRANSFERRED: 'Funded',
            ACCOUNT_SETTLED: 'Settled',
            DTF_FINANCE_CLOSED: 'Settled',
            FINANCE_CANCELLED: 'Settled' // Account Settled
        };
        // used by state drop downs
        this.friendlyStateNamesLong = {
            "PURCHASE_ORDER_ACCEPTED": "purchase order accepted",
            "PROFORMA_INVOICE_ACCEPTED": "proforma invoice accepted",
            "EXPORTER_PRODUCTION_COMPLETED": "exporter production completed",
            "CARGO_READY_TO_SHIP": "cargo ready to ship",
            "CARGO_GATED_IN_ORIGIN_PORT": "cargo gated in origin port",
            "CARGO_RECEIVED_ORIGIN_YARD": "cargo received origin yard",
            "CARGO_INSPECTED_ORIGIN_PORT": "cargo inspected origin port",
            "CARGO_LOADED_ORIGIN_PORT": "cargo loaded origin port",
            "VESSEL_DEPARTED_ORIGIN_PORT": "vessel departed origin port",
            "VESSEL_ARRIVED_DESTINATION_PORT": "vessel arrived destination port",
            "CARGO_UNLOADED_DESTINATION_YARD": "cargo unloaded destination yard",
            "CARGO_CLEARED_IMPORTS_DESTINATION_PORT": "cargo cleared imports destination port",
            "CARGO_INSPECTED_DESTINATION_PORT": "cargo inspected destination port",
            "CARGO_RELEASE_GENERATED_DESTINATION_PORT": "cargo released generated destination port",
            "CARGO_GATED_OUT_DESTINATION_PORT": "cargo gated out destination port",
            "CARGO_RECEIVED_FREIGHT_FORWARDER": "cargo recieved freight forwarder",
            "CARGO_LOADED_DESTINATION_TRUCK": "cargo loaded destination truck",
            "CARGO_RECEIVED_IMPORTER_FACILITY": "cargo received importer facility"
        };
        this.applicantTypes = [
            'importer',
            'exporter',
        ];
        // used by tables in Dashboard and Applications
        this.financeTypeDisplayName = {
            TradeFinance: 'Trade',
            LogisticsFinance: 'Logistics'
        };
    }
    ConstantsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConstantsService_Factory() { return new ConstantsService(); }, token: ConstantsService, providedIn: "root" });
    return ConstantsService;
}());
export { ConstantsService };
