import { Observable } from 'rxjs';
import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { Transaction } from './dashboard.models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FinanceType } from '../apply/apply-models';
import * as i0 from "@angular/core";
import * as i1 from "../../dp-world/services/static-data.service";
import * as i2 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(staticData, http) {
        this.staticData = staticData;
        this.http = http;
    }
    /*
    headers = new HttpHeaders({
      'X-User-Id': 'cf1e612e47663b9be6bd3fe1962b8a529846073f190905a86feb29ae7ad719b7',
      'X-DPW-User-Token':
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwMDAwMDA4LCJpYXQiOjE1ODY1MDU2MzgsImV4cCI6MTU4NjU0ODgzOH0.15GwzXG8n3JOnJycJksq4pn1DokHA2FzyPQxXgB5KYM',
    });*/
    DashboardService.prototype.getTransactions = function () {
        //return of(this.staticData.transactions);
        var url = environment.rootUrl + environment.urls.get_transactions;
        return this.http.get(url);
    };
    /**
     * Used by Dashboard and Applications. Applications passes dates, Dashboard doesn't.
     * @param from
     * @param to
     */
    DashboardService.prototype.getFinances = function (from, to) {
        if (from === void 0) { from = ''; }
        if (to === void 0) { to = ''; }
        var url = environment.rootUrl + environment.urls.get_finance;
        // if we have a date range, use it
        if (from != '' && to != '') {
            url += '&created_at_from=' + from + '&created_at_to=' + to;
        }
        return this.http.get(url);
    };
    DashboardService.prototype.markNotificationsRead = function () {
        var url = environment.rootUrl + environment.urls.notifications_read;
        return this.http.get(url);
    };
    // 4-7 working, but no mock data.
    DashboardService.prototype.getTradeCreditLimitInfo = function () {
        //return of(this.staticData.creditLimit2);
        var url = environment.rootUrl + environment.urls.trade_credit_limit;
        return this.http.get(url);
    };
    DashboardService.prototype.getLogisticsCreditLimitInfo = function () {
        //return of(this.staticData.creditLimit2);
        var url = environment.rootUrl + environment.urls.logistics_credit_limit;
        return this.http.get(url);
    };
    ////////////// API Integrations //////////////////
    // TODO: need to shift this apis where ever required
    // GET /organisations
    // params: org_name (search keys), org_type, _limit, _page
    DashboardService.prototype.getOrganisations = function (queries) {
        // url and path
        var url = environment.rootUrl + environment.urls.get_companies;
        // Add query params
        var queryparams = new HttpParams();
        queries.forEach(function (query) {
            queryparams = queryparams.append(Object.keys(query)[0], query[Object.keys(query)[0]]);
        });
        //return this.http.get(url, { headers: this.headers, params: queryparams });
        return this.http.get(url, { params: queryparams });
    };
    // POST /trade-finance/save
    // Formdata in body.
    DashboardService.prototype.saveFinance = function (formData, id, financeType) {
        if (id === void 0) { id = ''; }
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            console.log('submitNewFinance; this is an existing finance! ');
            url += id + "/";
        }
        url += 'save';
        console.log("submitNewFinance; url: " + url);
        return this.http.post(url, formData);
    };
    /*
    // 3. PUT /trade-finance/:id/save
    saveExistingFinance(formData: FormData, financeId: number) {
      // TODO: customize path from environment to add finance_id
      // const url = environment.rootUrl + environment.urls.save_finance_by_id;
      const url = environment.rootUrl + `finance/${financeId}/save`;
      //return this.http.put(url, formData, { headers: this.headers });
      return this.http.put(url, formData);
    }*/
    // POST /trade-finance/submit
    DashboardService.prototype.submitNewFinance = function (formData, id, financeType) {
        if (id === void 0) { id = ''; }
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            console.log('submitNewFinance; this is an existing finance! ');
            url += id + "/";
        }
        url += 'submit';
        console.log("submitNewFinance; url: " + url);
        return this.http.post(url, formData);
    };
    /*
    // 5. PUT /trade-finance/:id/submit
    submitExistingFinance(formData: FormData, financeId: number) {
      // TODO: customize path from environment to add finance_id
      // const url = environment.rootUrl + environment.urls.save_finance_by_id;
  
      const url = environment.rootUrl + `trade-finance/${financeId}/submit`;
      //return this.http.put(url, formData, { headers: this.headers });
      return this.http.put(url, formData);
    }*/
    // GET /trade-finance/:id
    DashboardService.prototype.getFinanceById = function (id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        url += id;
        return this.http.get(url);
    };
    // PUT /trade-finance/:id/other-bco-approval
    DashboardService.prototype.otherBCOApproveFinance = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'other-bco-approval';
        return this.http.put(url, payload);
    };
    // PUT /trade-finance/:id/dtf-admin-approval
    DashboardService.prototype.dtfAdminApproveFinance = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'dtf-admin-approval';
        return this.http.put(url, payload);
    };
    // PUT /trade-finance/:id/bank-assessment
    DashboardService.prototype.bankAssessment = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'bank-assessment';
        return this.http.put(url, payload);
    };
    // PUT /trade-finance/:id/accept-payment-terms
    DashboardService.prototype.acceptTerms = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'accept-payment-terms';
        return this.http.put(url, payload);
    };
    // PUT /trade-finance/:id/update-shipment-event
    DashboardService.prototype.updateShipmentEvents = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'update-shipment-event';
        return this.http.put(url, payload);
    };
    // PUT /trade-finance/:id/cancel
    DashboardService.prototype.cancelFinance = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'cancel';
        return this.http.put(url, payload);
    };
    // called by owner BCO
    // PUT /trade-finance/:id
    DashboardService.prototype.deleteFinance = function (payload, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id;
        }
        return this.http.delete(url, payload);
    };
    // POST /trade-finance/:id/transaction
    DashboardService.prototype.createTransaction = function (formData, id, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (id != '') {
            url += id + "/";
        }
        url += 'transaction';
        return this.http.post(url, formData);
    };
    DashboardService.prototype.inviteOrganization = function (request) {
        var url = environment.rootUrl + 'organisation/invite';
        return this.http.post(url, request);
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.StaticDataService), i0.ɵɵinject(i2.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
