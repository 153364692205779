import { CurrencyPipe } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var PipeUtilsService = /** @class */ (function () {
    function PipeUtilsService(_currencyPipe) {
        this._currencyPipe = _currencyPipe;
    }
    /**
     *
     * @param value
     * @param currencyType
     */
    PipeUtilsService.prototype.fixedCurrency = function (value, currencyType, saveTo) {
        //console.log("fixedCurrency; value: "+value);
        //console.log("fixedCurrency; typeof value: "+ typeof value);
        if (saveTo === void 0) { saveTo = null; }
        if (saveTo) {
            saveTo.setValue(value);
        }
        if (!isNaN(value)) {
            return this._currencyPipe.transform(value, currencyType, 'symbol', '1.0-0');
        }
        else {
            return value;
        }
    };
    PipeUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PipeUtilsService_Factory() { return new PipeUtilsService(i0.ɵɵinject(i1.CurrencyPipe)); }, token: PipeUtilsService, providedIn: "root" });
    return PipeUtilsService;
}());
export { PipeUtilsService };
