import { commonEnvironment } from './environment.common';

// Todo determine the branch
// Staging or Master
// then update rootUrl accordingly

let gitBranch = 'staging';
let rootUrl = '//staging-api.revma.dpworld.com:8089/';

// what was this?
// @ts-ignore
if (gitBranch === 'master') {
  rootUrl = '//prod-api.revma.dpworld.com:8089/';
}

export const environment = {
  ...commonEnvironment, // add our common variables
  SERVER_URL: '',
  production: true,
  useHash: true,
  hmr: false,
  rootUrl: rootUrl,
};
