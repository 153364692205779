import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { AuthGuard } from './sessions/login/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard', canActivate: [AuthGuard],
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'sessions', canActivate: [AuthGuard],
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Sessions', titleI18n: 'Sessions' },
      },
  { 
    path: 'apply', canActivate: [AuthGuard], runGuardsAndResolvers: 'always',
    loadChildren: () => import('./apply/apply.module').then(m => m.ApplyModule) },
  { 
    path: 'transaction-history', canActivate: [AuthGuard], loadChildren: () => import('./transaction-history/transaction-history.module').then(m => m.TransactionHistoryModule) },
  { path: 'settings', canActivate: [AuthGuard], loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'status', canActivate: [AuthGuard], loadChildren: () => import('./status/status.module').then(m => m.StatusModule) },
  { path: 'update', canActivate: [AuthGuard], loadChildren: () => import('./update/update.module').then(m => m.UpdateModule) },
  { path: 'faq', canActivate: [AuthGuard], loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'view', canActivate: [AuthGuard], loadChildren: () => import('./view/view.module').then(m => m.ViewModule) },
  { path: 'error-handling', canActivate: [AuthGuard], loadChildren: () => import('./error-handling/error-handling.module').then(m => m.ErrorHandlingModule) },
  { path: 'logistics-finance', canActivate: [AuthGuard], loadChildren: () => import('./logistics-finance/logistics-finance.module').then(m => m.LogisticsFinanceModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login', titleI18n: 'Login' },
      },
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register', titleI18n: 'Register' },
      },
    ],
  },
  { path: '**', canActivate: [AuthGuard], redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
