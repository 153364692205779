import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { UiService } from 'app/dp-world/services/ui.service';

@Component({
  selector: 'app-upload-wrapper',
  templateUrl: './upload-wrapper.component.html',
  styleUrls: ['./upload-wrapper.component.scss']
})

/**
 * Wraps https://www.npmjs.com/package/ngx-file-drop 
 * We use this for uploading files on many different views.
 */
export class UploadWrapperComponent implements OnInit {

  @Output() fileUploaded = new EventEmitter<NgxFileDropEntry[]>();
  @Input() supportedFileTypes:string = "pdf, doc, docx, png, jpg, xls, csv, pptx, xlsx, zip"; 

  public file: NgxFileDropEntry = null;

  constructor(private ui:UiService) { }

  ngOnInit() { }

  fileDropped(files: NgxFileDropEntry[]){

    console.log('File dropped or selected: ', files);
    if (files.length > 1) {
      this.ui.snackbar('Only 1 file should be uploaded.');
      return;
    }

    let droppedFile = files[0];

    let fileEntry = null;

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      this.file = files[0];
      fileEntry = this.file.fileEntry as FileSystemFileEntry;
      console.log('fileEntry', fileEntry);
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      fileEntry = this.file.fileEntry as FileSystemDirectoryEntry;
      console.log(this.file.relativePath, fileEntry);
    }

    console.log("fileDropped;");
    this.fileUploaded.emit(fileEntry);
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
}
