import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-step-explain',
  templateUrl: './step-explain.component.html',
  styleUrls: ['./step-explain.component.scss']
})

// just css/html for the explain section, no logic.
export class StepExplainComponent implements OnInit {
  @Input() financeType:string='Trade';

  constructor() { }
  ngOnInit() {

  }
}
