<div style="padding: 20px;">

  <div style="width: 100%;" fxLayoutAlign="center center">
    <h2>
      DP World Trade Finance
    </h2>
  </div>
  <div class="w-full h-full" fxLayout="row wrap" fxLayoutAlign="center center">

    <div fxShow fxHide.lt-lg="true" style="background-image: url('assets/images/login_BG.png');
  background-repeat: no-repeat;
  background-size: cover !important; width: 852px; height:613px;">
    </div>

    <mat-card class="mat-elevation-z4 m-b-0" style="width: 740px;">
      <div style="margin-bottom: 30px;">
        <mat-card-title style="font-size: 18px; color:#173A64;">Register with us</mat-card-title>
        <div style="color:grey; font-size: 12px;">Welcome to the Finance Portal!</div>
      </div>
      <mat-card-content>
        <form class="matero-form-horizontal" [formGroup]="reactiveForm">


          <mat-radio-group name="delivery_service" formControlName="delivery_service">
            <div class="col-md-12" style="height: 40px;">
              <label for="delivery_service" class="col-md-12">Account Type: </label>
              <mat-radio-button value="Importer" [checked]="true">Importer
              </mat-radio-button>
              <mat-radio-button value="Exporter" class="offset-md-1">Exporter</mat-radio-button>
              <mat-radio-button value="Exporter" class="offset-md-1">Freight Forwarder</mat-radio-button>
              <mat-radio-button value="Exporter" class="offset-md-1">Bank</mat-radio-button>
            </div>
          </mat-radio-group>


          <div style="width: 100%;">
            <mat-form-field appearance="outline" style="width: 350px;">
              <mat-label>Username</mat-label>
              <input autocomplete="new-password" matInput placeholder="Username" formControlName="username" required>
              <mat-error *ngIf="reactiveForm.get('username').invalid">Please enter username</mat-error>
            </mat-form-field>
            <div style="width: 370px;"></div>
          </div>

          <div style="width: 100%;" fxLayout="row">
            <mat-form-field appearance="outline" style="width: 350px;">
              <mat-label>Last Name</mat-label>
              <input autocomplete="new-password" matInput placeholder="Last Name" formControlName="lastName" required>
              <mat-error *ngIf="reactiveForm.get('lastName').invalid">Please enter your last name</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 350px; margin-left: 20px;">
              <mat-label>First Name</mat-label>
              <input autocomplete="new-password" matInput placeholder="First Name" formControlName="firstName" required>
              <mat-error *ngIf="reactiveForm.get('firstName').invalid">Please enter your first name</mat-error>
            </mat-form-field>
          </div>

          <div style="width: 100%;" fxLayout="row">
            <mat-form-field appearance="outline" style="width: 350px;">
              <mat-label>Country</mat-label>
              <input autocomplete="new-password" matInput placeholder="Country" formControlName="country" required>
              <mat-error *ngIf="reactiveForm.get('country').invalid">Please enter your country</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 350px; margin-left: 20px;">
              <mat-label>Phone No</mat-label>
              <input autocomplete="new-password" matInput placeholder="phoneNumber" formControlName="phoneNumber"
                required>
              <mat-error *ngIf="reactiveForm.get('phoneNumber').invalid">Please enter your Phone Number</mat-error>
            </mat-form-field>
          </div>

          <div style="width: 100%;" fxLayout="row">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Email</mat-label>
              <input autocomplete="new-password" matInput placeholder="Email" formControlName="email" required>
              <mat-error *ngIf="reactiveForm.get('email').invalid">Please enter your email</mat-error>
            </mat-form-field>
          </div>


          <div style="width: 100%;" fxLayout="row">
            <mat-form-field appearance="outline" style="width: 350px;">
              <mat-label>Password</mat-label>
              <input autocomplete="new-password" matInput placeholder="Password" type="password"
                formControlName="password" required>
              <mat-error *ngIf="reactiveForm.get('password').invalid">Please enter password</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 350px; margin-left: 20px;">
              <mat-label>Confirm Password</mat-label>
              <input autocomplete="new-password" matInput placeholder="Confirm Password" type="password"
                formControlName="confirmPassword" required>
              <mat-error *ngIf="reactiveForm.get('confirmPassword').hasError('required')">Please confirm
                your password!
              </mat-error>
              <mat-error *ngIf="reactiveForm.get('confirmPassword').hasError('confirm')">Password is
                inconsistent!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-checkbox>I have read and agree to the terms of service.</mat-checkbox>
          </div>

          <div style="width: 100%; display:inline-block;" fxLayout="row">
            <button class="w-full" mat-raised-button color="primary" (click)="register()"
              style="width: 90px !important;">Register</button>

            <div class="m-t-16" style="margin-left: auto; margin-top: 0; width: 230px;">Already have an account? <a
                routerLink="/auth/login">Sign in</a></div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>