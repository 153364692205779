import { OnInit } from '@angular/core';
import { ConstantsService } from 'app/dp-world/services/constants.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ApplyService } from '../apply.service';
import { LogService } from 'app/dp-world/services/log.service';
import { UiService } from 'app/dp-world/services/ui.service';
import { of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SharedDataService } from 'app/dp-world/services/shared-data.service';
import { DashboardService } from 'app/routes/dashboard/dashboard.service';
import { DocumentService } from '@core/services/document.service';
import { AuthService } from 'app/routes/sessions/login/auth.service';
import { PipeUtilsService } from 'app/dp-world/services/pipe-utils.service';
import { UtilsService } from 'app/dp-world/services/utils.service';
import { FinanceType } from '../apply-models';
import { MatDialog } from '@angular/material';
var FinanceRequestFormComponent = /** @class */ (function () {
    function FinanceRequestFormComponent(_formBuilder, constants, applyService, logger, ui, sharedData, dashboardService, documentService, authService, pipeUtils, utils, dialog) {
        var _this = this;
        this._formBuilder = _formBuilder;
        this.constants = constants;
        this.applyService = applyService;
        this.logger = logger;
        this.ui = ui;
        this.sharedData = sharedData;
        this.dashboardService = dashboardService;
        this.documentService = documentService;
        this.authService = authService;
        this.pipeUtils = pipeUtils;
        this.utils = utils;
        this.dialog = dialog;
        this.form = null;
        this.hideTradeFinanceSection = false;
        this.finance = null;
        this.hideApplyAs = false;
        this.showFileUpload = true;
        this.shared = null;
        this.endorseColumn = false;
        this.financeType = FinanceType.Trade;
        this.percentageLimitExceeded = false;
        this.file = null;
        this.cargoFile = null;
        this.endorseFile = null;
        this.options = [];
        // used by toCountry and fromCountry
        this.locations = null;
        this.incoterms = [];
        this.banks = null;
        this.proofTypes = [];
        this.currencies = [];
        // old from static data, probably deprecated
        this.events = [];
        this.importerEvents = [];
        this.exporterEvents = [];
        this.parties = ['IMPORTER', 'EXPORTER'];
        this.recourseParties = ['IMPORTER', 'EXPORTER', 'NONE'];
        this.discountRates = ['0.075%', '0.05%', '0.1%'];
        this.showCargoDetailsFileUpload = false;
        this.totalAmountEditable = false;
        this.applyAsImporter = false;
        // TODO: the form isn't allowing us to assign a calculated value, it shows up, but then can't be retrieved from form
        // so this is a hack to get around that.
        this.totalAmount = 0;
        this.requestedAmount = 0;
        this.currDate = new Date();
        this.oneYearBelowDate = new Date();
        this.oneYearAboveDate = new Date();
        // used by the autocompletes, what they actually databind too, result after main list has been filtered.
        this.toCountryFilteredOptions = [];
        this.fromCountryFilteredOptions = [];
        this.toPortFilteredOptions = [];
        this.fromPortFilteredOptions = [];
        this.toCitiesFilteredOptions = [];
        this.fromCitiesFilteredOptions = [];
        this.fromLabel = "From City";
        this.toPorts = [];
        this.toCities = [];
        this.selectedToCity = null;
        this.selectedToCountry = null;
        this.fromPorts = [];
        this.fromCities = [];
        this.selectedFromCity = null;
        this.selectedFromCountry = null;
        this.currentAutoCompleteValue = '';
        this.updateOptionsTimeout = null;
        this.percentageErrorMatcher = {
            isErrorState: function (control, form) {
                var totalPercentage = 0;
                var eventDetailsFormGroup = _this.eventDetailsFormArray.controls;
                eventDetailsFormGroup.forEach(function (eventDetails) {
                    var paymentPercentage = eventDetails.get("paymentPercentage").value;
                    totalPercentage += parseFloat(paymentPercentage) || 0;
                });
                if (totalPercentage > 100) {
                    return true;
                }
                return false;
            },
        };
        this.settlementPercentageErrorMatcher = {
            isErrorState: function (control, form) {
                var totalPercentage = 0;
                var settlementEventDetailsFormGroup = _this.settlementEventDetailsFormArray.controls;
                settlementEventDetailsFormGroup.forEach(function (settlementEventDetails) {
                    var settlementPercentage = settlementEventDetails.get("settlementPercentage").value;
                    totalPercentage += parseFloat(settlementPercentage) || 0;
                });
                if (totalPercentage > 100) {
                    return true;
                }
                return false;
            },
        };
        this.logisticsPercentageErrorMatcher = {
            isErrorState: function (control, form) {
                var totalPercentage = 0;
                var logisticsDetailsFormGroup = _this.logisticsDetailsFormArray.controls;
                logisticsDetailsFormGroup.forEach(function (logisticsDetails) {
                    var paymentPercentage = logisticsDetails.get("paymentPercentage").value;
                    totalPercentage += parseFloat(paymentPercentage) || 0;
                });
                if (totalPercentage > 100) {
                    return true;
                }
                return false;
            },
        };
    }
    FinanceRequestFormComponent.prototype.ngOnInit = function () {
        // disable these text boxes because they are calulated fields
        var _this = this;
        this.form.get('amount').disable();
        this.form.get('totalAmount').disable();
        this.form.get('requestedAmount').disable();
        if (this.financeType == FinanceType.Logistics) {
            this.form.get('discountRate').disable();
            this.form.get('receivableAmount').disable();
        }
        this.user = this.authService.currentUserValue;
        this.filteredOptions = this.form.get('importer').valueChanges.pipe(startWith(''), map(function (value) { return (typeof value === 'string' ? value : value.name); }), map(function (name) { return (name ? _this._filter(name) : _this.options.slice()); }));
        this.locations = this.shared.countryPortsAndCities;
        this.oneYearBelowDate.setFullYear(this.oneYearBelowDate.getFullYear() - 1);
        this.oneYearAboveDate.setFullYear(this.oneYearAboveDate.getFullYear() + 1);
        // fill in our autocomplete values
        this.toCountryFilteredOptions = this.locations;
        this.fromCountryFilteredOptions = this.locations;
        this.incoterms = this.shared.supportedIncoTerms;
        this.proofTypes = this.shared.supportedProofTypes;
        this.currencies = this.shared.supportedCurrencies;
        this.banks = this.shared.supportedBanks;
        this.importerEvents = this.shared.supportedImporterShipmentEvents;
        this.exporterEvents = this.shared.supportedExporterShipmentEvents;
        if (this.finance.documentsDetails) {
            var documentDetails = this.finance.documentsDetails;
            for (var i = 0; i < documentDetails.length; i++) {
                if (documentDetails[i].document_type === "cargo_details") {
                    this.totalAmountEditable = true;
                }
            }
        }
    };
    FinanceRequestFormComponent.prototype.selectedToCountryChanged = function () {
        console.log('selectedToCountryChanged;');
        var to = this.form.get('toCountry');
        if (to && to.value) {
            this.toCities = to.value.cities;
            this.toPorts = to.value.ports;
            this.toCitiesFilteredOptions = this.toCities;
            this.toPortFilteredOptions = this.toPorts;
        }
    };
    FinanceRequestFormComponent.prototype.selectedFromCountryChanged = function () {
        console.log('selectedFromCountryChanged;');
        var from = this.form.get('fromCountry');
        if (from && from.value) {
            this.fromCities = from.value.cities;
            this.fromPorts = from.value.ports;
            this.fromCitiesFilteredOptions = this.fromCities;
            this.fromPortFilteredOptions = this.fromPorts;
        }
    };
    /**
     * called when users enter a new character in a search box.
     * @param $event
     * @param options
     * @param type
     */
    FinanceRequestFormComponent.prototype.applyFilter = function ($event, options, type) {
        // filter all options by the search text.
        var filter = $event.target.value;
        var filterValue = filter.toLowerCase();
        var filtered = options.filter(function (option) { return option.toLowerCase().indexOf(filterValue) === 0; });
        switch (type) {
            case 'toCity':
                this.toCitiesFilteredOptions = filtered;
            case 'fromCity':
                this.fromCitiesFilteredOptions = filtered;
            case 'toPort':
                this.toPortFilteredOptions = filtered;
            case 'fromPort':
                this.fromPortFilteredOptions = filtered;
        }
    };
    /**
     * Called when users enter a new character in the Country search box.
     * @param $event
     * @param options
     * @param type
     */
    FinanceRequestFormComponent.prototype.applyCountryFilter = function ($event, options, type) {
        // filter all options by the search text
        var filter = $event.target.value;
        var filterValue = filter.toLowerCase();
        var filtered = options.filter(function (option) { return option.country_name.toLowerCase().indexOf(filterValue) === 0; });
        switch (type) {
            case 'toCountry':
                this.toCountryFilteredOptions = filtered;
            case 'fromCountry':
                this.fromCountryFilteredOptions = filtered;
        }
    };
    Object.defineProperty(FinanceRequestFormComponent.prototype, "cargoDetailsFormArray", {
        get: function () {
            return this.form.get('cargoDetails');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FinanceRequestFormComponent.prototype, "eventDetailsFormArray", {
        get: function () {
            return this.form.get('eventDetails');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FinanceRequestFormComponent.prototype, "settlementEventDetailsFormArray", {
        get: function () {
            return this.form.get('settlementEventDetails');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FinanceRequestFormComponent.prototype, "logisticsDetailsFormArray", {
        get: function () {
            return this.form.get('logisticsDetails');
        },
        enumerable: true,
        configurable: true
    });
    FinanceRequestFormComponent.prototype._filter = function (name) {
        var filterValue = name.toLowerCase();
        return this.options.filter(function (option) { return option.full_name.toLowerCase().indexOf(filterValue) === 0; });
    };
    FinanceRequestFormComponent.prototype.displayCountryFn = function (obj) {
        if (obj) {
            return obj.country_name;
        }
        else {
            return '';
        }
    };
    // TODO: why is full_name missing now?
    FinanceRequestFormComponent.prototype.displayFn = function (obj) {
        if (obj && obj.full_name) {
            return obj.full_name;
        }
        return null;
    };
    /**
     * called when user enters a key in importer or exporter search boxes.
     * @param $event
     */
    FinanceRequestFormComponent.prototype.onKey = function ($event) {
        var _this = this;
        console.log('onKey;' + $event.target.value);
        this.currentAutoCompleteValue = $event.target.value;
        clearTimeout(this.updateOptionsTimeout);
        this.updateOptionsTimeout = setTimeout(function () {
            _this.getCompanyOptions(_this.currentAutoCompleteValue);
        }, 300);
    };
    /**
     * actually calls the backend to retrieve all the companies that match the search text.
     * @param searchText
     */
    FinanceRequestFormComponent.prototype.getCompanyOptions = function (searchText) {
        var _this = this;
        // if the user just deleted their search text, no point in searching
        if (searchText == '') {
            return;
        }
        var queryParams = [{ org_type: 'ORG' }, { _limit: 20 }, { org_name: searchText }];
        this.dashboardService.getOrganisations(queryParams).subscribe(function (data) {
            if (!data) {
                _this.logger.logError('The backend is not currently returning autocomplete options.');
                _this.ui.snackbar('The backend is not currently returning autocomplete options.');
                return;
            }
            _this.options = data;
            // make the filtering of autocomplete update.
            _this.filteredOptions = of(_this._filter(_this.currentAutoCompleteValue));
        }, function () {
            _this.logger.logError('The backend is not currently returning autocomplete options.');
            _this.ui.snackbar('The backend is not currently returning autocomplete options.');
        });
    };
    /**
     * Called by dragging and dropping a file in the form.
     * @param files
     */
    FinanceRequestFormComponent.prototype.dropped = function (fileEntry) {
        console.log('File dropped or selected: ', fileEntry);
        var fileControl = this.form.get('file');
        if (fileControl) {
            fileEntry.file(function (file) {
                fileControl.setValue(file);
                console.log('fileControl', fileControl);
            });
        }
    };
    /**
     * On Trading Partner Review step, this is called when you drop a document in the drag and drop
     * @param fileEntry
     */
    FinanceRequestFormComponent.prototype.droppedEndorseFile = function (fileEntry) {
        var fileControl = this.form.get('endorseFile');
        if (fileControl) {
            fileEntry.file(function (file) {
                fileControl.setValue(file);
                console.log('fileControl', fileControl);
            });
        }
    };
    FinanceRequestFormComponent.prototype.droppedCargoFile = function (fileEntry) {
        console.log('droppedCargoFile; File dropped or selected: ', fileEntry);
        var fileControl = this.form.get('cargoFile');
        if (fileControl) {
            fileEntry.file(function (file) {
                fileControl.setValue(file);
                console.log('fileControl', fileControl);
            });
        }
    };
    FinanceRequestFormComponent.prototype.openFileSelector = function () {
        if (!this.canEdit) {
            return;
        }
    };
    FinanceRequestFormComponent.prototype.fileOver = function (event) {
        console.log(event);
    };
    FinanceRequestFormComponent.prototype.fileLeave = function (event) {
        console.log(event);
    };
    FinanceRequestFormComponent.prototype.getProofTypeDisabled = function (proof) {
        return (proof == this.form.get('proofType').value);
    };
    /**
     * Called when the user selects the importer/exporter radio button. automatically fills the importer/exporter fields below
     */
    FinanceRequestFormComponent.prototype.importerExporterChanged = function () {
        var _this = this;
        // determine if the user is importer or exporter
        var weAre = this.form.get('BCOType').value;
        var orgName = this.user.organisation_name;
        var queryParams = [{ org_type: 'ORG' }, { _limit: 20 }, { org_name: orgName }];
        this.dashboardService.getOrganisations(queryParams).subscribe(function (data) {
            var org = data[0];
            if (weAre == 'importer') {
                _this.applyAsImporter = true;
                // auto fill 
                _this.form.get('importer').setValue(org);
                // wipe potential old selection
                _this.form.get('exporter').setValue(null);
            }
            else if (weAre == 'exporter') {
                _this.applyAsImporter = false;
                // auto fill
                _this.form.get('exporter').setValue(org);
                // wipe potential old selection
                _this.form.get('importer').setValue(null);
            }
        });
    };
    /**
     * called when someone selects to upload a file rather than fill in cargoDetails.
     */
    FinanceRequestFormComponent.prototype.showCargoFileUploadOption = function () {
        this.showCargoDetailsFileUpload = !this.showCargoDetailsFileUpload;
        this.totalAmountEditable = !this.totalAmountEditable;
        this.showCargoDetailsFileUpload ? this.form.get('totalAmount').enable() : this.form.get('totalAmount').disable();
    };
    FinanceRequestFormComponent.prototype.addEvent = function () {
        if (this.eventDetailsFormArray.length <= 2) {
            var eventDetails = new FormGroup({
                paymentEvent: new FormControl(''),
                daysAfterDisbursalEvent: new FormControl(''),
                paymentPercentage: new FormControl(''),
                interestBearer: new FormControl(''),
                recourse: new FormControl(''),
            });
            this.eventDetailsFormArray.push(eventDetails);
        }
        else {
            this.ui.snackbar("You can only have 3 details.");
        }
    };
    FinanceRequestFormComponent.prototype.addSettlementEvent = function () {
        if (this.settlementEventDetailsFormArray.length <= 2) {
            var eventDetails = new FormGroup({
                settlementEvent: new FormControl(''),
                daysAfterSettlementEvent: new FormControl(''),
                settlementPercentage: new FormControl(''),
            });
            this.settlementEventDetailsFormArray.push(eventDetails);
        }
        else {
            this.ui.snackbar("You can only have 3 settlement details.");
        }
    };
    FinanceRequestFormComponent.prototype.removeEvent = function (index) {
        this.eventDetailsFormArray.removeAt(index);
    };
    FinanceRequestFormComponent.prototype.removeSettlementEvent = function (index) {
        this.settlementEventDetailsFormArray.removeAt(index);
    };
    FinanceRequestFormComponent.prototype.addCargo = function () {
        if (this.cargoDetailsFormArray.length <= 4) {
            var group = new FormGroup({
                cargoDescription: new FormControl(''),
                quantity: new FormControl(''),
                unitPrice: new FormControl(''),
            });
            this.cargoDetailsFormArray.push(group);
        }
        else {
            this.ui.snackbar("You can only have 5 cargo details.");
        }
    };
    FinanceRequestFormComponent.prototype.removeCargo = function (index) {
        this.cargoDetailsFormArray.removeAt(index);
    };
    // sets to int value since we display as string
    FinanceRequestFormComponent.prototype.updatePrice = function (unitPriceHolder, item) {
        item.get('unitPriceHolder').setValue(unitPriceHolder);
    };
    FinanceRequestFormComponent.prototype.getAmount = function (unitPrice, quantity, cargoDetails) {
        //let amount = this.utils.currencyStringToNumber(unitPrice) * quantity;
        var amount = unitPrice * quantity;
        return amount.toFixed(2);
    };
    FinanceRequestFormComponent.prototype.getTotalAmount = function () {
        var totalAmount = 0;
        // this scenario must be when they uploaded the file
        if (this.totalAmountEditable) {
            var total = this.form.get("totalAmount").value;
            if (total) {
                console.log("total: " + total);
                totalAmount = parseFloat(total);
            }
            //console.log("getTotalAmount; editable ");
        }
        // the normal scenario when no cargo details file is uploaded
        else {
            //console.log("getTotalAmount; not editable ");
            if (this.cargoDetailsFormArray) {
                var cargoDetailsFormGroup = this.cargoDetailsFormArray.controls;
                cargoDetailsFormGroup.forEach(function (cargoDetails) {
                    var quantity = cargoDetails.get("quantity").value;
                    var unitPrice = cargoDetails.get("unitPrice").value;
                    //const unitPrice = this.utils.currencyStringToNumber(cargoDetails.get(`unitPrice`).value);
                    //console.log("unitPrice: "+unitPrice);
                    //console.log("typeof unitPrice: "+ typeof unitPrice);
                    totalAmount += quantity * unitPrice;
                });
            }
        }
        //console.log("totalAmount: "+totalAmount);
        this.form.get('totalAmountHolder').setValue(totalAmount);
        // removed .toFixed() since i'm using a pipe to format the data
        return totalAmount;
    };
    FinanceRequestFormComponent.prototype.getRequestedAmount = function () {
        var requestedAmount = 0;
        if (this.eventDetailsFormArray) {
            var totalPercentage_1 = 0;
            var eventDetailsFormGroup = this.eventDetailsFormArray.controls;
            eventDetailsFormGroup.forEach(function (eventDetails) {
                var paymentPercentage = eventDetails.get("paymentPercentage").value;
                totalPercentage_1 += parseFloat(paymentPercentage) || 0;
            });
            var totalAmount = this.getTotalAmount();
            requestedAmount = (totalAmount * totalPercentage_1) / 100;
            this.form.get('requestedAmountHolder').setValue(requestedAmount);
        }
        // removed .toFixed() since i'm using a pipe to format the data
        return requestedAmount;
    };
    FinanceRequestFormComponent.prototype.selectionChange = function () {
        console.log('selectionChange;');
    };
    FinanceRequestFormComponent.prototype.downloadDoc = function (doc) {
        var _this = this;
        this.documentService.downloadDocument(this.finance.finance_id, doc.id, this.financeType).subscribe(function (response) {
            _this.downloadFile(response, doc.document_name);
        }, function (error) {
            _this.ui.snackbar("Error occured while downloading " + doc.document_name + " file");
            console.log(error);
        });
    };
    FinanceRequestFormComponent.prototype.downloadMiscDoc = function (docName) {
        var _this = this;
        this.documentService.downloadMiscDocument(docName).subscribe(function (response) {
            _this.downloadFile(response, docName);
        }, function (error) {
            _this.ui.snackbar("Error occured while downloading " + docName + " file");
            console.log(error);
        });
    };
    FinanceRequestFormComponent.prototype.downloadFile = function (result, filename) {
        var csvDownloadAnchor = document.createElement('a');
        var templateURL = URL.createObjectURL(new Blob([result]));
        csvDownloadAnchor.href = templateURL;
        csvDownloadAnchor.download = filename;
        csvDownloadAnchor.click();
        URL.revokeObjectURL(templateURL);
    };
    /**
     * When a user selects an autocomplete, this resets the dropdowns displayed options.
     * This gets around a bug in autocomplete where it shows options from last search.
     * @param toSelect
     */
    FinanceRequestFormComponent.prototype.inputFocused = function (toSelect) {
        var fromCountry = this.form.get('fromCountry').value;
        var toCountry = this.form.get('toCountry').value;
        switch (toSelect) {
            case 'fromPort':
                // if a user hasn't selected a fromCountry yet, we don't have options for this loaded yet 
                if (fromCountry === '') {
                    this.ui.snackbar("You must select a 'From Country' before you can select this. ");
                }
                this.fromPortFilteredOptions = this.fromPorts;
                break;
            case 'toPort':
                // if a user hasn't selected a toCountry yet, we don't have options for this loaded yet 
                if (toCountry === '') {
                    this.ui.snackbar("You must select a 'To Country' before you can select this. ");
                }
                this.toPortFilteredOptions = this.toPorts;
                break;
            case 'toCities':
                // if a user hasn't selected a toCountry yet, we don't have options for this loaded yet 
                if (toCountry === '') {
                    this.ui.snackbar("You must select a 'To Country' before you can select this. ");
                }
                this.toCitiesFilteredOptions = this.toCities;
                break;
            case 'fromCities':
                // if a user hasn't selected a fromCountry yet, we don't have options for this loaded yet        
                if (fromCountry === '') {
                    this.ui.snackbar("You must select a 'From Country' before you can select this. ");
                }
                this.fromCitiesFilteredOptions = this.fromCities;
                break;
            case 'fromCountry':
                this.fromCountryFilteredOptions = this.locations;
                break;
            case 'toCountry':
                this.toCountryFilteredOptions = this.locations;
                break;
        }
    };
    /**
     * Called when user selection leaves an autocomplete, if what they typed is not
     * a valid value we wipe it out. (mat-autocomplete should have this built in)
     * @param controlName
     * @param options
     */
    FinanceRequestFormComponent.prototype.blur = function (controlName, options) {
        var value = this.form.get(controlName).value;
        // if a country object is selected
        if (typeof value !== 'string') {
            return;
        }
        var filterValue = value.toLowerCase();
        var found = options.find(function (opt) {
            if (typeof opt === "string") {
                return (opt.toLowerCase() === filterValue.toLowerCase());
            }
            else if (opt.country_name) {
                return (opt.country_name.toLowerCase() === filterValue.toLowerCase());
            }
        });
        if (!found) {
            this.form.get(controlName).setValue('');
        }
    };
    // Logistic Finance Functions /////////////////////////////////////////////////////////////
    FinanceRequestFormComponent.prototype.invoiceDateChanged = function ($event) {
        console.log("invoiceDateChanged;");
        //this.getDiscountRate();
        this.InvoiceDate = this.form.get('invoiceDate').value;
    };
    FinanceRequestFormComponent.prototype.invoiceDueDateChanged = function ($event) {
        console.log("invoiceDueDateChanged;");
        this.InvoiceDueDate = this.form.get('invoiceDueDate').value;
        this.InvoiceDueDate.setDate(this.InvoiceDueDate.getDate() - 1);
        this.getDiscountRates();
    };
    FinanceRequestFormComponent.prototype.paymentDateChanged = function ($event) {
        console.log("paymentDateChanged;");
        this.getDiscountRates();
    };
    FinanceRequestFormComponent.prototype.isLogistics = function () {
        return this.financeType == FinanceType.Logistics;
    };
    FinanceRequestFormComponent.prototype.logisticsRequestedAmount = function () {
        return this.utils.logisticsRequestedAmount(this.form);
    };
    FinanceRequestFormComponent.prototype.logisticsReceivableAmount = function () {
        return this.utils.logisticsReceivableAmount(this.form);
    };
    FinanceRequestFormComponent.prototype.logisticsTotalAmount = function () {
        var total = 0;
        total = this.form.get('invoiceAmount').value;
        return total;
    };
    FinanceRequestFormComponent.prototype.addLogisticsDetails = function () {
        var logisticsDetails = this.form.get("logisticsDetails");
        if (logisticsDetails.length <= 2) {
            var group = new FormGroup({
                discountRate: new FormControl(''),
                recourse: new FormControl(''),
                paymentDate: new FormControl(''),
                paymentPercentage: new FormControl(''),
            });
            group.get('discountRate').disable();
            logisticsDetails.push(group);
        }
        else {
            this.ui.snackbar("You can only have 3 logistics details.");
        }
    };
    FinanceRequestFormComponent.prototype.removeLogisticsDetails = function (index) {
        var logisticsDetails = this.form.get("logisticsDetails");
        logisticsDetails.removeAt(index);
    };
    FinanceRequestFormComponent.prototype.getDiscountRates = function () {
        console.log("getDiscountRates;");
        this.applyService.getDiscountRates(this.form);
    };
    return FinanceRequestFormComponent;
}());
export { FinanceRequestFormComponent };
