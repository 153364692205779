import { HighlightSearchPipe } from './highlight-search-pipe';
import { LocalizedDatePipe } from './localized-date-pipe';
import { NgModule } from '@angular/core';
import { StringReplacePipe } from './string-replace-pipe';

@NgModule({
  imports: [
  ],
  declarations: [ 
    HighlightSearchPipe, LocalizedDatePipe, StringReplacePipe
  ],
  exports: [
    HighlightSearchPipe, LocalizedDatePipe, StringReplacePipe
  ]
})

/**
 * shared pipes for the project
 */
export class PipesModule {}