import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { PreloaderService } from '@core';
import { AuthService } from './routes/sessions/login/auth.service';
import { UiService } from './dp-world/services/ui.service';
import { environment } from 'environments/environment';
import { isDevMode } from '@angular/core';
import { UtilsService } from './dp-world/services/utils.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})

/**
 * Top level component of the application. Holds testing tool that allows us to switch accounts.
 */
export class AppComponent implements OnInit, AfterViewInit {

  hasAccountToggled = false;

  isDevMode = isDevMode;

  constructor(
    private preloader: PreloaderService,
    public authService: AuthService,
    public ui: UiService,
    private utils:UtilsService
  ) { }

  ngOnInit() { }

  curAccountIndex = 0;

  accounts = [
    {
      user_id: '48b7a5c069f670c049ed47208574cc5cdc7c5cd3',
      username: 'Chandler Bing',
      role: 'financier',
      admin: true,
      organisation_id: 1000016,
      organisation_name: 'Maxo Mills',
      organisation_type: 'ORG',
      email: 'chandler@century.com', //BCO
      password: 'demo1234',
    },
    {
      user_id: 'c1154a6cf17410b6067398815788f6f803210f8f',
      username: 'Ahmed Mohamed',
      role: 'financier',
      admin: true,
      organisation_id: 1000015,
      organisation_name: 'Century Textile Mills PVT LTD',
      organisation_type: 'ORG',
      email: 'ahmed@maxo.com', //BCO
      password: 'demo1234',
    },
    {
      user_id: 'fe8559dd3683b1ebf2413724d8d9b6cfb1e51aa3',
      username: 'Joey Tribbiani',
      role: 'financier',
      admin: true,
      organisation_id: 1000014,
      organisation_name: 'Digital Trade Finance',
      organisation_type: 'DTF',
      email: 'joey@dpworld.com', //Admin
      password: 'demo1234',
    },
    {
      user_id: '29427b66b8cb7fbdec4c8d92012ff65689907501',
      username: 'Monica Geller',
      role: 'banker',
      admin: true,
      organisation_id: 1000013,
      organisation_name: 'Dubai Finance Bank',
      organisation_type: 'BANK',
      email: 'monica@dfb.com', //Bank
      password: 'demo1234',
    },
  ];

  /**
   * Testing functionality that allows us to toggle through accounts by pressing '^'
   * @param event 
   */
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    // account switching tool only works in dev mode.
    if (!isDevMode() && !this.utils.isStaging()){
      return;
    }

    if (event.key === '^') {
      console.log('^ pressed!');

      let accountIndex = '-1';

      // first time we use this, we need to set to index of our current account, 
      // so we don't switch to what we are already logged in as
      for (let i = 0; i < this.accounts.length; i++) {
        let account = this.accounts[i];
        if (account.username === this.authService.currentUserValue.username) {
          console.log("handleKeyboardEvent; found the user!");
          accountIndex = '' + i;
          this.curAccountIndex = i;
        }
      }

      if (accountIndex == '-1') {
        //allows us to toggle through accounts despite browser refreshes.
        accountIndex = localStorage.getItem('testAccountIndex');
        if (accountIndex === null) {
          this.curAccountIndex = 0;
        } else {
          this.curAccountIndex = parseInt(accountIndex);
        }
      }

      this.curAccountIndex++;
      if (this.curAccountIndex >= this.accounts.length) {
        this.curAccountIndex = 0;
      }
      localStorage.setItem('testAccountIndex', this.curAccountIndex + '');

      let account = this.accounts[this.curAccountIndex];
      Object.assign(this.authService.user, account);
      let theUser: any = this.authService.user;

      this.authService
        .login({
          username: theUser.email,
          password: theUser.password,
        })
        .subscribe(() => {
          this.ui.snackbar(
            `Account Type: (${theUser.organisation_type}) -- ${theUser.username} -- Reloading... `
          );
          console.log('user now: ', this.authService.user);
          setTimeout(() => window.location.reload(), 1000);
        });
    }
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
