<div fxLayout="column" style="height: 500px; border: 0.5px solid #cecece; background-color: white; margin:0px;">

  <div fxLayout="row" style="border-bottom: 0.5px solid #cecece;">
    <div style="width:100px; height:90px;">
      <img src="assets/images/Content_Icon1b.png" draggable="false" class="matero-branding-logo-expanded" style="margin:10px;" />
    </div>
    <div>
      <h2 style="margin-bottom: 3px; color: #173A64;">Finance Status</h2>
      <div style="color:gray; font-size:small;">Progress Summary</div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxFlex="grow">

    <!-- 90% seems to be the only way we can get a margin on this-->
    <div style="width: 90%; height: 90%;" *ngIf="isTradeFinance">
      <ngx-charts-bar-vertical [view]="" [scheme]="colorScheme" [results]="tradeFinanceStatus" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </div>
    <div style="width: 90%; height: 90%;" *ngIf="!isTradeFinance">
      <ngx-charts-bar-vertical [view]="" [scheme]="colorScheme" [results]="logisticsFinanceStatus" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </div>
  </div>

</div>