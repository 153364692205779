<form autocomplete="off" [formGroup]="form">

    <div *ngIf="!hideApplyAs" fxLayout="row" fxLayoutAlign="start center" style="margin: 0 0 20px 0; min-width: 350px;">
        <h3 style="margin-right: 20px;"> Apply as: </h3>

        <mat-radio-group aria-label="Select an option" formControlName="BCOType" (change)="importerExporterChanged()">
            <mat-radio-button value="importer" [checked]="form.get('BCOType').value==1" style="margin-right: 20px;">
                <span *ngIf="!isLogistics()">Importer</span>
                <span *ngIf="isLogistics()">Buyer</span>
            </mat-radio-button>
            <mat-radio-button value="exporter" [checked]="form.get('BCOType').value==2" style="margin-right: 20px;">
                <span *ngIf="!isLogistics()">Exporter</span>
                <span *ngIf="isLogistics()">Seller</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <p style="margin-top: 10px;" class="category">1. Company Details</p>
    <div style="margin: 0px 10px 30px 10px; width: 100%;">
        <mat-divider style="width:100%;"></mat-divider>
    </div>

    <div *ngIf="canEdit" style="margin-bottom: 20px; margin-top: 35px;">
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-bottom: 0px;">
                <mat-label *ngIf="!isLogistics()">Importer</mat-label>
                <mat-label *ngIf="isLogistics()">Buyer</mat-label>
                <input autocomplete="off" (blur)="blur('importer', options)" type="text"
                    placeholder="start typing a company name" aria-label="Number" matInput formControlName="importer"
                    (keyup)="onKey($event)" [matAutocomplete]="auto" required>
                <mat-error *ngIf="form.get('importer').invalid">Please enter a valid company</mat-error>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <ng-container *ngFor="let option of filteredOptions | async">
                        <mat-option [value]="option">
                            {{option.full_name}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-bottom: 0px;">
                <mat-label *ngIf="!isLogistics()">Exporter</mat-label>
                <mat-label *ngIf="isLogistics()">Seller</mat-label>
                <input autocomplete="off" (blur)="blur('exporter', options)" type="text"
                    placeholder="start typing a company name" aria-label="Number" matInput formControlName="exporter"
                    (keyup)="onKey($event)" [matAutocomplete]="auto" required>
                <mat-error *ngIf="form.get('exporter').invalid">
                    Please enter a valid company
                </mat-error>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.full_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="!canEdit" style="margin-bottom: 20px;">
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                <mat-label *ngIf="!isLogistics()">Importer</mat-label>
                <mat-label *ngIf="isLogistics()">Buyer</mat-label>
                <input autocomplete="off" matInput placeholder="company name" formControlName="importerName" required>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                <mat-label *ngIf="!isLogistics()">Exporter</mat-label>
                <mat-label *ngIf="isLogistics()">Seller</mat-label>
                <input autocomplete="off" matInput placeholder="company name" formControlName="exporterName" required>
            </mat-form-field>
        </div>
    </div>



    <ng-container *ngIf="isLogistics()">

        <p class="category">2. Invoice Details</p>
        <div style="margin: 0px 10px 40px 10px; width: 90%;">
            <mat-divider style="width:100%;"></mat-divider>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                <mat-label>Invoice Id</mat-label>
                <input autocomplete="off" matInput maxlength="50" matTooltip="The id of the invoice"
                    placeholder="Invoice Id" formControlName="invoiceId">
                <mat-error *ngIf="form.get('invoiceId').invalid">Please enter a valid invoice id</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                <mat-label>Invoice Currency</mat-label>
                <mat-select formControlName="invoiceCurrency" name="currency" required>
                    <mat-option *ngFor="let currency of currencies" [value]="currency">
                        {{currency}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('invoiceCurrency').invalid">Please select the currency</mat-error>
            </mat-form-field>
        </div>


        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="1 1 50%"
                matTooltip="select date invoice was made by clicking calendar icon">
                <mat-label>Invoice Date</mat-label>
                <input autocomplete="off" matInput matTooltip="The date on which invoice was created"
                    [matDatepicker]="picker" (dateInput)="invoiceDateChanged($event)" [max]="currDate"
                    [min]="oneYearBelowDate" formControlName="invoiceDate" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.get('invoiceDate').invalid">Please select invoice date</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 1 50%">
                <mat-label>Invoice Due Date</mat-label>
                <input autocomplete="off" matInput
                    matTooltip="The due date on which the payment is expected to be received from the buyer"
                    [matDatepicker]="picker2" (dateInput)="invoiceDueDateChanged($event)" [min]="currDate"
                    [max]="oneYearAboveDate" formControlName="invoiceDueDate" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="form.get('invoiceDueDate').invalid">Please select invoice due date</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field appearance="outline" fxFlex="1 1 50%">
                <mat-label>Invoice Amount</mat-label>
                <input autocomplete="off" matInput matTooltip="The total amount as per the invoice" min="0"
                    placeholder="Invoice Amount" formControlName="invoiceAmount">
                <mat-error *ngIf="form.get('invoiceAmount').invalid">Please enter invoice amount</mat-error>
            </mat-form-field>

            <div class="center" *ngIf="showFileUpload" style="margin-top:-10px;" fxFlex="1 1 50%">
                <p>Upload invoice proof documents</p>
                <app-upload-wrapper (fileUploaded)="dropped($event)"> </app-upload-wrapper>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start center" style="width: 50%;"
                *ngIf="finance.documentsDetails && finance.documentsDetails.length > 0">
                <div style="font-weight: 500; margin-top: 0px; text-decoration: underline;">
                    Attached Documents
                </div>
                <div>
                    <ul>
                        <li *ngFor="let doc of finance.documentsDetails">
                            <span class="list-item">
                                <span style="margin-right: 10px">{{ doc.document_name }}</span>
                                <mat-icon (click)="downloadDoc(doc)" aria-hidden="false" color="primary">
                                    cloud_download</mat-icon>
                            </span>
                        </li>
                    </ul>
                </div>
                <br />
            </div>
        </div>

        <ng-container *ngIf="!hideTradeFinanceSection">
            <p class="category">3. Request Logistics Finance</p>
            <div style="margin: 0px 10px 40px 10px; width: 90%;">
                <mat-divider style="width:100%;"></mat-divider>
            </div>

            <div fxLayout="row wrap" fxLayoutGap="20px grid">
                <mat-form-field appearance="outline" fxFlex="1 1 auto">
                    <mat-label>Total Amount</mat-label>
                    <input formControlName="totalAmount" autocomplete="off" matInput min="0" placeholder="Total Amount"
                        [value]="logisticsTotalAmount()">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="1 1 auto">
                    <mat-label>Disbursal (Invoice) Currency</mat-label>
                    <mat-select formControlName="dispersalCurrency" name="currency" required>
                        <mat-option *ngFor="let currency of currencies" [value]="currency">
                            {{currency}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('dispersalCurrency').invalid">Please select the currency</mat-error>
                </mat-form-field>
            </div>

            <ng-container *ngFor="let item of form.get('logisticsDetails').controls; index as i">

                <div fxLayout="row wrap" fxLayoutGap="20px grid">

                    <mat-form-field appearance="outline" fxFlex="1 1 25%">
                        <mat-label>Payment Date</mat-label>
                        <input autocomplete="off" matInput matTooltip="The date on which payment is to be received"
                            [matDatepicker]="picker2" [min]="currDate" [max]="InvoiceDueDate"
                            (dateInput)="paymentDateChanged($event)" [formControl]="item.get('paymentDate')" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error *ngIf="item.get('paymentDate').invalid">Please select the payment date</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 25%">
                        <mat-label>Percentage Amount(%)</mat-label>
                        <input [formControl]="item.get('paymentPercentage')" autocomplete="off" matInput
                            matTooltip="Select the percentage of the amount you need on this date" min="0"
                            placeholder="Percentage Amount" [errorStateMatcher]="logisticsPercentageErrorMatcher">
                        <mat-error *ngIf="item.get('paymentPercentage').invalid">Please enter percentage of total amount
                            you need at this date</mat-error>
                        <mat-error>Total percentage should not exceed 100</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 25%">
                        <mat-label>Discount Rate</mat-label>
                        <input [formControl]="item.get('discountRate')" autocomplete="off" matInput min="0"
                            placeholder="Discount">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 20%">
                        <mat-label>Recourse</mat-label>
                        <mat-select matTooltip="The BCO that will have to pay the bank in case of a default"
                            [formControl]="item.get('recourse')" name="recourse">
                            <mat-option *ngFor="let by of recourseParties" [value]="by">
                                {{ utils.getLogisticsRecourse(by)}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="item.get('recourse').invalid">Please select recourse</mat-error>
                    </mat-form-field>

                    <div *ngIf="i == 0 && canEdit" fxFlex="1 1 5%">
                        <button *ngIf="i == 0 && canEdit" mat-icon-button color="primary" style="margin-top: 10px;"
                            (click)="$event.preventDefault(); addLogisticsDetails()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="i > 0 && canEdit" fxFlex="1 1 5%">
                        <button *ngIf="i > 0 && canEdit" mat-icon-button color="primary" style="margin-top: 10px;"
                            (click)="$event.preventDefault(); removeLogisticsDetails(i)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>


            <div fxLayout="row wrap" fxLayoutGap="20px grid">

                <mat-form-field appearance="outline" fxFlex="1 1 50%">
                    <mat-label>Approx Receivable Amount</mat-label>
                    <input formControlName="receivableAmount" autocomplete="off"
                        matTooltip="The approximate amount you will receive" matInput min="0"
                        placeholder="Approx Receivable Amount" [value]="logisticsReceivableAmount()">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="1 1 50%">
                    <mat-label>Requested Amount</mat-label>
                    <input formControlName="requestedAmount" matTooltip="The amount you requested" autocomplete="off"
                        matInput min="0" placeholder="Requested Amount" [value]="logisticsRequestedAmount()">
                </mat-form-field>

            </div>

            <div style="width: 100%; height: 40px;">
            </div>

        </ng-container>
    </ng-container>


    <ng-container *ngIf="!isLogistics()">

        <p class="category">2. Document Proof Type</p>
        <div style="margin: 0px 10px 40px 10px; width: 90%;">
            <mat-divider style="width:100%;"></mat-divider>
        </div>

        <div style="height: auto;" fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field fxFlex="1 1 25%" appearance="outline">
                <mat-label>Proof Type</mat-label>
                <mat-select matTooltip="Proof of intent of business for the reference of other stakeholders"
                    formControlName="proofType" name="proofType" required>
                    <mat-option *ngFor="let proof of proofTypes" [value]="proof">
                        {{ proof | replace | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('proofType').invalid">Please select a proof type</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="1 1 25%" appearance="outline">
                <mat-label>Incoterms</mat-label>
                <mat-select formControlName="incoterms" matTooltip="International Commercial terms you want to apply"
                    name="incoterms" required>
                    <mat-option *ngFor="let inc of shared.supportedIncoTermsDetails" [value]="inc.value">
                        {{ inc.value +" - "+inc.description }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('incoterms').invalid">Please select incoterms</mat-error>
            </mat-form-field>

            <div class="center" *ngIf="showFileUpload" fxFlex="2 1 50%">
                <app-upload-wrapper (fileUploaded)="dropped($event)"> </app-upload-wrapper>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start center" style="width: 50%; padding: 0px;"
                *ngIf="finance.documentsDetails && finance.documentsDetails.length > 0">
                <div style="font-weight: 500; margin-top: 0px; text-decoration: underline;">
                    Attached Documents
                </div>
                <div>
                    <ul>
                        <li *ngFor="let doc of finance.documentsDetails">
                            <span class="list-item">
                                <span style="margin-right: 10px">{{ doc.document_name }}</span>
                                <mat-icon (click)="downloadDoc(doc)" aria-hidden="false" color="primary">
                                    cloud_download</mat-icon>
                            </span>
                        </li>
                    </ul>
                </div>
                <br />
            </div>
        </div>

        <div *ngIf="endorseColumn" fxLayout="row wrap" fxLayoutGap="20px grid">
            <mat-form-field style="margin-top:10px;" fxFlex="1 1 50%" appearance="outline">
                <mat-label>Additional Proof Type</mat-label>
                <mat-select matTooltip="Proof of intent of business for the reference of other stakeholders"
                    formControlName="endorseProofType" name="endorseProofType" required>
                    <mat-option *ngFor="let proof of proofTypes" [value]="proof"
                        [disabled]="getProofTypeDisabled(proof)">
                        {{ proof | replace | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxFlex="1 1 50%">

                <app-upload-wrapper (fileUploaded)="droppedEndorseFile($event)"> </app-upload-wrapper>

            </div>
        </div>

        <p class="category">3. Location Details</p>
        <div style="margin: 0px 10px 30px 10px; width: 100%;">
            <mat-divider style="width:100%;"></mat-divider>
        </div>

        <div style="width: 100%; margin-top: 10px;">

            <div *ngIf="canEdit" style="margin-bottom: 5px; margin-top: 40px;">
                <div fxLayout="row" fxLayoutGap="20px grid" style="margin-top: 30px;">

                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="min-width: 350px;">
                        <mat-label>From Country</mat-label>
                        <input autocomplete="new-password" (blur)="blur('fromCountry', locations)"
                            (focus)="inputFocused('fromCountry')"
                            (keyup)="applyCountryFilter($event, locations, 'fromCountry')" type="text"
                            placeholder="Pick one" aria-label="Number" matInput formControlName="fromCountry"
                            [matAutocomplete]="autoF">
                        <mat-autocomplete (optionSelected)="selectedFromCountryChanged()" #autoF="matAutocomplete"
                            [displayWith]="displayCountryFn">
                            <mat-option *ngFor="let country of fromCountryFilteredOptions" [value]="country">
                                {{country.country_name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('fromCountry').invalid">Please select the source country</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="min-width: 350px;">
                        <mat-label>To Country</mat-label>
                        <input autocomplete="new-password" (blur)="blur('toCountry', locations)"
                            (focus)="inputFocused('toCountry')"
                            (keyup)="applyCountryFilter($event, locations, 'toCountry')" type="text"
                            placeholder="Pick one" aria-label="Number" matInput formControlName="toCountry"
                            [matAutocomplete]="autoG">
                        <mat-autocomplete (optionSelected)="selectedToCountryChanged()" #autoG="matAutocomplete"
                            [displayWith]="displayCountryFn">
                            <mat-option *ngFor="let country of toCountryFilteredOptions" [value]="country">
                                {{country.country_name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('toCountry').invalid">Please select the source country</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px grid">

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>From City</mat-label>
                        <input autocomplete="new-password" (blur)="blur('fromCity', fromCities)"
                            (focus)="inputFocused('fromCities')" (keyup)="applyFilter($event, fromCities, 'fromCity')"
                            type="text" aria-label="Number" matInput formControlName="fromCity"
                            [matAutocomplete]="autoE">
                        <mat-autocomplete #autoE="matAutocomplete">
                            <mat-option *ngFor="let city of fromCitiesFilteredOptions" [value]="city">
                                {{city}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('fromCity').invalid">Please select the origin city</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>To City</mat-label>
                        <input autocomplete="new-password" (focus)="inputFocused('toCities')" name="jkfldsjakfdlsk"
                            (keyup)="applyFilter($event, toCities, 'toCity')" type="search" placeholder="Pick one"
                            aria-label="Number" matInput formControlName="toCity" [matAutocomplete]="autoD">
                        <mat-autocomplete #autoD="matAutocomplete">
                            <mat-option *ngFor="let city of toCitiesFilteredOptions" [value]="city">
                                {{city}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('toCity').invalid">Please select the destination city</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px grid">

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>From Port</mat-label>
                        <input (keyup)="applyFilter($event, fromPorts, 'fromPort')" (focus)="inputFocused('fromPort')"
                            autocomplete="off" type="text" placeholder="Pick one" aria-label="Number" matInput
                            formControlName="fromPort" [matAutocomplete]="autoC">
                        <mat-autocomplete #autoC="matAutocomplete">
                            <mat-option *ngFor="let port of fromPortFilteredOptions" [value]="port">
                                {{port}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('fromPort').invalid">Please select the origin port</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>To Port</mat-label>
                        <input (keyup)="applyFilter($event, toPorts, 'toPort')" (focus)="inputFocused('toPort')"
                            type="text" autocomplete="off" placeholder="Pick one" aria-label="Number" matInput
                            formControlName="toPort" [matAutocomplete]="autoB">
                        <mat-autocomplete #autoB="matAutocomplete">
                            <mat-option *ngFor="let port of toPortFilteredOptions" [value]="port">
                                {{port}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="form.get('toPort').invalid">Please select the destination port</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="!canEdit" style="margin-bottom: 5px;">
                <div fxLayout="row" fxLayoutGap="20px grid">
                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>From Country</mat-label>
                        <input autocomplete="new-password" matInput placeholder="From Country"
                            formControlName="fromCountryName" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>To Country</mat-label>
                        <input autocomplete="new-password" matInput placeholder="To Country"
                            formControlName="toCountryName" required>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px grid">
                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>From City</mat-label>
                        <input autocomplete="new-password" matInput placeholder="From City" formControlName="fromCity"
                            required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>To City</mat-label>
                        <input autocomplete="new-password" matInput placeholder="To City" formControlName="toCity"
                            required>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px grid">
                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>From Port</mat-label>
                        <input autocomplete="new-password" matInput placeholder="From Port" formControlName="fromPort"
                            required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto" style="margin-top: 10px;">
                        <mat-label>To Port</mat-label>
                        <input autocomplete="new-password" matInput placeholder="To Port" formControlName="toPort"
                            required>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayoutAlign="start center" style="margin-right: 20px">
            <p class="category">4. Cargo Details</p>
            <mat-checkbox background-color="#4B0082" *ngIf="canEdit" (click)="showCargoFileUploadOption()"
                style="margin-left: 20px">Upload cargo details file</mat-checkbox>
        </div>
        <div style="margin: 0px 10px 25px 10px; width: 100%;">
            <mat-divider style="width:100%;"></mat-divider>
        </div>

        <div>
            <ng-container *ngIf="showCargoDetailsFileUpload">

                <div fxLayoutGap="20px grid">
                    <div fxFlex="1 1 50%" fxLayoutAlign="center center" style="width: 50%; margin-top:-10px;">
                        <p style="margin-top: 0px;">Cargo details file
                            <mat-icon style="font-size: 20px;"
                                matTooltip="Cargo details file should contain details like cargo type, quantity, and price. Supported file formats are: xls, xlsx and csv"
                                class="material-icons">help</mat-icon>
                            Download sample file
                            <span class="cargo-sample-file">
                                <mat-icon (click)="downloadMiscDoc('cargo_details.xlsx')" aria-hidden="false"
                                    color="primary">
                                    cloud_download</mat-icon>
                            </span>
                        </p>
                    </div>

                    <div fxFlex="1 1 50%" class="center" style="width: 50%; margin-top:-10px; margin-bottom: 20px;">
                        <app-upload-wrapper [supportedFileTypes]="'xls, xlsx, csv'"
                            (fileUploaded)="droppedCargoFile($event)"> </app-upload-wrapper>

                        <div class="upload-file">
                            <p *ngIf="cargoFile">
                                <mat-icon color="primary" style="vertical-align: middle;">attachment</mat-icon>
                                <span>Uploaded File - <strong> {{ cargoFile.fileEntry.name }} </strong></span>
                            </p>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>

        <ng-container *ngIf="cargoDetailsFormArray">
            <ng-container *ngFor="let item of cargoDetailsFormArray.controls; index as i">
                <div style="margin-bottom: 0px;">
                    <div *ngIf="!showCargoDetailsFileUpload" fxLayout="row wrap" fxLayoutGap="20px grid"
                        style="margin-bottom: 100px;">

                        <mat-form-field appearance="outline" fxFlex="1 1 25%">
                            <mat-label>Cargo Description</mat-label>
                            <input autocomplete="new-password" matInput type="text" placeholder="Cargo Description"
                                [formControl]="item.get('cargoDescription')" required>
                            <mat-error *ngIf="item.get('cargoDescription').invalid">Please enter a valid cargo
                                description
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 25%">
                            <mat-label>Quantity</mat-label>
                            <input autocomplete="off" matInput type="number" placeholder="Quantity"
                                [formControl]="item.get('quantity')" required>
                            <mat-error *ngIf="item.get('quantity').invalid">Please enter a valid numeric quantity
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 25%">
                            <mat-label>Unit Price</mat-label>
                            <!--<input autocomplete="off" matInput placeholder="Unit Price"
                        [formControl]="item.get('unitPrice')" [value]="pipeUtils.fixedCurrency( item.get('unitPriceHolder').value, form.get('currency').value )" required>-->
                            <input autocomplete="off" type="number" matInput placeholder="Unit Price"
                                [formControl]="item.get('unitPrice')" required>

                            <mat-error *ngIf="item.get('unitPrice').invalid">Please enter a valid unit price</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 20%">
                            <mat-label>Amount</mat-label>
                            <input autocomplete="off" matInput placeholder="Amount" formControlName="amount"
                                [value]="pipeUtils.fixedCurrency( getAmount(item.get('unitPrice').value, item.get('quantity').value), form.get('currency').value )"
                                required>
                            <mat-error *ngIf="form.get('amount').invalid">Please enter a valid numeric amount
                            </mat-error>
                        </mat-form-field>

                        <div *ngIf="i == 0 && canEdit" fxFlex="1 1 5%">
                            <button *ngIf="i == 0 && canEdit" matTooltip="Add new cargo type" mat-icon-button
                                class="matero-toolbar-button" color="primary" style="margin-top: 10px;"
                                (click)="$event.preventDefault(); addCargo()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <div *ngIf="i > 0 && canEdit" fxFlex="1 1 5%">
                            <button *ngIf="i > 0 && canEdit" matTooltip="Remove this cargo type" mat-icon-button
                                class="matero-toolbar-button" color="primary" style="margin-top: 10px;"
                                (click)="$event.preventDefault(); removeCargo(i)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </ng-container>
        </ng-container>

        <mat-form-field appearance="outline" style="width: 100%; height: auto; margin-top: 0px;">
            <mat-label>Content Description</mat-label>
            <textarea matInput style="height:85.5px;" type="text" placeholder="Content Description"
                formControlName="contentDescription" required>
            </textarea>
            <mat-error *ngIf="form.get('contentDescription').invalid">Please enter a content description for the cargo
            </mat-error>
        </mat-form-field>

        <ng-container *ngIf="!hideTradeFinanceSection">
            <div fxLayoutAlign="start center" style="margin-right: 20px;">
                <p class="category">5. Request Trade Finance</p>
            </div>
            <div style="margin: 0px 10px 30px 10px; width: 100%;">
                <mat-divider style="width:100%;"></mat-divider>
            </div>

            <ng-container *ngIf="form.get('eventDetails')">
                <ng-container *ngFor="let item of form.get('eventDetails').controls; index as i">
                    <div style="margin-bottom: 0px;">
                        <div fxLayout="row wrap" fxLayoutGap="20px grid">
                            <mat-form-field appearance="outline" fxFlex="1 1 25%">
                                <mat-label>Disbursal Event</mat-label>

                                <mat-select matTooltip="The event that triggers payment disbursal"
                                    [formControl]="item.get('paymentEvent')" required>

                                    <mat-optgroup [label]=" 'Exporter Events' ">
                                        <mat-option *ngFor="let exporterEvent of exporterEvents"
                                            [value]="exporterEvent">
                                            {{exporterEvent | replace | titlecase }}
                                        </mat-option>
                                    </mat-optgroup>

                                    <mat-optgroup [label]=" 'Importer Events' ">
                                        <mat-option *ngFor="let importerEvent of importerEvents"
                                            [value]="importerEvent">
                                            {{ importerEvent | replace | titlecase }}
                                        </mat-option>
                                    </mat-optgroup>

                                </mat-select>
                                <mat-error *ngIf="item.get('paymentEvent').invalid">Please select disbursal event
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="1 1 12.5%">
                                <mat-label>Days after event</mat-label>
                                <input autocomplete="new-password" matInput type="number"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    placeholder="Days after event" [formControl]="item.get('daysAfterDisbursalEvent')"
                                    required>
                                <mat-error *ngIf="item.get('daysAfterDisbursalEvent').invalid">Please enter the no of
                                    days(whole number)</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="10 1 12.5%">
                                <mat-label>Disbursal Percentage(%)</mat-label>
                                <input autocomplete="off" matInput type="number" min="0"
                                    placeholder="Disbursal Percentage"
                                    matTooltip="The percentage of the amount you need at this event"
                                    [formControl]="item.get('paymentPercentage')"
                                    [errorStateMatcher]="percentageErrorMatcher"
                                    [value]="item.get('paymentPercentage').value | number:'1.0-0' " required>
                                <mat-error *ngIf="item.get('paymentPercentage').invalid">Please enter the payment
                                    percentage
                                </mat-error>
                                <mat-error>total percentage should not exceed 100</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="1 1 25%">
                                <mat-label>Interest Borne By</mat-label>
                                <mat-select matTooltip="The interest charges will be borne by this BCO"
                                    [formControl]="item.get('interestBearer')" name="interestBearer" required>
                                    <mat-option *ngFor="let by of parties" [value]="by">
                                        {{by}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="item.get('interestBearer').invalid">Please select interest bearer
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="1 1 20%">
                                <mat-label>Recourse</mat-label>
                                <mat-select matTooltip="The BCO that will have to pay the bank in case of a default"
                                    [formControl]="item.get('recourse')" name="recourse">
                                    <mat-option *ngFor="let by of recourseParties" [value]="by">
                                        {{by}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div *ngIf="i == 0 && canEdit" fxLayoutAlign="start start" fxFlex="1 1 5%">
                                <button matTooltip="Add new disbursal event" mat-icon-button
                                    class="matero-toolbar-button" color="primary" style="margin-top: 10px;"
                                    (click)="$event.preventDefault(); addEvent()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>

                            <div *ngIf="i > 0 && canEdit" fxLayoutAlign="start start" fxFlex="1 1 5%">
                                <button *ngIf="i > 0 && canEdit" matTooltip="Remove disbursal event" mat-icon-button
                                    class="matero-toolbar-button" color="primary" style="margin-top: 10px;"
                                    (click)="$event.preventDefault(); removeEvent(i)">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="form.get('settlementEventDetails')">
                <ng-container *ngFor="let item of form.get('settlementEventDetails').controls; index as i">
                    <div fxLayout="row wrap" fxLayoutGap="20px grid" style="margin-bottom: 40px;">

                        <mat-form-field appearance="outline" fxFlex="1 1 50%">
                            <mat-label> Settlement Event</mat-label>

                            <mat-select matTooltip="The event that triggers settlement"
                                [formControl]="item.get('settlementEvent')" required>

                                <mat-optgroup [label]=" 'Importer Events' ">
                                    <mat-option *ngFor="let importerEvent of importerEvents" [value]="importerEvent">
                                        {{ importerEvent | replace | titlecase }}
                                    </mat-option>
                                </mat-optgroup>

                            </mat-select>
                            <mat-error *ngIf="item.get('settlementEvent').invalid">Please select settlement event
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 25%">
                            <mat-label>Days after event</mat-label>
                            <input autocomplete="new-password" matInput type="number"
                                matTooltip="The payment will happen after these no. of days"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                placeholder="Days after event" [formControl]="item.get('daysAfterSettlementEvent')"
                                required>
                            <mat-error *ngIf="item.get('daysAfterSettlementEvent').invalid">Please enter the no of days
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="1 1 20%">
                            <mat-label>Settlement Percentage(%)</mat-label>
                            <input autocomplete="new-password" matInput type="number" min="0.01"
                                placeholder="Settlement Percentage"
                                matTooltip="The percentage of the amount you want to pay at this event"
                                [formControl]="item.get('settlementPercentage')"
                                [errorStateMatcher]="settlementPercentageErrorMatcher" required>
                            <mat-error *ngIf="item.get('settlementPercentage').invalid">Please enter the payment
                                percentage
                            </mat-error>
                            <mat-error>total percentage should not exceed 100</mat-error>
                        </mat-form-field>

                        <div *ngIf="i == 0 && canEdit" fxLayoutAlign="start start" fxFlex="1 1 5%">
                            <button matTooltip="Add new settlement event" mat-icon-button class="matero-toolbar-button"
                                color="primary" style="margin-top: 10px;"
                                (click)="$event.preventDefault(); addSettlementEvent()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <div *ngIf="i > 0 && canEdit" fxLayoutAlign="start start" fxFlex="1 1 5%">
                            <button *ngIf="i > 0 && canEdit" matTooltip="Remove settlement event" mat-icon-button
                                class="matero-toolbar-button" color="primary" style="margin-top: 10px;"
                                (click)="$event.preventDefault(); removeSettlementEvent(i)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>

                    </div>
                </ng-container>
            </ng-container>

            <div style="margin-bottom: 40px;">
                <div fxLayout="row" fxLayoutGap="20px grid">
                    <mat-form-field appearance="outline" fxFlex="1 1 50%">
                        <mat-label>Bank</mat-label>
                        <mat-select formControlName="bank" name="bank">
                            <mat-option *ngFor="let bank of banks" [value]="bank">
                                {{ bank.full_name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('bank').invalid">Please select the bank</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 50%">
                        <mat-label>Total Amount</mat-label>
                        <input autocomplete="off" matInput min="0" placeholder="Total Amount"
                            formControlName="totalAmount" [value]="getTotalAmount()" required>
                        <mat-error *ngIf="form.get('totalAmount').invalid">Please enter the total amount</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px grid" style="padding-bottom: 0px !important;">
                    <mat-form-field appearance="outline" fxFlex="1 1 50%">
                        <mat-label>Currency</mat-label>
                        <mat-select formControlName="currency" name="currency" required>
                            <mat-option *ngFor="let currency of currencies" [value]="currency">
                                {{currency}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('currency').invalid">Please select the currency</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 50%">
                        <mat-label>Requested Amount</mat-label>
                        <input autocomplete="off" matInput min="0" placeholder="Requested Amount"
                            formControlName="requestedAmount"
                            [value]="pipeUtils.fixedCurrency( getRequestedAmount(), form.get('currency').value )">
                    </mat-form-field>
                </div>
            </div>

        </ng-container>
    </ng-container>
</form>