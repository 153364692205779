/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./breadcrumb.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/services/menu.service";
var styles_BreadcrumbComponent = [i0.styles];
var RenderType_BreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
function View_BreadcrumbComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "chevron mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BreadcrumbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "matero-breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.diplayData == null) ? null : _co.diplayData.secondLink); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.diplayData == null) ? null : _co.diplayData.firstLink); _ck(_v, 2, 0, currVal_0); }); }
function View_BreadcrumbComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "matero-breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.diplayData.secondLink; _ck(_v, 1, 0, currVal_0); }); }
export function View_BreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nav", [["aria-label", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ol", [["class", "matero-breadcrumb"], ["style", "margin-bottom: 2px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.diplayData == null) ? null : _co.diplayData.firstLink); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.diplayData == null) ? null : _co.diplayData.secondLink); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i1.ɵdid(1, 638976, null, 0, i5.BreadcrumbComponent, [i6.Router, i7.MenuService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbComponentNgFactory = i1.ɵccf("breadcrumb", i5.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, { nav: "nav", diplayData: "diplayData" }, {}, []);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
