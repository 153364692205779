import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { FinanceType } from 'app/routes/apply/apply-models';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  /*
  @param financeId
  @param documentId
  */
  downloadDocument(financeId: string | number, documentId: string, financeType:FinanceType=FinanceType.Trade) {
    //let url = environment.rootUrl + 'trade-finance/' + financeId + '/document/' + documentId;
    let url = environment.rootUrl;

    if (financeType==FinanceType.Logistics){
      url += 'logistics-finance/';
    } else if (financeType==FinanceType.Trade){
      url += 'trade-finance/';
    }

    if (financeId != '') {
      url += financeId+"/";
    }

    if (documentId != '') {
      url += "document/"+documentId;
    }
    
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  downloadMiscDocument(documentName: string) {
    let url = environment.rootUrl + 'miscDocument/' + documentName;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }
}
