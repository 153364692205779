import { Injectable } from '@angular/core';
import { Notification } from '../../routes/dashboard/dashboard.models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})

export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotifications() {
    const url = environment.rootUrl + environment.urls.notifications;
    return this.http.get(url);
  }

  updateNotification(notificationId) {
    const url = environment.rootUrl + 'notifications/' + notificationId + '/status';
    return this.http.put(url, null);
  }
}
