import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss']
})

/**
 * The popup that allows you to enter a new organization.
 */
export class AddOrganizationComponent implements OnInit {

  form:FormGroup;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddOrganizationComponent>,) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      name: ['', Validators.required], 
      email: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }

  cancelClicked(){
    this.dialogRef.close(null);
  }

  submitClicked(){
    this.dialogRef.close(this.form.value);
  }

}
