import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

/** 
 * Data shared by the frontend and backend. We load this at site load, so that frontend and backend data always
 * match up, without communication being neccessary.
 * @author charles.skinner@dpworld.com
 */
export class SharedDataService {

  sharedData:any=null;

  observableInProgress:Observable<any>;

  constructor(
    private http: HttpClient) { }

  getSharedStaticData() {

    // if we've already fetched this data, just return it
    if (this.sharedData){
      return of(this.sharedData);
    }

    // if we're currently fetching the data, return the fetch
    if (this.observableInProgress){
      return this.observableInProgress;
    }

    // otherwise, start fetching the data
    let url = environment.rootUrl + environment.urls.shared_data;
    this.observableInProgress = this.http.get(url);
    this.observableInProgress.subscribe((data:any)=>{

      // save the data for later
      this.sharedData = data;

      console.log("sharedData: ", this.sharedData);

      // make sure it's clear we aren't fetching anymore.
      this.observableInProgress = null;
    });

    return this.observableInProgress;
  }
}
