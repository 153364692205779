import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PipeUtilsService {

  constructor(private _currencyPipe: CurrencyPipe) { }

  /**
   * 
   * @param value 
   * @param currencyType 
   */
  public fixedCurrency(value, currencyType, saveTo:FormControl=null){

    //console.log("fixedCurrency; value: "+value);
    //console.log("fixedCurrency; typeof value: "+ typeof value);

    if (saveTo){
      saveTo.setValue(value);
    }

    if ( !isNaN(value) ) {
      return this._currencyPipe.transform(value, currencyType, 'symbol', '1.0-0');
    } else {
      return value;
    }
  }
}
