import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReasonDialogComponent } from 'app/routes/apply/def/reason-dialog/reason-dialog.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-apply-dialog',
  templateUrl: './apply-dialog.component.html',
  styleUrls: ['./apply-dialog.component.scss']
})

/**
 * Called when user clicks Apply button. Shows user steps of application and allows them to proceed to
 * application.
 */
export class ApplyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder:FormBuilder) { }

    typeControl:FormControl;

    isDevMode=isDevMode;

    financeType='normal';

  ngOnInit() {
    this.typeControl = new FormControl('');
  }

  submitClicked(){
    console.log("submitClicked;");
    this.dialogRef.close("proceed");
  }

  cancelClicked(){
    this.dialogRef.close("cancel");
  }

  financeTypeChanged(){
    console.log("financeTypeChanged;"+this.financeType);
  }

  logisticsClicked(){
    this.dialogRef.close("logistics");
  }
}
