<nav aria-label="breadcrumb">
  <ol style="margin-bottom: 2px;" class="matero-breadcrumb">
    <li *ngIf="diplayData?.firstLink" class="matero-breadcrumb-item">
      <a href="#" class="link">{{diplayData?.firstLink}}</a>
      <mat-icon *ngIf="diplayData?.secondLink" class="chevron">chevron_right</mat-icon>
    </li>
    <li *ngIf="diplayData?.secondLink" class="matero-breadcrumb-item">
      {{diplayData.secondLink}}
    </li>
    <!--
    <li class="matero-breadcrumb-item"
        *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst;">
      <a href="#" class="link" *ngIf="isFirst">{{navlink}}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{navlink}}</span>
      </ng-container>
    </li>
    -->
  </ol>
</nav>