import * as tslib_1 from "tslib";
import { commonEnvironment } from './environment.common';
// Todo determine the branch
// Staging or Master
// then update rootUrl accordingly
var gitBranch = 'staging';
var rootUrl = '//staging-api.revma.dpworld.com:8089/';
// what was this?
// @ts-ignore
if (gitBranch === 'master') {
    rootUrl = '//prod-api.revma.dpworld.com:8089/';
}
export var environment = tslib_1.__assign({}, commonEnvironment, { SERVER_URL: '', production: true, useHash: true, hmr: false, rootUrl: rootUrl });
