import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FinanceType } from 'app/routes/apply/apply-models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DocumentService = /** @class */ (function () {
    function DocumentService(http) {
        this.http = http;
    }
    /*
    @param financeId
    @param documentId
    */
    DocumentService.prototype.downloadDocument = function (financeId, documentId, financeType) {
        if (financeType === void 0) { financeType = FinanceType.Trade; }
        //let url = environment.rootUrl + 'trade-finance/' + financeId + '/document/' + documentId;
        var url = environment.rootUrl;
        if (financeType == FinanceType.Logistics) {
            url += 'logistics-finance/';
        }
        else if (financeType == FinanceType.Trade) {
            url += 'trade-finance/';
        }
        if (financeId != '') {
            url += financeId + "/";
        }
        if (documentId != '') {
            url += "document/" + documentId;
        }
        return this.http.get(url, { responseType: 'blob' });
    };
    DocumentService.prototype.downloadMiscDocument = function (documentName) {
        var url = environment.rootUrl + 'miscDocument/' + documentName;
        return this.http.get(url, { responseType: 'blob' });
    };
    DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.HttpClient)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };
