import * as tslib_1 from "tslib";
import { MatSnackBar, MatDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
var UiService = /** @class */ (function () {
    function UiService(bar, dialog) {
        this.bar = bar;
        this.dialog = dialog;
        this.currentSnackBar = null;
    }
    /**
     * Displays a message to the user, in the top center of their screen. Wraps angular material snackbar.
     * @param message
     * @param action
     * @param config
     */
    UiService.prototype.snackbar = function (message, action, config) {
        if (action === void 0) { action = null; }
        if (config === void 0) { config = {}; }
        this.currentSnackBar = this.bar.open(message, action, tslib_1.__assign({ verticalPosition: 'top', duration: 4000, panelClass: 'warn' }, config));
        return this.currentSnackBar;
    };
    /**
     * Closes the currently displayed snackbar. This is called when the user navigates away from a tabs/view
     */
    UiService.prototype.closeCurrentSnackbar = function () {
        if (this.currentSnackBar) {
            this.currentSnackBar.dismiss();
        }
    };
    UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i2.MatDialog)); }, token: UiService, providedIn: "root" });
    return UiService;
}());
export { UiService };
