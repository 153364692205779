import { Finance, Notification, CreditLimitInfo, Settings, StatusType } from 'app/routes/dashboard/dashboard.models';
import { FinanceStates } from 'app/routes/apply/apply-models';
import * as i0 from "@angular/core";
var StaticDataService = /** @class */ (function () {
    function StaticDataService() {
        this.settings = {
            user: {
                userName: "Charles",
                name: "Charles Skinner",
                email: "charles.skinner@dpworld.com",
                phone: "2069097485"
            },
            bank: {
                bank: "Chase",
                branch: "seattle",
                country: "USA",
                city: "Seattle",
                accountName: "primary",
                accountNumber: "555555555",
                IBAN: "huh?",
                swiftCode: "not sure",
            },
            compliance: {
                panCard: "4444444",
                GST_Num: "3333333"
            }
        };
        /*
        transactions: Array<Transaction> = [
          {
            //"transaction_id": "10009821",
            "finance_id": 659073824,
            //"type": "Credit/Debit",
            "amount": 3000.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.PROCESSING,
            "action": "Pay Now"
          },
          {
            //"transaction_id": "109340001",
            "finance_id": 6098430,
            //"type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.PENDING,
            "action": "Pay Now"
          },
          {
            //"transaction_id": "1007777701",
            "finance_id": 655558,
            "type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.REVERTED,
            "action": "View Message From Agent"
          },
          {
            "transaction_id": "100220001",
            "finance_id": 651111,
            "type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.DRAFT,
            "action": "Resume"
          },
          {
            "transaction_id": "10111100001",
            "finance_id": 60000,
            "type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.APPROVED,
            "action": "View Summary"
          },
          {
            "transaction_id": "1088880001",
            "finance_id": 6898898,
            "type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.IN_PROCESS,
            "action": "View Summary"
          },
          {
            "transaction_id": "1088880001",
            "finance_id": 6898898,
            "type": "Credit/Debit",
            "amount": 3690.00,
            "currency_type": "dollar",
            "timestamp": "2011-10-05T14:48:00.000Z",
            "importer": "1",
            "exporter": "2",
            "status": StatusType.SETTLED,
            "action": "View Summary"
          },
        ];*/
        this.finances = [
            {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.PENDING,
                "timestamp": "2011-10-05T14:48:00.000Z"
            },
            {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.REVERTED,
                "timestamp": "2011-10-05T14:48:00.000Z"
            },
            {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.DRAFT,
                "timestamp": "2011-10-05T14:48:00.000Z"
            },
            {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.APPROVED,
                "timestamp": "2011-10-05T14:48:00.000Z"
            }, {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.IN_PROCESS,
                "timestamp": "2011-10-05T14:48:00.000Z"
            },
            {
                "finance_id": 654678,
                "finance_sub_classification": "Pre/Post",
                "proof_id": "223456789",
                "proof_type": "PO/Sales/Contract",
                "amount": 50000.00,
                "currency_type": "dollar",
                "current_state": StatusType.SETTLED,
                "timestamp": "2011-10-05T14:48:00.000Z"
            }
        ];
        this.notifications = [
            {
                "message_id": "001",
                "finance_id": "123456",
                "message": "DTF has been created",
                "timestamp": "2020-03-09T08:27:07Z"
            },
            {
                "message_id": "002",
                "finance_id": "234567",
                "message": "DTF has been approved",
                "timestamp": "2020-03-09T08:27:07Z"
            },
            {
                "message_id": "001",
                "finance_id": "123456",
                "message": "DTF has been created",
                "timestamp": "2020-03-09T08:27:07Z"
            },
            {
                "message_id": "002",
                "finance_id": "234567",
                "message": "DTF has been approved",
                "timestamp": "2020-03-09T08:27:07Z"
            },
            {
                "message_id": "001",
                "finance_id": "123456",
                "message": "DTF has been created",
                "timestamp": "2020-03-09T08:27:07Z"
            },
            {
                "message_id": "002",
                "finance_id": "234567",
                "message": "DTF has been approved",
                "timestamp": "2020-03-09T08:27:07Z"
            }
        ];
        // for testing dashboard pie chart
        this.creditLimit = {
            "max_limit": "100000.00",
            "availed_amount": 50000.00,
            "currency_type": "dollar",
            "num_of_active_loans": 5
        };
        // for testing dashboard without pie chart
        this.creditLimit2 = {
            "max_limit": "100000.00",
            "availed_amount": 0,
            "currency_type": "dollar",
            "num_of_active_loans": 5
        };
        // used to auto fill the info-step for testing purposes.
        this.applyInfoData = {
            fromTerminal: 'Seattle',
            toTerminal: 'Dubai',
            importer: 'Honda',
            exporter: 'Nissan',
            cargoType: 'Can',
            loading: '',
            discharge: '',
            proofType: 'yep',
            totalAmount: 1000,
            requestedAmount: 1000,
            contentDescription: 'yep',
            dispersalTime: '10-20-2020',
            bank: 'Chase',
            currency: 'USD',
        };
        // used to auto fill the  for testing purposes.
        this.applyOtherFormData = {
            sanctionedAmount: '',
            discountRate: '',
            currency: '',
            bank: '',
            processingFee: '',
            amountReceivable: '',
            transactionEvent: '',
        };
        this.finance2 = {
            "financeSubClassification": "pre",
            "createdBy": "ac0f370623415f8be75c2dac39b3cf6ce6933660c4e8510d16a907cccd3102b1",
            "importerId": "org_id_1",
            "exporterId": "org_id_2",
            "incoTerms": "EXW",
            "shipmentOriginPort": "Dubai",
            "shipmentDestinationPort": "Mumbai",
            "invoiceAmount": 1234.56789,
            "requestedAmount": 1200,
            "financeCurrency": "USD",
            "cargoDetails": [{ "cargoDescription": "details about cargo", "quantity": 3, "unitPrice": 1.50 }],
            "requestedBanks": [{ "id": "1000000", "accountNumber": "acc_num01" }],
            "disbursalEvents": [{ "paymentPercentage": 20, "daysAfterDisbursalEvent": 10, "paymentEvent": "EXPORTER_PRODUCTION_COMPLETED", "interestBearer": "IMPORTER", "recourse": "EXPORTER" }],
            "settlementEvents": [{ "settlementPercentage": 20, "daysAfterSettlementEvent": 10, "settlementEvent": "EXPORTER_PRODUCTION_COMPLETED" }]
        };
        /**
         * used when user starts a new application. holds defaults.
         */
        this.blankFinanceObject = {
            "finance_id": "",
            "finance_sub_classification": "pre",
            "importer_id": 0,
            "exporter_id": 0,
            "created_by": "",
            "assigned_to": null,
            "state": FinanceStates.BCO_FINANCE_SAVED,
            "proof_type": "",
            "inco_terms": "EXW",
            "submitted_at": "",
            "shipment_origin_port": "",
            "shipment_destination_port": "",
            "shipment_description": null,
            "invoice_amount": "",
            "invoice_currency": "USD",
            "requested_amount": "",
            "requested_currency": "USD",
            "collection_amount": null,
            "collection_currency": null,
        };
        this.fullFinance = {
            "finance_id": "1000003",
            "finance_sub_classification": "pre",
            "importer_id": 1000009,
            "exporter_id": 1000015,
            "created_by": "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
            "assigned_to": null,
            "state": FinanceStates.BCO_FINANCE_SAVED,
            "proof_type": "purchase_order",
            "inco_terms": "EXW",
            "submitted_at": "2020-03-30T03:35:10.050Z",
            "shipment_origin_port": "Dubai",
            "shipment_destination_port": "Mumbai",
            "shipment_description": null,
            "invoice_amount": "1212.444568",
            "invoice_currency": "USD",
            "requested_amount": "800.000000",
            "requested_currency": "USD",
            "collection_amount": null,
            "collection_currency": null,
            "created_at": "2020-03-30T03:34:53.924Z",
            "updated_at": "2020-03-30T03:35:10.171Z",
            "deleted_at": null,
            "financeUsersDetails": [
                {
                    "user_id": "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
                    "user_full_name": "Griffie Dillingston",
                    "approval_status": "approved"
                },
                {
                    "user_id": "1561a52590514ad47ee4afc7f09f7d181be3343ecf7270841880172d2e3434e8",
                    "user_full_name": "Ker Ewells",
                    "approval_status": "pending"
                }
            ],
            "financeBanksDetails": [
                {
                    "bank_id": 1000011,
                    "bank_account_number": null,
                    "approval_status": "pending"
                },
                {
                    "bank_id": 1000012,
                    "bank_account_number": null,
                    "approval_status": "pending"
                }
            ],
            "financeCargoDetails": [
                {
                    "id": 1000005,
                    "cargo_description": "details about cargo",
                    "quantity": 3,
                    "unit_price": "1.500000"
                },
                {
                    "id": 1000006,
                    "cargo_description": "details about cargo",
                    "quantity": 3,
                    "unit_price": "1.500000"
                }
            ],
            "financePaymentTerms": [
                {
                    "id": 1000005,
                    "finance_id": 1000003,
                    "bank_id": 1000011,
                    "sanctioned_amount": null,
                    "sanctioned_currency": null,
                    "interest_rate": null,
                    "payment_deadline": null,
                    "processing_fee": null,
                    "has_exporter_accepted": "pending",
                    "has_importer_accepted": "pending",
                    "created_at": "2020-03-30T03:34:53.924Z",
                    "updated_at": null,
                    "deleted_at": null,
                    "disbursalEvent": []
                },
                {
                    "id": 1000006,
                    "finance_id": 1000003,
                    "bank_id": 1000012,
                    "sanctioned_amount": null,
                    "sanctioned_currency": null,
                    "interest_rate": null,
                    "payment_deadline": null,
                    "processing_fee": null,
                    "has_exporter_accepted": "pending",
                    "has_importer_accepted": "pending",
                    "created_at": "2020-03-30T03:35:10.171Z",
                    "updated_at": null,
                    "deleted_at": null,
                    "disbursalEvent": [
                        {
                            "payment_term_id": 1000006,
                            "payment_percentage": "20.00",
                            "disburse_on_event": "EXPORTER_PRODUCTION_COMPLETED",
                            "interest_bearer": "IMPORTER",
                            "recourse": "EXPORTER",
                            "actual_disbursal_date": null,
                            "created_at": "2020-03-30T03:35:10.171Z",
                            "updated_at": null,
                            "deleted_at": null
                        }
                    ]
                }
            ],
            "financeStateTransactionsDetails": [
                {
                    "user_id": "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
                    "user_full_name": "Griffie Dillingston",
                    "state": "BCO_FINANCE_SUBMITTED",
                    "notes": "finance raised",
                    "created_at": "2020-03-30T03:35:10.171Z",
                    "updated_at": null,
                    "deleted_at": null
                },
                {
                    "user_id": "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665",
                    "user_full_name": "Griffie Dillingston",
                    "state": "BCO_FINANCE_SAVED",
                    "notes": "finance raised",
                    "created_at": "2020-03-30T03:34:53.924Z",
                    "updated_at": null,
                    "deleted_at": null
                }
            ],
            "financeTransactionsDetails": [],
            "financeDocumentsDetails": [
                {
                    "id": 1000007,
                    "document_name": "XkOo35zqwAMVxYPoVo_d1uzE.png",
                    "document_type": "purchase_order",
                    "document_location": "https://dtlp-file-storage-development.s3.ap-south-1.amazonaws.com/trade-finance/finances/1000003/XkOo35zqwAMVxYPoVo_d1uzE.png",
                    "uploaded_by": "Griffie Dillingston",
                    "accessibleUsers": [
                        "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665"
                    ]
                },
                {
                    "id": 1000008,
                    "document_name": "jXF7PcmLCVCCv98Xrecuct7Z.png",
                    "document_type": "proforma_invoice",
                    "document_location": "https://dtlp-file-storage-development.s3.ap-south-1.amazonaws.com/trade-finance/finances/1000003/jXF7PcmLCVCCv98Xrecuct7Z.png",
                    "uploaded_by": "Griffie Dillingston",
                    "accessibleUsers": [
                        "258531e6b6bb130080db67666161967207035ac3cf43d10df0de71ece10c2665"
                    ]
                }
            ]
        };
        // user objects (just a reference) -----------------------------------------
        this.exporter = {
            "user_id": "c1154a6cf17410b6067398815788f6f803210f8f",
            "username": "Ahmed Mohamed",
            "role": "financier",
            "admin": true,
            "organisation_id": 1000002,
            "organisation_name": "Maxo Mills",
            "organisation_type": "ORG"
        };
        this.importer = {
            "user_id": "48b7a5c069f670c049ed47208574cc5cdc7c5cd3",
            "username": "Chandler Bing",
            "role": "financier",
            "admin": true,
            "organisation_id": 1000003,
            "organisation_name": "Century Textile Mills PVT LTD",
            "organisation_type": "ORG"
        };
        this.banker = {
            "user_id": "29427b66b8cb7fbdec4c8d92012ff65689907501",
            "username": "Monica Geller",
            "role": "banker",
            "admin": true,
            "organisation_id": 1000000,
            "organisation_name": "Dubai Finance Bank",
            "organisation_type": "BANK"
        };
        this.dpAdmin = {
            "user_id": "fe8559dd3683b1ebf2413724d8d9b6cfb1e51aa3",
            "username": "Joey Tribbiani",
            "role": "financier",
            "admin": true,
            "organisation_id": 1000001,
            "organisation_name": "Digital Trade Finance",
            "organisation_type": "DTF"
        };
    }
    StaticDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StaticDataService_Factory() { return new StaticDataService(); }, token: StaticDataService, providedIn: "root" });
    return StaticDataService;
}());
export { StaticDataService };
