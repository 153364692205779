import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { StaticDataService } from 'app/dp-world/services/static-data.service';
import { Finance } from '../dashboard/dashboard.models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Finance2 } from './apply-models';
import { UtilsService } from 'app/dp-world/services/utils.service';
import { FormGroup, FormArray } from '@angular/forms';
import { UiService } from 'app/dp-world/services/ui.service';
import { LogService } from 'app/dp-world/services/log.service';

@Injectable({
  providedIn: 'root'
})

/**
 * All webrequest for the Apply functionality.
 * @author charles.skinner@dpworld.com
 */
export class ApplyService {

  constructor(private staticData:StaticDataService,
    private http: HttpClient,
    private utils:UtilsService,
    private ui:UiService,
    private logger:LogService) { }

  public saveFinance(finance:Finance2,  id:string=''){
    console.log("saveFinance; ");
    //return of();

    let url = environment.rootUrl + environment.urls.save_finance;

    let data:FormData = this.utils.getFormData(finance);

    //console.log("saveFinance; data:"+JSON.stringify(data) );

   data.forEach((value, key) => {
     console.log( key+"="+JSON.stringify(value) );
   });

    //if this is an existing finance, we must use it's id to save it.
    if (finance.id != null){
      url = environment.rootUrl + environment.urls.save_finance_by_id.replace(':id', finance.id);
    }

    return this.http.post(url, data);
  }

  public downloadDocument(id:string, type:string){
    let url = environment.rootUrl + '/trade-finance/'+id+'/document/'+type; //environment.urls.upload_document;
    return this.http.get<string[]>(url);
  }

  /** 
   * used by other parties to reject finance
   * /discount-rate?invoiceDueDate=2020-07-31T12:43:02.119Z&paymentDate=2020-07-30T12:43:02.119Z
   */
  public getDiscountRate(invoiceDueDate:string, paymentDate:string):Observable<any>{
    console.log("getDiscountRate;");
    let url = environment.rootUrl + 'discount-rate?invoiceDueDate=' +invoiceDueDate+'&paymentDate='+paymentDate;
    return this.http.get<string[]>(url);
  }

  getDiscountRates(form:FormGroup) {
    console.log("getDiscountRate;");

    // could be string or date, make sure it's a string
    let invoiceDueDate = form.get('invoiceDueDate').value as Date;
    let invoiceDueDateString = "";
    if (typeof invoiceDueDate != "string"){
      invoiceDueDateString = invoiceDueDate.toISOString();
    } else {
      invoiceDueDateString = invoiceDueDate;
    }

    console.log("getDiscountRate; invoiceDueDate: "+invoiceDueDate);

    let logisticsDetailsFormGroup = (form.get('logisticsDetails') as FormArray).controls;
    logisticsDetailsFormGroup.forEach((eventDetails: FormGroup) => {

      let paymentDate = eventDetails.get(`paymentDate`).value as Date;
      if (!paymentDate || !invoiceDueDate) {
        return;
      }

      // could be string or date, make sure it's a string
      let paymentDateString = "";
      if (typeof paymentDate != "string"){
        paymentDateString = paymentDate.toISOString();
      } else {
        paymentDateString = paymentDate;
      }

      let discountRateControl = eventDetails.get('discountRate');

      this.getDiscountRate(invoiceDueDateString, paymentDateString).subscribe((response) => {
        console.log("getDiscountRate; response: ", response);

        discountRateControl.setValue(response.discountRate);
      },
        error => {
          this.ui.snackbar('The backend is currently not returning discount rate data.');
          this.logger.logError('The backend is currently not returning discount rate data.');
        });
    });
  }
}
