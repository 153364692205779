<div style="min-width: 400px;">
    <ngx-file-drop (onFileDrop)="fileDropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div fxLayout="column" fxLayoutAlign="center center">
                <div>
                    <span>Drag and drop a file here or</span>
                    <button matTooltip="Select a File" mat-icon-button class="matero-toolbar-button"
                        (click)="openFileSelector()" style="margin-left: 0px;">
                        <mat-icon>folder_open</mat-icon>
                    </button>
                </div>
                <div style="font-size: smaller;" class="text-gray-600">Supported file formats: 
                    {{ supportedFileTypes }}
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>
    <div class="upload-file">
        <p *ngIf="file">
            <mat-icon color="primary" style="vertical-align: middle;">attachment</mat-icon>
            <span>Uploaded File - <strong> {{ file.fileEntry.name }} </strong></span>
        </p>
    </div>
</div>

