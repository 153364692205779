import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
var BasicAuthInterceptor = /** @class */ (function () {
    function BasicAuthInterceptor(authenticationService, 
    //private uiService: UIService,
    router) {
        this.authenticationService = authenticationService;
        this.router = router;
    }
    BasicAuthInterceptor.prototype.intercept = function (request, next) {
        var _a, _b, _c;
        var _this = this;
        // add authorization header with basic auth credentials if available
        var currentUser = this.authenticationService.currentUserValue;
        //const isHandset = this.uiService.isHandsetChanged.getValue();
        if (request.url === environment.urls.vessel) {
            request = request.clone({
                setHeaders: (_a = {},
                    _a[environment.apiKeyVessel] = environment.apiValueVessel,
                    _a)
            });
        }
        else if (currentUser && currentUser.token) {
            var headers = (_b = {},
                _b[environment.HEADER.X_USER_TOKEN] = currentUser.token,
                _b['x-user-id'] = currentUser.user_id || '',
                _b);
            request = request.clone({
                setHeaders: headers
            });
        }
        else {
            request = request.clone({
                setHeaders: (_c = {},
                    _c[environment.apiKey] = environment.apiValue,
                    _c[environment.HEADER.X_DISPLAY_MODE] = 'web',
                    _c)
            });
        }
        return next.handle(request).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                var token = event.headers.get(environment.HEADER.X_USER_TOKEN);
                if (token && currentUser) {
                    // //console.log(
                    //   '%c token intercepted=' + token,
                    //   'color: lightgreen'
                    // );
                    currentUser.token = token;
                }
            }
        }, function (error) {
            if (error instanceof HttpErrorResponse) {
                console.log("error.status: " + error.status);
                // authorization has expired
                if ([440, 401].includes(error.status)) {
                    _this.router.navigate(['/auth/login'], { queryParams: { returnUrl: _this.router.url } });
                    // internet disconnected
                }
                else if ([0].includes(error.status)) {
                    _this.router.navigate(['/error-handling/def'], { queryParams: {} });
                }
            }
        }));
    };
    return BasicAuthInterceptor;
}());
export { BasicAuthInterceptor };
